import React, { Component } from 'react';
import {
  Button, Dropdown, List, Icon,
} from 'semantic-ui-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class NotificationsDropdown extends Component {
  render() {
    const { notifications, deleteNotification } = this.props.store.notifications;
    return (
      <Dropdown
        style={{ verticalAlign: 'text-bottom' }}
        trigger={(
          <Button
            circular
            icon="bell"
          />
        )}
        icon={null}
        pointing="top right"
        disabled={!Object.keys(notifications).length}
      >
        <Dropdown.Menu>
          {
            Object.values(notifications).map(notif => (
              <List.Item key={notif.key}>
                <List.Content>
                  <Icon
                    name="x"
                    onClick={() => deleteNotification(notif.key)}
                  />
                  <span>
                    {notif.text}
                  </span>
                  <i className="capitalize" style={{ fontWeight: 100 }}>
                    {` - ${moment(notif.timestamp, 'x').fromNow()}`}
                  </i>
                </List.Content>
              </List.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default withRouter(NotificationsDropdown);
