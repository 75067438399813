export function keygen() {
  return Math.random().toString(36).slice(2);
}

export function roundDecimals(num, digits = 2) {
  return Math.round(num * (10 ** digits)) / (10 ** digits);
}

export function arrayMove(array, fromIndex, toIndex) {
  const shallowClone = [...array];
  shallowClone.splice(toIndex, 0, shallowClone.splice(fromIndex, 1)[0]);
  return shallowClone;
}

export function validateUrl(string) {
  // we are now allowing acceptance of http
  if (!string) {
    return false;
  } else {
    let url;
    try {
      url = new URL(string);
    } catch (e) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}
