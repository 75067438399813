import axios from 'axios';
import { action, observable } from 'mobx';
import { API_URL } from '../configs';
import { toastTypes } from '../enums';

class Admin {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  clientData = {};

  clearDataCache = () => {
    localStorage.removeItem('summaryData');
    localStorage.removeItem('summaryDataPathFilter');
    localStorage.removeItem('summaryDataDomainFilter');
    localStorage.removeItem('summaryDataTimeSpan');
    localStorage.removeItem('summaryDataCachedDate');
  }

  spoofClient = async (clientId = null) => {
    this.clearDataCache();
    // the route will overwrite the superadmin's clientId property in the firebase db
    const res = await axios.post(`${API_URL}/api/admin/spoof-client`, { clientId });

    // refreshes the page so the new claims propagate
    if (res.data.success) {
      window.location.reload();
    } else {
      this.rootStore.failure('Spoof failed for some reason...');
    }
  }

  @action
  reset = () => {
    this.clientData = {};
  }

  @action
  getClientData = async () => {
    Promise.all([
      axios.get(`${API_URL}/api/admin/client-list/`),
      axios.get(`${API_URL}/api/admin/overview-stats/`),
    ])
      .then((results) => {
        const [clientInfoRes, clientStatsRes] = results;
        const clientInfo = clientInfoRes.data.clients;
        const clientStats = clientStatsRes.data.data;

        Object.keys(clientInfo).forEach((clientId) => {
          for (let i = 0; i < clientStats.length; i += 1) {
            if (clientStats[i].client_id === clientId) {
              clientInfo[clientId].formsStarted = Number(clientStats[i].t_form_start);
              clientInfo[clientId].abandons = Number(clientStats[i].t_abandon);
              clientInfo[clientId].abandons_with_emails = Number(clientStats[i].abandons_with_unique_emails);
            }
          }
        });

        this.clientData = clientInfo;
      })
      .catch(err => console.error(err));
  }

  setClientStatus = async (clientId, statusTo) => {
    try {
      const res = await axios.post(`${API_URL}/api/um/${statusTo}`, { clientId });

      if (res.data.success) {
        this.rootStore.success('Status changed successfully.', toastTypes.setClientStatus);
        this.rootStore.clearToast(toastTypes.setClientStatus);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.setClientStatus);
    } finally {
      this.getClientData();
    }
  }

  emailRef;

  downloadCaptures = async (clientId, company) => {
    try {
      const res = await axios.get(`${API_URL}/api/admin/captures/${clientId}`);

      if (res.data.success) {
        const emailList = [['Name', 'Email', 'Capture Url'],
          ...res.data.captures.reduce((acc, curr) => {
            acc.push([curr.name, curr.email, curr.url]);
            return acc;
          }, [])];

        let csvParsedEmailList = '';

        for (const rowIndex in emailList) {
          csvParsedEmailList += `${emailList[rowIndex].join(',')}\n`;
        }

        this.emailRef.href = URL.createObjectURL(new Blob([csvParsedEmailList], { type: 'text/csv' }));
        this.emailRef.download = `captures_${company.replace(/ /gi, '_')}.csv`;
        this.emailRef.click();
      } else {
        throw new Error();
      }
    } catch (err) {
      this.rootStore.failure('Something went wrong...');
    }
  }
}

export default Admin;
