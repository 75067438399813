import React, { Component } from 'react';
import {
  Segment, Grid, Header,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import SettingsDropdown from './SettingsDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import HamburgerIcon from '../../shared/HamburgerIcon';
import { userRoleType } from '../../../enums';
import './ContentHeader.css';

@inject('store')
@observer
class ContentHeader extends Component {
  render() {
    const { user } = this.props.store.auth;
    const { mobile } = this.props.store.ui;

    return (
      <Segment basic id="ContentHeader" className="shadow">
        <Grid verticalAlign="middle">
          { !mobile ? (
            <Grid.Row>
              <Grid.Column width={2}>
                <HamburgerIcon
                  sideNavFnc={this.props.sideNavFnc}
                  visibility={this.props.visibility}
                />
              </Grid.Column>

              <Grid.Column width={12}>
                <Header as="h5" textAlign="center" className="noEmphasisHeader">
                  {
                  (() => {
                    if (user) {
                      const name = (user.company || user.email);
                      if (user.role === userRoleType.superadmin) {
                        return `Spoofing ${name}`;
                      } else {
                        return name;
                      }
                    } else {
                      return '';
                    }
                  })()
                }
                </Header>
              </Grid.Column>

              <Grid.Column width={2} textAlign="right">
                <NotificationsDropdown />
                <SettingsDropdown />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column width={1}>
                <HamburgerIcon
                  sideNavFnc={this.props.sideNavFnc}
                  visibility={this.props.visibility}
                />
              </Grid.Column>

              <Grid.Column width={14} textAlign="right">
                <NotificationsDropdown />
                <SettingsDropdown />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    );
  }
}

export default withRouter(ContentHeader);
