import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import './EmailVerificationPage.css';

@inject('store')
@observer
class EmailVerificationPage extends Component {
  state = {
    linkSent: false,
  }

  sendVerificationLink = () => {
    const { email } = this.props.store.firebase.auth().currentUser;
    this.props.store.auth.sendCustomVerificationEmail(email);
    this.setState({ linkSent: true });
  }

  componentDidMount = () => {
    const { email } = this.props.store.firebase.auth().currentUser;
    this.props.store.auth.sendCustomVerificationEmail(email);
  }

  render() {
    return (
      <div id="EmailVerificationPage">
        {
          !this.state.linkSent ? (
            <Message>
              <Message.Header>Please verify your email before proceeding.</Message.Header>
              <div className="textAlignCenter">
                Lost the email?
                <span
                  className="linkStyling"
                  onClick={this.sendVerificationLink}
                >
                  {' '}
                  Send it again.
                </span>
              </div>
              <div className="textAlignCenter">
                Log in with a
                {' '}
                <span className="linkStyling" onClick={this.props.store.auth.signOut}>different account.</span>
              </div>
            </Message>
          )
            : (
              <Message>
                <Message.Header>Verification Email Sent!</Message.Header>
                <p>
                  Please refresh the page after verifying to proceed.
                </p>
              </Message>
            )
        }
      </div>
    );
  }
}

export default EmailVerificationPage;
