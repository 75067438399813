import React from 'react';
import {
  Accordion, Header, List, Segment, Modal,
} from 'semantic-ui-react';
import tos from '../../../assets/tos';

const FAQ = () => {
  const panels = [
    {
      key: 1,
      title: 'What constitutes an abandonment?',
      content: 'Capture®– When a customer comes to your site, starts filling out the form and makes it as far as entering an email address, but does not complete the form. Our system also detects an abandonment when the user has not interacted with the form for 30 minutes.',
    },
    {
      key: 2,
      title: 'How does the system collect email addresses?',
      content: 'Once you’ve embedded Capture® snippet on your website, our system will detect any email information entered on any of the site pages, in real time. If the customer on your site enters an email address but doesn’t proceed to the next page, our system is still able to collect that email address and apply it to the abandoned Capture® email campaigns.',
    },
    {
      key: 2.5,
      title: 'Are all my forms supported?',
      content: 'We support all forms except cross-domain iframe forms. Due to the nature of cross-domain iframes, our snippet cannot interact with the nested forms and capture the abandoned data. If you\'re still interested in our service, please contact us directly about integrating our snippet natively into your forms.',
    },
    {
      key: 3,
      title: 'Do you collect any other sensitive information from my site?',
      content: 'Absolutely not. The only fields we collect are first name, last name, email address and phone number. We do not collect or store any financial or other personal information such as credit card numbers – this data never touches our servers.',
    },
    {
      key: 4,
      title: 'Will your code affect the performance of my site?',
      content: 'Not at all. The JavaScript is similar to Google Analytics™ and does not have any impact on page-load time. The code is asynchronous – which loads independently of all other page scripts and does not delay page loads nor cannot cause your website to fail.',
    },
    {
      key: 5,
      title: 'Do you ever rent, share, or sell our customer data?',
      content: 'No, never! We take the privacy of our clients and their shoppers very seriously, and we never rent, share, or sell any of your customer data. We only use customer data (first name, last name, email address and cart items) for the purpose of sending emails on your site’s behalf via your abandoned form/cart campaigns.',
    },
    {
      key: 6,
      title: 'How do you ensure that all emails are delivered to the inbox of abandoned shoppers?',
      content: `We can send emails through our messaging engine or through your Email Service Provider (ESP). We have years of experience with a variety of large email platforms.  Our tracking and reporting system allows us to monitor your deliverability rate. We optimize your email messages to allow for the highest inbox delivery rate possible.
      Our tracking and reporting system allows us to monitor your deliverability rate. We optimize your email messages to allow for the highest inbox delivery rate possible.`,
    },
    {
      key: 7,
      title: 'How do you handle unsubscribes from the remarketing emails?',
      content: 'We adhere to CAN-SPAM Act in all countries and every email contains an unsubscribe link at the bottom in which users can remove themselves from the remarketing list. Please note that if a user unsubscribes from these remarketing messages, they are not unsubscribing from your own email list.',
    },
    {
      key: 8,
      title: 'What ecommerce platforms do you support?',
      content: 'Because our code is non-invasive and agnostic we support various platforms such as WordPress, Magento, Volusion, and many more. We can also accommodate custom ecommerce sites. We support almost every type of architecture including, HTML, PHP, Classic ASP, ASP.NET, Ruby on Rails, JSP, and many, many others.',
    },
    {
      key: 9,
      title: 'Can I manage multiple domains in one account?',
      content: 'Absolutely… Once you have registered your first domain, you can add your other websites that have online forms. We’ll just need to install the unique code snippet on each of your websites (free of charge!)',
    },
    {
      key: 10,
      title: 'What’s the typical conversion rate for your tool?',
      content: 'We consistently see 10%-15% conversion rates, and even more.',
    },
    {
      key: 11,
      title: 'How do you count conversions?',
      content: 'We consider a conversion successful when the following happens: a consumer abandons a form/cart, and is then remarketed to (i.e. sent a follow-up email), where they click on one of the embedded links within the follow-up email, returning them to their abandoned form/cart where they complete a successful transaction or we have a successfully delivered message and that user is tracked back to your form as a submit which we call an influence save. We won’t attribute a conversion until we have proof that the email influenced the customer to return.',
    },
    {
      key: 12,
      title: 'Can I customize my email creative?',
      content: 'Absolutely –  we accept fully-coded html and can assist in uploading your current email creative to maintain the look and feel of your brand. Or choose from our selection of pre-defined templates. Of course, our email marketing specialists will provide recommendations to improve your emails as well.',
    },
    {
      key: 13,
      title: 'What analytics/metrics can you track?',
      content: 'We track email analytics such as the number of emails sent, open rate, click-through rate and conversion.',
    },
    {
      key: 14,
      title: 'How does the free captures work?',
      content: 'Once we’ve completed your set up; we will track 100 free form starts.',
    },
    {
      key: 15,
      title: 'How much does it cost?',
      content: 'Pricing models are dictated by how many forms are tracked. Depending on which plan, we will discontinue tracking after the threshold has been hit.',
    },
    {
      key: 16,
      title: 'Do I have to send emails through your system in order to use this product?',
      content: 'No, you can utilize our Capture® technology and services to collect abandoned emails from your website to and use your own email marketing if that is prefered but we do prefer to use our platform to manage and optimize conversions real time.',
    },
    {
      key: 17,
      title: 'Do you send more than one email?',
      content: 'Through our years of experience in remarketing we have found that a series of 3 emails strategically sent at specific times is the most effective method of remarketing emails.  Upon review we can customize if needed.',
    },
    {
      key: 18,
      title: 'How can I use Capture to build my email lists?',
      content: 'With Capture®’s patented solution you can capture your website abandoners email address and add them to your email list.',
    },
    {
      key: 19,
      title: 'I manage multiple sites – how much does that cost?',
      content: 'That is not a problem! Multiple sites can be added seamlessly in the tool and we simply add up the total checkout page traffic to arrive at subscription pricing model for all of your sites.',
    },
    {
      key: 20,
      title: 'Is there a contract?',
      content: 'Yes, our contracts are easy and flexible and provide a right to cancel at any time.',
    },
    {
      key: 21,
      title: 'How long does implementation take?',
      content: 'We are one of the industry-leaders in ease of implementation and speed to recovering lost revenue from abandoned forms or carts. Our onboarding process starts with generation of the JavaScript snippet. Our dedicated account managers will assist in testing your website and the initial configuration of your account campaigns. Once our code is implemented on your website, we will walk you through every step of the way to make it easy and hassle-free!',
    },
    {
      key: 22,
      title: 'How much revenue can be saved?',
      content: 'Industry norms show 10%-30% boosts in online revenue recovery by implementing cart abandon email campaigns.',
    },
  ];
  return (
    <div>
      <Header id="TabTitle" as="h2">
          Frequently Asked Questions
      </Header>
      <Segment>
        <Modal trigger={<span className="linkStyling">Terms and Conditions can be read here.</span>}>
          <Modal.Header>Terms and Conditions</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {tos}
              </p>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Segment>
      <Segment>
        <List bulleted>
          <List.Item>
              You can chat with us using the chat bubble on the bottom right corner of this page.
          </List.Item>
          <List.Item>
              Or you can send an email to us at
            {' '}
            <strong>dev@automatedremarketing.com</strong>
          </List.Item>
        </List>
      </Segment>
      <Accordion panels={panels} styled fluid />
    </div>
  );
};

export default FAQ;
