import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import './HamburgerIcon.css';

@inject('store')
@observer
class HamburgerIcon extends Component {
  render() {
    const { sideNavFnc, visibility } = this.props;
    const { mobile } = this.props.store.ui;

    return (
      <div
        id="HamburgerIcon"
        className={`leftArrow ${mobile ? visibility ? 'hamburgerMobileOpen' : 'hamburgerMobileClose' : ''}`}
        onClick={sideNavFnc}
      >
        <div className={visibility ? 'left1' : 'right1'} />
        <div className={visibility ? 'left2' : 'right2'} />
        <div className={visibility ? 'left3' : 'right3'} />
      </div>
    );
  }
}


export default HamburgerIcon;
