/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component, Fragment } from 'react';
import {
  Segment, Button, Header, List,
} from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import 'react-datepicker/dist/react-datepicker.css';

import { planLimits } from '../../../../enums';

@inject('store')
@observer
class Emails extends Component {
  @observable
  loading = false;

  constructor(props) {
    super(props);

    const { planStartDate } = this.props.store.auth.user;
    const currentDay = moment().date();
    const planStartDay = moment(planStartDate).date();

    const startDate = planStartDay >= currentDay
      ? moment().subtract(1, 'month').date(planStartDay).toDate()
      : moment().date(planStartDay).toDate();

    this.state = {
      startDate,
      endDate: new Date(),
    };
  }

  setDate = (inputDate, planStartDate = this.props.store.auth.user.planStartDate) => {
    const { startDate, endDate } = this.state;
    const planStartMoment = moment(planStartDate);
    const planStartDay = planStartMoment.date();
    const inputMoment = moment(inputDate);

    let startMoment = moment(startDate);
    let endMoment = moment(endDate);

    if (inputMoment.isBefore(startMoment, 'month') || inputMoment.isSame(endMoment, 'month')) {
      startMoment = inputMoment.date(planStartDay);
    } else {
      endMoment = inputMoment.date(planStartDay);
    }
    /** bounds check for startMoment and endMoment */
    startMoment = moment.min(moment.max(planStartMoment, startMoment), moment());
    endMoment = moment.min(moment(), endMoment);

    /** if at ends of range, go to closest pay period */
    if (startMoment.isSame(endMoment, 'day')) {
      if (startMoment.isSame(planStartMoment, 'day')) {
        endMoment.add(1, 'month');
      } else {
        startMoment.subtract(1, 'month').date(planStartDay);
      }
    }

    this.setState({
      startDate: startMoment.toDate(),
      endDate: endMoment.toDate(),
    });
  }

  render() {
    const { planStartDate, planName } = this.props.store.auth.user;
    const { planObj = {} } = this.props.store.accountStatus;
    const planMeta = planObj.metadata || {};
    return (
      <Fragment>
        <Header as="h3" attached="top" style={{ marginTop: '1rem' }} className="noEmphasisHeader">
          Download Leads Data as CSV
        </Header>

        <Segment attached>
          You may download your list of abandoned and submitted leads here.
          <List bulleted style={{ marginTop: 3 }}>
            <List.Item>
              Name will be listed if it was extracted properly from the form.
            </List.Item>
            <List.Item>
              Any other form field will be listed as a column in the CSV.
            </List.Item>
            <List.Item>
              Please select the months on the calendar you want to download data for below.
            </List.Item>
            <List.Item>
              By default, it will download the data from your last payment/plan date and today.
            </List.Item>
          </List>
        </Segment>
        <Segment attached>
          <Segment.Group horizontal>
            <Segment textAlign="center">
              <Header as="h4" className="noEmphasisHeader">
            Selected Date Range
                <br />
                {` ${moment(this.state.startDate).format('MM/DD/YYYY')} `}
            -
                {` ${moment(this.state.endDate).format('MM/DD/YYYY')}`}
              </Header>
              <DatePicker
                showPopperArrow={false}
                onChange={date => this.setDate(date, planStartDate)}
                inline
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={planStartDate && moment(planStartDate).startOf('month').toDate()}
                maxDate={Date.now()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </Segment>
            <Segment>
              <Header as="h3">
              Plan Details
              </Header>
              <List bulleted>
                <List.Item>
              Current Plan:
                  {` ${planMeta.name || planName || 'N/A'}`}
                </List.Item>
                <List.Item>
              Plan Start Date:
                  {` ${planStartDate}`}
                </List.Item>
                <List.Item>
              Limit:
                  {` ${planMeta.limit || planLimits[planName] || 'N/A'}`}
                </List.Item>
              </List>
            </Segment>
          </Segment.Group>
        </Segment>
        <a
          ref={(ref) => { this.props.store.analytics.emailRef = ref; }}
          style={{ display: 'none' }}
        />

        <Button
          attached="bottom"
          disabled={this.props.store.analytics.loading.initiateEmailDownload}
          loading={this.props.store.analytics.loading.initiateEmailDownload}
          onClick={() => {
            this.props.store.analytics.downloadCaptures(this.state.startDate, this.state.endDate);
          }}
          color="green"
          content="Download CSV"
        />
      </Fragment>
    );
  }
}

export default Emails;
