import _ from 'lodash';
import qs from 'query-string';
import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, Image } from 'semantic-ui-react';
import { Route } from 'react-router-dom';

import configs from './configs';

import OMI366 from './OMI366';
import HubSpot from './HubSpot';
import Salesforce from './Salesforce';
import WiX from './WiX';
import GoogleAdwordsPage from './GoogleAdwords';
import GoogleTagManager from './GoogleTagManager';
import Shopify from './Shopify';
import Mailchimp from './Mailchimp';
import SalesforceMarketingCloud from './SalesforceMarketingCloud';
import Typeform from './Typeform';
import Pipedrive from './Pipedrive';
import Blitz from './Blitz';
import Fanbridge from './Fanbridge';

import './Integrations.css';
import { colors } from '../../../enums';

@inject('store')
@observer
class Integrations extends Component {
  componentDidMount() {
    const { code } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { pathname } = this.props.location;
    if (code && (pathname.endsWith('integrations') || pathname.endsWith('integrations/'))) {
      /** Redirect to Hubspot Integration to preserve legacy support */
      this.props.history.push(`/integrations/HubSpot?code=${code}`);
    }
  }

  generateColumns = () => Object.keys(configs).map(partner => (
    <Card
      key={configs[partner].key}
      onClick={() => this.props.history.push(`/integrations/${configs[partner].key}`)}
    >
      <Image
        className="imageSizeOverride"
        src={configs[partner].logo}
        wrapped
        ui={false}
      />
      <Card.Content>
        <Card.Header>
          {configs[partner].displayName}
        </Card.Header>
        <Card.Meta>
          {
            configs[partner].status === 'Integration Ready'
              ? <span style={{ color: colors.primary }}>{configs[partner].status}</span>
              : <span>{configs[partner].status}</span>
          }
        </Card.Meta>
        <Card.Description>
          {configs[partner].desc}
        </Card.Description>
      </Card.Content>
    </Card>
  ))

  render() {
    return _.get(this.props, 'match.params.partner') === undefined ? (
      <Card.Group>
        {this.generateColumns()}
      </Card.Group>
    )
      : (
        <Fragment>
          <Route path="/integrations/OMI366" component={OMI366} />
          <Route path="/integrations/HubSpot" component={HubSpot} />
          <Route path="/integrations/Salesforce" component={Salesforce} />
          <Route path="/integrations/WiX" component={WiX} />
          <Route path="/integrations/GoogleAdwords" component={GoogleAdwordsPage} />
          <Route path="/integrations/GoogleTagManager" component={GoogleTagManager} />
          <Route path="/integrations/Shopify" component={Shopify} />
          <Route path="/integrations/Mailchimp" component={Mailchimp} />
          <Route path="/integrations/SalesforceMarketingCloud" component={SalesforceMarketingCloud} />
          <Route path="/integrations/Typeform" component={Typeform} />
          <Route path="/integrations/Pipedrive" component={Pipedrive} />
          <Route path="/integrations/Blitz" component={Blitz} />
          <Route path="/integrations/Fanbridge" component={Fanbridge} />
        </Fragment>
      );
  }
}

export default Integrations;
