import React, { Component, Fragment } from 'react';
import {
  Segment, Header, Button, Icon, Modal, Form, Checkbox, Message, Grid,
} from 'semantic-ui-react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { modalTypes } from '../../../../enums';
import { validateUrl } from '../../../../helper';

const WEBHOOK_DOCS_LINK = 'https://docs.movology.com/docs/setting-up-webhooks/';

@inject('store')
@observer
class Webhooks extends Component {
  state = {
    modalOpen: false,
    modalType: modalTypes.add,
    editKey: null,
    deleteKey: null,
    hookObj: this.props.store.clientHooks.hookObjTemplate,
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
      hookObj: this.props.store.clientHooks.hookObjTemplate,
    });
  }

  handleInput = (e, { value, name }) => this.setState({
    hookObj: { ...this.state.hookObj, [name]: value },
  })

  toggleDataToSend = (e, { name }) => this.setState({
    hookObj: {
      ...this.state.hookObj,
      dataToSend: {
        capture: {
          ...this.state.hookObj.dataToSend.capture,
          [name]: !this.state.hookObj.dataToSend.capture[name],
        },
      },
    },
  })

  generateHookCards = () => {
    const { hooks } = this.props.store.clientHooks;

    return (
      <Grid columns={2} style={{ marginBottom: '1rem' }}>
        {_.chunk(Object.keys(hooks), 2)
          .map((row, index) => (
            <Grid.Row key={index}>
              {row.map(key => (
                <Grid.Column key={key}>
                  <Segment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Header>{hooks[key].name}</Header>
                      <div>
                        <Icon
                          name="pencil alternate"
                          className="cursorPointer"
                          onClick={() => this.setState({
                            modalOpen: true,
                            modalType: modalTypes.edit,
                            editKey: key,
                            hookObj: this.props.store.clientHooks.hooks[key],
                          })}
                        />
                        <Icon
                          name="x"
                          className="cursorPointer"
                          onClick={() => this.setState({
                            modalOpen: true,
                            modalType: modalTypes.remove,
                            deleteKey: key,
                          })}
                        />
                      </div>
                    </div>

                    <Grid columns="equal">
                      <Grid.Row>
                        <Grid.Column>
                      Request Url
                        </Grid.Column>
                        <Grid.Column>
                          {hooks[key].url}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                      Secret Key
                        </Grid.Column>
                        <Grid.Column>
                          <div className="hoverMask">
                            <span className="mask">
                              {'*'.repeat(25)}
                            </span>
                            <span className="reveal">
                              {hooks[key].secretKey}
                            </span>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
      </Grid>
    );
  }

  validateForm = () => {
    const { failure } = this.props.store;

    if (!validateUrl(this.state.hookObj.url)) {
      failure('Invalid url entered.');
      return false;
    } else if (this.state.hookObj.name.trim() === '') {
      failure('Name field empty.');
      return false;
    }

    return true;
  }

  hookObjForm = () => (
    <Form>
      <Form.Input
        fluid
        required
        label="Webhook Name"
        placeholder="Webhook #1"
        value={this.state.hookObj.name}
        name="name"
        onChange={this.handleInput}
      />
      <Form.Input
        fluid
        required
        placeholder="https://mysite.com"
        label="Webhook Url"
        value={this.state.hookObj.url}
        name="url"
        onChange={this.handleInput}
      />
      <label style={{
        display: 'block',
        margin: '0 0 .28571429rem 0',
        color: 'rgba(0,0,0,.87)',
        fontSize: '.92857143em',
        fontWeight: 700,
        textTransform: 'none',
      }}
      >
        Data to be Sent
      </label>
      <Form.Checkbox
        label="Captured Timestamp"
        control={Checkbox}
        checked={this.state.hookObj.dataToSend.capture.created_ts}
        name="created_ts"
        onChange={this.toggleDataToSend}
      />
      <Form.Checkbox
        label="Email Address Captured"
        control={Checkbox}
        checked={this.state.hookObj.dataToSend.capture.email}
        name="email"
        onChange={this.toggleDataToSend}
      />
      <Form.Checkbox
        label="Recover Emails Sent"
        control={Checkbox}
        checked={this.state.hookObj.dataToSend.capture.email_count}
        name="email_count"
        onChange={this.toggleDataToSend}
      />
      <Form.Checkbox
        label="Form Captured"
        control={Checkbox}
        checked={this.state.hookObj.dataToSend.capture.form_data}
        name="form_data"
        onChange={this.toggleDataToSend}
      />
      <Form.Checkbox
        label="Capture Origin Url"
        control={Checkbox}
        checked={this.state.hookObj.dataToSend.capture.url}
        name="url"
        onChange={this.toggleDataToSend}
      />
    </Form>
  );

  generateModal = () => {
    const { addHook, deleteHook, updateHook } = this.props.store.clientHooks;
    const { hooks } = this.props.store.clientHooks;

    switch (this.state.modalType) {
      case modalTypes.add:
        return (
          <Fragment>
            <Modal.Header>
              Add a Webhook
            </Modal.Header>
            <Modal.Content>
              {this.hookObjForm()}
            </Modal.Content>
            <Modal.Actions>
              <Button content="Cancel" onClick={this.closeModal} />
              <Button
                positive
                content="Confirm"
                onClick={() => {
                  if (this.validateForm()) {
                    addHook(this.state.hookObj);
                    this.closeModal();
                  }
                }}
              />
            </Modal.Actions>
          </Fragment>
        );
      case modalTypes.remove:
        return (
          <Fragment>
            <Modal.Header>
              Are you sure you want to delete this webhook?
            </Modal.Header>
            <Modal.Content>
              {hooks[this.state.deleteKey] && hooks[this.state.deleteKey].name}
              {' '}
              will be deleted.
            </Modal.Content>
            <Modal.Actions>
              <Button content="Cancel" onClick={this.closeModal} />
              <Button
                negative
                content="Delete"
                onClick={() => {
                  deleteHook(this.state.deleteKey);
                  this.closeModal();
                }}
              />
            </Modal.Actions>
          </Fragment>
        );
      case modalTypes.edit:
        return (
          <Fragment>
            <Modal.Header>
              Update Webhook Settings:
              {' '}
              {hooks[this.state.editKey] && hooks[this.state.editKey].name}
            </Modal.Header>
            <Modal.Content>
              {this.hookObjForm()}
            </Modal.Content>
            <Modal.Actions>
              <Button content="Cancel" onClick={this.closeModal} />
              <Button
                positive
                content="Update"
                onClick={() => {
                  if (this.validateForm()) {
                    updateHook(this.state.editKey, this.state.hookObj);
                    this.closeModal();
                  }
                }}
              />
            </Modal.Actions>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Modal.Header>
              Something went wrong...
            </Modal.Header>
          </Fragment>
        );
    }
  }

  render() {
    return (
      <Fragment>
        <Message
          floating
          info
          icon
        >
          <Icon name="bullhorn" />
          <Message.Content>
            <Message.Header>
              Have you tried our new webhooks? Find more information
              {' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={WEBHOOK_DOCS_LINK}
              >
              here.
              </a>
            </Message.Header>

          </Message.Content>
        </Message>

        {this.generateHookCards()}

        <span
          {...Object.keys(this.props.store.clientHooks.hooks).length >= 5
            ? { 'data-tooltip': 'You can add up to 5 webhooks.' }
            : {}}
        >
          <Button
            primary
            icon="book"
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href={WEBHOOK_DOCS_LINK}
            labelPosition="left"
            content="Read the Docs"
          />
          <Button
            positive
            icon="plus"
            labelPosition="left"
            content="Add a Webhook"
            onClick={() => this.setState({
              modalOpen: true,
              modalType: modalTypes.add,
              hookObj: this.props.store.clientHooks.hookObjTemplate,
            })}
            disabled={Object.keys(this.props.store.clientHooks.hooks).length >= 5}
          />
        </span>

        <Modal
          open={this.state.modalOpen}
          onClose={this.closeModal}
        >
          {this.generateModal()}
        </Modal>
      </Fragment>
    );
  }
}

export default Webhooks;
