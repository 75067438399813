/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import axios from 'axios';
// import moment from 'moment';
import { observable, action } from 'mobx';
import { API_URL } from '../configs';

class AMP {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable loading = {
    getAmpData: false,
  };

  @observable
  ampData = {}

  @action
  reset = () => {
    this.loading = _.mapValues(this.loading, () => false);
  }

  @action
  getAmpData = async () => {
    try {
      this.loading.getAmpData = true;
      const { data } = await axios.get(`${API_URL}/api/amp/overview-stats`);
      if (data.success) {
        this.ampData = data.overviewStats;
      }
    } catch (e) {
      this.rootStore.failure('Failed to get AMP overview data...');
    }
    this.loading.getAmpData = false;
    return this.ampData;
  }
}

export default AMP;
