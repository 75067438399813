import axios from 'axios';
import { action, observable } from 'mobx';
import _ from 'lodash';
import { API_URL } from '../configs';
import { toastTypes } from '../enums';

class AccountStatus {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable account = {};
  @observable planObj = {};

  @action reset = () => {
    this.account = {};
    this.planObj = '';
  }

  @action
  getAccountDetails = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/payments/account-details`);
      if (res.data.success && res.data.paymentData && !res.data.paymentData.deleted) {
        this.account = res.data.paymentData;
        this.setPlanName(res.data.paymentData);
      } else {
        this.account = {};
      }
    } catch (e) {
      this.rootStore.failure('Could not get account details.');
    }
  }

  setPlanName = (paymentData) => {
    this.planObj = _.get(paymentData, ['subscriptions', 'data', '0', 'items', 'data', '0', 'plan']);
  }

  @action
  cancelSubscription = async () => {
    try {
      this.loading = true;
      const res = await axios.delete(`${API_URL}/api/payments/cancel-subscription`);
      this.getAccountDetails();

      if (res.data.success) {
        this.rootStore.success('Your Subscription will conclude after the billing cycle.', toastTypes.cancelSubscription);
        this.rootStore.clearToast(toastTypes.cancelSubscription);
        this.getAccountDetails();
      } else {
        this.rootStore.failure('Update Failed.', toastTypes.cancelSubscription);
      }
    } catch (e) {
      this.rootStore.failure('Could not cancel your subscription.', toastTypes.cancelSubscription);
    } finally {
      this.getAccountDetails();
      this.loading = false;
    }
  }
}

export default AccountStatus;
