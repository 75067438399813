import axios from 'axios';
import {
  action, observable, computed,
} from 'mobx';
import _ from 'lodash';
import { API_URL } from '../configs';
import { roundDecimals } from '../helper';
import { toastTypes } from '../enums';

class Payments {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable loading = false;
  @observable availablePlans = [];
  @observable selectedPlan = [];
  @observable appliedPromoCode = {};
  @observable stripeToken = {};
  @observable email = '';
  @observable promoCode = '';

  @action
  reset = () => {
    this.availablePlans = [];
    this.selectedPlan = [];
    this.appliedPromoCode = {};
    this.stripeToken = {};
    this.email = '';
    this.promoCode = '';
  }

  @computed
  get subtotal() {
    return roundDecimals(Object.values(this.selectedPlan).reduce((acc, curr) => (
      acc + this.availablePlans.find(availablePlan => (
        availablePlan.id === curr
      )).amount
    ), 0) / 100);
  }

  @computed
  get promoValue() {
    return roundDecimals(this.subtotal * this.appliedPromoCode.percentOff * 0.01 || 0);
  }

  @computed
  get total() {
    return roundDecimals(this.subtotal - this.promoValue);
  }

  @action
  getAvailablePlans = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/payments/plans-list`);
      if (res.data.success) {
        this.availablePlans = res.data.plans;
        this.email = this.rootStore.auth.user.email;
      }
    } catch (e) {
      this.rootStore.failure('Could not get available plans.');
    }
  }

  @action
  planSelector = (id) => {
    this.selectedPlan = [id];
  }

  @action
  inputHandler = (e, { name, value }) => {
    this[name] = value;
  }

  @action
  verifyPromoCode = async () => {
    try {
      const promoVal = this.promoCode;
      const res = await axios.post(`${API_URL}/api/payments/check-promo`, { promoVal });

      if (res.data.success) {
        this.appliedPromoCode = { promoVal, ...res.data };
        this.rootStore.success('The promo code has been applied.', toastTypes.verifyPromoCode);
        this.rootStore.clearToast(toastTypes.verifyPromoCode);
      } else {
        this.rootStore.failure('The promo code is invalid.', toastTypes.verifyPromoCode);
      }
    } catch (e) {
      this.rootStore.failure('Could not verify promo code', toastTypes.verifyPromoCode);
    }
  }

  @action
  updateStripePayment = async () => {
    this.loading = true;
    this.confirmPaymentsRef.click();
    setTimeout(async () => {
      const { stripeToken } = this;
      if (Object.keys(stripeToken).length === 0) {
        this.loading = false;
        return;
      }

      try {
        const res = await axios.patch(`${API_URL}/api/payments/account-details`, {
          updateObject: {
            source: stripeToken.id,
          },
        });

        if (res.data.success) {
          this.rootStore.success('Update Successful.', toastTypes.updateStripePayment);
          this.rootStore.clearToast(toastTypes.updateStripePayment);
          this.rootStore.accountStatus.getAccountDetails();
        } else {
          this.rootStore.failure('Update Failed.', toastTypes.updateStripePayment);
        }
      } catch (e) {
        this.rootStore.failure('Could not update your payment.', toastTypes.updateStripePayment);
      } finally {
        this.rootStore.accountStatus.getAccountDetails();
        this.loading = false;
      }
    }, 1500);
  }

  checkPayment = () => {
    this.confirmPaymentsRef.click();
  }

  @action
  confirmPaymentAndSubscribe = async () => {
    const {
      email, selectedPlan: selectedPlans, appliedPromoCode, stripeToken,
    } = this;

    if (Object.keys(stripeToken).length === 0) {
      return;
    }

    if (selectedPlans.length === 0) {
      this.rootStore.failure('Please select a plan.', toastTypes.confirmPaymentAndSubscribe);
      return;
    }

    try {
      this.loading = true;
      const res = await axios.post(`${API_URL}/api/payments/subscription`, {
        email,
        promo: appliedPromoCode.promoVal,
        plan: Object.values(selectedPlans),
        stripeToken: stripeToken.id,
      });

      if (res.data.success) {
        this.rootStore.success('Subscription Successful.', toastTypes.confirmPaymentAndSubscribe);
        this.rootStore.clearToast(toastTypes.confirmPaymentAndSubscribe);
        this.rootStore.accountStatus.getAccountDetails();
      } else {
        this.rootStore.failure('Could not complete your subscription.', toastTypes.confirmPaymentAndSubscribe);
      }
    } catch (e) {
      this.rootStore.failure('Could not complete your subscription.', toastTypes.confirmPaymentAndSubscribe);
    } finally {
      this.loading = false;
    }
  }

  @action
  upgradePlan = async () => {
    this.loading = true;
    const subscriptionId = _.get(this.rootStore, ['accountStatus', 'account', 'subscriptions', 'data', '0', 'id']);
    if (!subscriptionId) {
      this.rootStore.failure('Could not find a subscription. Please contact support @ info@automatedremarketing.com');
    }

    const planObj = { plan: this.selectedPlan[0] };

    try {
      await axios.post(`${API_URL}/api/payments/subscription/${subscriptionId}`, planObj);
      this.rootStore.success('Your plan has been updated.');
    } catch (e) {
      this.rootStore.failure('Could not find add Recover. Please contact support @ info@automatedremarketing.com');
    } finally {
      await this.rootStore.accountStatus.getAccountDetails();
      this.loading = false;
    }
  }
}

export default Payments;
