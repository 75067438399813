import _ from 'lodash';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Header, Segment, List, Card, Dropdown, Button, Dimmer, Loader,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

const loader = (
  <Dimmer active>
    <Loader active inline="centered" size="large" />
  </Dimmer>
);

@inject('store')
@observer
class Setup extends Component {
  componentDidMount() {
    this.props.store.integrations.GoogleTagManager.getAuthUrl();
    this.props.store.integrations.GoogleTagManager.getAllResources();
  }

  accountsCard(gtmStore = this.props.store.integrations.GoogleTagManager) {
    const {
      accounts, loading, selected, configs,
    } = gtmStore;
    const { accountIdx } = selected;

    const defaultDescription = 'Select your GTM account below';
    const selectedAccount = accounts[accountIdx] || {};

    const dropdownOptions = accounts.map((account, i) => {
      const { name, accountId, path } = account;
      return {
        key: path,
        text: `${name} - ${accountId}`,
        value: i,
      };
    });

    return (
      <Card color="red" style={configs.enabled ? {} : { backgroundColor: 'grey' }}>
        <Card.Content>
          <Card.Header>
            Account:
            <a href={`https://tagmanager.google.com/#/container/accounts/${selectedAccount.accountId}/`} target="_blank" rel="noopener noreferrer">
              {` ${selectedAccount.name || ''}`}
            </a>
          </Card.Header>
          <Card.Meta>
            ID:
            {` ${selectedAccount.accountId || ''}`}
          </Card.Meta>
          <Card.Description>
            {_.isEmpty(selectedAccount) ? defaultDescription : '' }
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Dropdown
            style={configs.enabled ? {} : { backgroundColor: 'grey' }}
            disabled={loading.getAllResources}
            placeholder="Account"
            fluid
            selection
            options={dropdownOptions}
            onChange={gtmStore.handleSelect}
            name="accountIdx"
          />
        </Card.Content>
      </Card>
    );
  }

  containersCard(gtmStore = this.props.store.integrations.GoogleTagManager) {
    const { accounts, selected } = gtmStore;
    const { accountIdx, containerIdx } = selected;

    const defaultDescription = 'Select your GTM container below';
    const selectedAccount = accounts[accountIdx] || {};
    const accountSelected = _.isEmpty(selectedAccount);

    const { containers = [] } = selectedAccount;
    const selectedContainer = containers[containerIdx] || {};

    const dropdownOptions = containers.map((container, i) => {
      const { name, containerId, path } = container;

      return {
        key: path,
        text: `${name} - ${containerId}`,
        value: i,
      };
    });

    return (
      <Card color="orange" style={accountSelected ? { backgroundColor: 'grey' } : {}}>
        <Card.Content>
          <Card.Header>
            Container:
            <a href={selectedContainer.tagManagerUrl} target="_blank" rel="noopener noreferrer">
              {` ${selectedContainer.name || ''}`}
            </a>
          </Card.Header>
          <Card.Meta>
            ID:
            {` ${selectedContainer.containerId || ''}`}
          </Card.Meta>
          <Card.Description>
            {
              _.isEmpty(selectedContainer)
                ? defaultDescription
                : `Public ID: ${selectedContainer.publicId}`
            }
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Dropdown
            disabled={accountSelected}
            style={accountSelected ? { backgroundColor: 'grey' } : {}}
            placeholder="Container"
            fluid
            selection
            options={dropdownOptions}
            onChange={gtmStore.handleSelect}
            name="containerIdx"
          />
        </Card.Content>
      </Card>
    );
  }

  workspacesCard(gtmStore = this.props.store.integrations.GoogleTagManager) {
    const { accounts, selected } = gtmStore;
    const { accountIdx, containerIdx, workspaceIdx } = selected;

    const defaultDescription = 'Select your GTM workspace below';
    const selectedAccount = accounts[accountIdx] || {};

    const { containers = [] } = selectedAccount;
    const selectedContainer = containers[containerIdx] || {};
    const containerSelected = _.isEmpty(selectedContainer);

    const { workspaces = [] } = selectedContainer;
    const selectedWorkspace = workspaces[workspaceIdx] || {};

    const dropdownOptions = workspaces.map((workspace, i) => {
      const { name, workspaceId, path } = workspace;

      return {
        key: path,
        text: `${name} - ${workspaceId}`,
        value: i,
      };
    });

    return (
      <Card color="blue" style={containerSelected ? { backgroundColor: 'grey' } : {}}>
        <Card.Content>
          <Card.Header>
            Workspace:
            <a href={selectedWorkspace.tagManagerUrl} target="_blank" rel="noopener noreferrer">
              {` ${selectedWorkspace.name || ''}`}
            </a>
          </Card.Header>
          <Card.Meta>
            ID:
            {` ${selectedWorkspace.workspaceId || ''}`}
          </Card.Meta>
          <Card.Description>
            {
              _.isEmpty(selectedWorkspace)
                ? defaultDescription
                : ''
            }
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Dropdown
            disabled={containerSelected}
            style={containerSelected ? { backgroundColor: 'grey' } : {}}
            placeholder="Workspace"
            fluid
            selection
            options={dropdownOptions}
            onChange={gtmStore.handleSelect}
            name="workspaceIdx"
          />
        </Card.Content>
      </Card>
    );
  }

  tagsCard(gtmStore = this.props.store.integrations.GoogleTagManager) {
    const { accounts, selected } = gtmStore;
    const { accountIdx, containerIdx, workspaceIdx } = selected;

    const selectedAccount = accounts[accountIdx] || {};

    const { containers = [] } = selectedAccount;
    const selectedContainer = containers[containerIdx] || {};

    const { workspaces = [] } = selectedContainer;
    const selectedWorkspace = workspaces[workspaceIdx] || {};
    const workspaceSelected = !_.isEmpty(selectedWorkspace);

    const { tags = [] } = selectedWorkspace;
    const tagCards = tags
      .sort((a, b) => a.tagId - b.tagId)
      .map((tag) => {
        const {
          tagId, path, name, tagManagerUrl,
        } = tag;

        const colorProp = { color: name.toLowerCase().includes('capture') ? 'green' : 'grey' };

        return (
          <Segment key={path} {...colorProp}>
            <a href={tagManagerUrl} target="_blank" rel="noopener noreferrer">
              {`ID: ${tagId} - ${name}`}
            </a>
          </Segment>
        );
      });

    return (
      <Card color="green" style={!workspaceSelected ? { backgroundColor: 'grey' } : {}}>
        <Card.Content>
          <Card.Header>
            Tags
          </Card.Header>
          <Card.Description>
            {
              workspaceSelected
                ? tagCards
                : <Segment style={{ backgroundColor: 'grey' }}>No tags</Segment>
            }
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }

  render() {
    const {
      authUrl, loading, anyLoading, selected,
    } = this.props.store.integrations.GoogleTagManager;

    return (
      <>
        <Header as="h3" attached="top">
          Setup
        </Header>

        <Segment attached>
          <List ordered>
            <List.Item>
              Log in to your
              <a target="_blank" rel="noopener noreferrer" href="https://tagmanager.google.com/"> Google Tag Manager </a>
              account.
            </List.Item>
            <List.Item>
              {'Click '}
              <a
                href={authUrl}
                target="_blank"
                rel="noopener noreferrer"
                disabled={loading.getAuthUrl}
              >
                {'here '}
              </a>
              to grant Movology&apos;s Capture application necessary permissions.
            </List.Item>
            <List.Item>
              After authorizing our application, refresh this page.
            </List.Item>
            <List.Item>
              Select your GTM account, container, and workspace below.
              <List.List>
                <List.Item>
                  Optionally create a new workspace by hitting &quot;Create Workspace&quot;
                  after selecting your container.
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Once you select your workspace you should see a list of your current tags.
              Capture tags will be marked green.
            </List.Item>
            <List.Item>
              Select &quot;Add Capture Tag&quot; to add the tag to your selected workspace.
              By default, the tag will be triggered on &quot;All Pages&quot;.
            </List.Item>
            <List.Item>
              You can review your workspace changes and any GTM resources
              by selecting the links on the cards.
              <br />
              After reviewing the changes, publish your workspace by selecting
              &quot;Publish Workspace&quot; or through the GTM console.
            </List.Item>
            <List.Item>
              You are all set! Start adding your forms and we will capture your abandons.
            </List.Item>
            <List.Item>
              To disable our snippet, remove it in your GTM console then publish.
            </List.Item>
          </List>
        </Segment>
        <Header as="h3" attached="top">
          Settings
        </Header>
        <Segment attached>
          {loading.getAllResources && loader}
          <Card.Group itemsPerRow={1} centered>
            {this.accountsCard(this.props.store.integrations.GoogleTagManager)}
            {this.containersCard(this.props.store.integrations.GoogleTagManager)}
            {this.workspacesCard(this.props.store.integrations.GoogleTagManager)}
            {this.tagsCard(this.props.store.integrations.GoogleTagManager)}
          </Card.Group>
        </Segment>
        <Segment attached>
          <Button
            positive
            disabled={anyLoading || selected.containerIdx === null}
            loading={loading.createWorkspace}
            onClick={this.props.store.integrations.GoogleTagManager.createWorkspace}
          >
            Create Workspace
          </Button>
          <Button
            positive
            disabled={anyLoading || _.some(selected, idx => idx === null)}
            loading={loading.addCaptureTag}
            onClick={this.props.store.integrations.GoogleTagManager.addCaptureTag}
          >
            Add Capture Tag
          </Button>
          <Button
            positive
            disabled={anyLoading || _.some(selected, idx => idx === null)}
            loading={loading.publishWorkspace}
            onClick={this.props.store.integrations.GoogleTagManager.publishWorkspace}
          >
            Publish Workspace
          </Button>
        </Segment>
      </>
    );
  }
}

export default withRouter(Setup);
