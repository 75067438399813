import React, { Component } from 'react';
import {
  Elements, StripeProvider,
} from 'react-stripe-elements';
import { STRIPE_API_KEY } from '../../../../configs';
import InjectedCardSection from './InjectedCardSection';

class CardInput extends Component {
  render() {
    return (
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <Elements>
          <InjectedCardSection />
        </Elements>
      </StripeProvider>
    );
  }
}

export default CardInput;
