import React, { Component, Fragment } from 'react';
import {
  Modal, Button, Form, Header, Input, Message,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { modalTypes } from '../../../../enums';
import { validateUrl } from '../../../../helper';

@inject('store')
@observer
class FormsModal extends Component {
  state = {
    urlToAdd: '',
    error: false,
  }

  inputHandler = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  cancelHandler = () => {
    this.props.onClose();
    this.setState({
      urlToAdd: '',
      error: false,
    });
  }

  addHandler = async (url) => {
    if (!validateUrl(url)) {
      this.setState({
        error: true,
      });
    }
    // validateUrl returns a boolean
    if (validateUrl(url)) {
      this.setState({
        urlToAdd: '',
      }, this.props.onClose());
      await this.props.store.forms.addFormLink(url);
    }
  }

  removeHandler = async (url) => {
    await this.props.store.forms.removeFormLink(url);
    this.props.onClose();
  }

  addModal = () => (
    <Fragment>
      <Modal.Header>
        Add Form
      </Modal.Header>
      <Modal.Content>
        <Form error>
          <Form.Field
            error={this.state.error}
          >
            <Header size="tiny">
              Please enter the URL of the form you would like to track.
              <div>
                <small>Example: https://www.formURL.com</small>
              </div>
            </Header>
            <Input
              fluid
              placeholder="https://www.formURL.com"
              value={this.state.urlToAdd}
              onChange={this.inputHandler}
              name="urlToAdd"
              error={this.state.error}
            />
          </Form.Field>
          {this.state.error ? (
            <Message
              error
              content="URL is not formatted properly"
            />
          ) : ''}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={this.cancelHandler}
        >
          Cancel
        </Button>
        <Button
          positive
          onClick={() => this.addHandler(this.state.urlToAdd)}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Fragment>
  )

  removeModal = () => (
    <Fragment>
      <Modal.Header>
        Delete Form
      </Modal.Header>
      <Modal.Content>
        Are you sure you want to delete
        {' '}
        <strong>{this.props.url}</strong>
        ?
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={this.cancelHandler}
        >
          Cancel
        </Button>
        <Button
          negative
          loading={this.props.store.forms.loading}
          disabled={this.props.store.forms.loading}
          onClick={() => this.removeHandler(this.props.url)}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Fragment>
  )

  render() {
    const { open, onClose, action } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        closeOnDimmerClick={false}
      >
        {
          action === modalTypes.add ? this.addModal()
            : this.removeModal()
        }
      </Modal>
    );
  }
}

export default FormsModal;
