import axios from 'axios';
import { action, observable } from 'mobx';
import { API_URL } from '../configs';
import { validateUrl } from '../helper';
import { toastTypes } from '../enums';

class ClientHooks {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  hookObjTemplate = {
    dataToSend: {
      capture: {
        created_ts: false,
        email: false,
        email_count: false,
        form_data: false,
        url: false,
      },
    },
    disabled: false,
    name: '',
    url: '',
  }

  @observable hooks = {};

  @action reset = () => {
    this.hooks = {};
  }

  @action getAllHooks = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/clientHooks`);
      if (res.data.success) {
        this.hooks = res.data.hooks || {};
      } else {
        this.rootStore.failure('Failed to retrieve webhooks.');
      }
    } catch (e) {
      this.rootStore.failure('Failed to retrieve webhooks.');
    }
  }

  @action deleteHook = async (hookId) => {
    try {
      const res = await axios.delete(`${API_URL}/api/clientHooks/${hookId}`);

      if (res.data.success) {
        this.rootStore.success('Webhook deleted successfully!', toastTypes.deleteHook);
        this.rootStore.clearToast(toastTypes.deleteHook);
      } else {
        this.rootStore.failure('Failed to delete webhook.', toastTypes.deleteHook);
      }
    } catch (e) {
      this.rootStore.failure('Failed to delete webhook.', toastTypes.deleteHook);
    } finally {
      this.getAllHooks();
    }
  }

  @action addHook = async (hook) => {
    try {
      const sanitizedHook = hook;
      sanitizedHook.name = sanitizedHook.name.trim();
      sanitizedHook.url = validateUrl(sanitizedHook.url);

      const res = await axios.post(`${API_URL}/api/clientHooks/`, {
        hook: sanitizedHook,
      });

      if (res.data.success) {
        this.rootStore.success('Webhook added successfully!', toastTypes.addHook);
        this.rootStore.clearToast(toastTypes.addHook);
      } else {
        this.rootStore.failure('Failed to add a webhook.', toastTypes.addHook);
      }
    } catch (e) {
      this.rootStore.failure('Failed to add a webhook.', toastTypes.addHook);
    } finally {
      this.getAllHooks();
    }
  }

  @action updateHook = async (hookId, hook) => {
    try {
      const sanitizedHook = hook;
      sanitizedHook.name = sanitizedHook.name.trim();
      sanitizedHook.url = validateUrl(sanitizedHook.url);

      const res = await axios.patch(`${API_URL}/api/clientHooks/${hookId}`, {
        updatedHook: sanitizedHook,
      });

      if (res.data.success) {
        this.rootStore.success('Webhook updated successfully!', toastTypes.updateHook);
        this.rootStore.clearToast(toastTypes.updateHook);
      } else {
        this.rootStore.failure('Failed to update webhook.', toastTypes.updateHook);
      }
    } catch (e) {
      this.rootStore.failure('Failed to update webhook.', toastTypes.updateHook);
    } finally {
      this.getAllHooks();
    }
  }
}

export default ClientHooks;
