import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Table, Button, Loader, Icon, Segment,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import TemplateDropdown from './Dropdown';

@inject('store')
@observer
class TemplateRow extends Component {
  componentDidUpdate(prevProps) {
    const { active, template: templateProps } = this.props;
    if (prevProps.active !== active && !templateProps.template) {
      this.props.store.emailManagement.getTemplate(templateProps.name);
    }
  }

  createDropdown() {
    return (
      <Table.Row>
        <Table.Cell colSpan="3">
          <Segment>
            {
              this.props.store.emailManagement.loading.getTemplate
                ? (<Loader active inline="centered" />)
                : (<TemplateDropdown template={this.props.template} />)
            }
          </Segment>
        </Table.Cell>
      </Table.Row>
    );
  }

  async handlePreview() {
    let html = _.get(this.props, 'template.template');
    if (!html) {
      const template = await this.props.store.emailManagement.getTemplate(this.props.template.name);
      html = template.template;
    }
    const preview = window.open();
    preview.document.open();
    preview.document.write(html);
    preview.document.close();
  }

  render() {
    const { active, template } = this.props;
    const { name } = template;
    return (
      <Fragment>
        <Table.Row
          active={this.props.active}
          name={name}
        >
          <Table.Cell onClick={e => this.props.onClick(e, this.props)}>
            <Icon name="dropdown" {...(active ? {} : { rotated: 'counterclockwise' })} />
            {name}
          </Table.Cell>

          <Table.Cell>
            {moment.utc(this.props.template.createdAt, 'ddd, DD MMM YYYY hh:mm:ss').format('MM/DD/YY')}
          </Table.Cell>

          <Table.Cell textAlign="center">
            <Button.Group>
              <Button
                onClick={() => this.handlePreview()}
                basic
                icon="eye"
                data-tooltip="Preview"
                data-position="top center"
                disabled={this.props.store.emailManagement.loading.getTemplate}
              />
              <Button
                type="sendTestEmail"
                onClick={(e, props) => this.props.openModal(e, {
                  ...props,
                  name,
                })}
                basic
                icon="envelope outline"
                data-tooltip="Test E-mail"
                data-position="top center"
              />
              <Button
                onClick={e => this.props.onClick(e, this.props)}
                basic
                icon="pencil"
                data-tooltip="Edit"
                data-position="top center"
              />
              <Button
                type="deleteTemplate"
                onClick={(e, props) => this.props.openModal(e, {
                  ...props,
                  name,
                })}
                basic
                icon="trash"
                data-tooltip="Delete"
                data-position="top center"
              />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
        {
          active
            ? this.createDropdown()
            : null
        }
      </Fragment>
    );
  }
}

export default TemplateRow;
