import _ from 'lodash';
import axios from 'axios';
import { observable, action, computed } from 'mobx';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

import { API_URL } from '../configs';
import { toastTypes } from '../enums';

class EmailManagement {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.uppyInit();
  }

  @observable
  activeEmailDomainName = '';

  @observable
  emailDomains = {};

  @observable
  templates = [];

  @observable
  relevantTemplates = [];

  @observable loading = {
    getDomains: false,
    getTemplate: false,
    getTemplates: false,
    addTemplate: false,
    uploadTemplates: false,
    updateTemplate: false,
    deleteTemplate: false,
    sendTestEmail: false,
    saveEmailSettings: false,
    saveEmailLogic: false,
    addEmailDomain: false,
    getNSRecords: false,
    verifyRecords: false,
  };

  @computed get activeEmailDomain() {
    return this.emailDomains[this.activeEmailDomainName] || {};
  }

  uppyInit() {
    this.uppy = Uppy({
      meta: { type: 'templates' },
      restrictions: {
        maxNumberOfFiles: 10,
        maxFileSize: 1024 * 1024,
        allowedFileTypes: ['.html', '.htm', '.hbm', '.handlebars'],
      },
      allowMultipleUploads: true,
      autoProceed: false,
      debug: process.env.REACT_APP_NODE_ENV !== 'production',
    });

    this.uppy.use(XHRUpload, {
      id: 'XHRUpload',
      endpoint: `${API_URL}/api/em/email-templates`,
      method: 'POST',
      formData: true,
      fieldName: 'templates',
      headers: {
        Authorization: null,
      },
      body: {
        emailDomain: this.emailDomain,
      },
    });

    this.uppy.on('complete', () => {
      this.getTemplates();
    });
  }

  getIdToken() {
    return this.rootStore.firebase.auth().currentUser.getIdToken();
  }

  @action
  reset = () => {
    this.activeEmailDomainName = '';
    this.emailDomains = {};
    this.templates = [];
    this.loading = _.mapValues(this.loading, () => false);
    this.uppy.close();
    this.uppyInit();
  }

  @action
  setActiveEmailDomainName = (e, { value: emailDomainName }) => {
    if (this.emailDomains[emailDomainName]) {
      this.activeEmailDomainName = emailDomainName;
      this.getTemplates();
      return true;
    } else {
      this.rootStore.failure('Invalid email domain!');
    }
    return false;
  }

  @action
  getEmailDomains = async () => {
    this.loading.getDomains = true;
    try {
      const { data } = await axios.get(`${API_URL}/api/em/email-domains`);
      this.emailDomains = data.emailDomains || {};
      if (_.size(this.emailDomains) && !this.activeEmailDomainName) {
        [this.activeEmailDomainName] = Object.keys(this.emailDomains);
      }
    } catch (err) {
      this.rootStore.failure('Error getting email Domains');
    }
    this.loading.getDomains = false;
    return this.emailDomains;
  }

  @action
  getTemplate = async (templateName) => {
    this.loading.getTemplate = true;
    let template;
    try {
      const { data } = await axios.get(`${API_URL}/api/em/email-template?name=${templateName}&emailDomain=${this.activeEmailDomainName}`);
      const templateIndex = this.templates.findIndex(t => t.name === templateName);
      template = {
        ...this.templates[templateIndex],
        ...data.template.version,
      };
      this.templates[templateIndex] = template;
    } catch (err) {
      this.rootStore.failure('Error getting template HTML!');
    }
    this.loading.getTemplate = false;
    return template;
  }

  @action
  getTemplates = async (active = false, limit = 100) => {
    if (!this.activeEmailDomainName) {
      return;
    }
    this.loading.getTemplates = true;
    try {
      const { data } = await axios.get(`${API_URL}/api/em/email-templates?active=${active}&limit=${limit}&emailDomain=${this.activeEmailDomainName}`);
      if (data.success) {
        this.templates = data.templates || [];
      }
    } catch (err) {
      this.templates = [];
      this.rootStore.failure('Failed to get templates');
    }
    this.loading.getTemplates = false;
  }

  @action
  addTemplate = async ({
    name, template, description, tag, comment, active, versioned,
  }) => {
    this.loading.uploadTemplate = true;
    try {
      await axios.post(`${API_URL}/api/em/email-template`, {
        name,
        template,
        description,
        tag,
        comment,
        active,
        versioned,
        emailDomain: this.activeEmailDomainName,
      });
      this.getTemplates();
      this.rootStore.clearToast(toastTypes.addTemplate);
      this.rootStore.success('Successfully added template!', toastTypes.addTemplate);
    } catch (err) {
      this.rootStore.failure('Error uploading single template', toastTypes.addTemplate);
    }
    this.loading.uploadTemplate = false;
  }

  @action
  uploadTemplates = async () => {
    this.loading.uploadTemplates = true;
    const xhrUpload = this.uppy.getPlugin('XHRUpload');
    _.set(xhrUpload, 'opts.headers.Authorization', await this.getIdToken());
    _.set(xhrUpload, 'opts.headers.EmailDomain', this.activeEmailDomainName);

    try {
      const res = await this.uppy.upload();
      if (res.successful.every(file => file.response.body.success)) {
        this.rootStore.success('Successfully uploaded template(s)!');
      } else {
        throw new Error('Failed to upload email template');
      }
    } catch (err) {
      this.rootStore.failure('Error uploading email templates');
    }
    this.uppy.reset();
    this.loading.uploadTemplates = false;
  }

  @action
  updateTemplate = async ({
    name,
    template,
    comment,
    description,
    versionTag,
  }) => {
    this.loading.updateTemplate = true;
    try {
      await axios.put(`${API_URL}/api/em/email-template`, {
        name,
        template,
        comment,
        description,
        active: true,
        versionTag,
        emailDomain: this.activeEmailDomainName,
      });
      const templateIndex = this.templates.findIndex(t => t.name === name);
      _.set(this.templates, [templateIndex, 'template'], template);
      this.rootStore.success('Successfully updated template!', toastTypes.updateTemplate);
      this.rootStore.clearToast(toastTypes.updateTemplate);
    } catch (err) {
      this.rootStore.failure('Error updating template', toastTypes.updateTemplate);
    }
    this.loading.updateTemplate = false;
  }

  @action
  deleteTemplate = async (name, versionTag) => {
    this.loading.deleteTemplate = true;
    try {
      await axios.delete(`${API_URL}/api/em/email-template`, {
        data: {
          name,
          versionTag,
          emailDomain: this.activeEmailDomainName,
        },
      });
      this.getTemplates();
      this.rootStore.success('Successfully deleted template', toastTypes.deleteTemplate);
      this.rootStore.clearToast(toastTypes.deleteTemplate);
    } catch (err) {
      this.rootStore.failure('Error deleting template.', toastTypes.deleteTemplate);
    }
    this.loading.deleteTemplate = false;
  }

  @action
  sendTestEmail = async ({
    to, from, subject, name, formData,
  }) => {
    this.loading.sendTestEmail = true;
    try {
      await axios.post(`${API_URL}/api/em/test-email`, {
        to,
        from,
        subject,
        templateName: name,
        formData,
        emailDomain: this.activeEmailDomainName,
      });
      this.rootStore.success(`Successfully sent test e-mail to ${to}`, toastTypes.sendTestEmail);
      this.rootStore.clearToast(toastTypes.sendTestEmail);
    } catch (err) {
      this.rootStore.failure('Error sending test e-mail', toastTypes.sendTestEmail);
    }
    this.loading.sendTestEmail = false;
  }

  @action
  saveEmailSettings = async ({
    url, domain, sender, logic,
  }) => {
    this.loading.saveEmailSettings = true;
    try {
      if (!sender) {
        this.rootStore.failure('Sender is required', toastTypes.saveEmailSettings);
        this.loading.saveEmailSettings = false;
        return;
      }
      if (!this.emailDomains[domain]) {
        this.rootStore.failure('Invalid email domain', toastTypes.saveEmailSettings);
        this.loading.saveEmailSettings = false;
        return;
      }
      await axios.post(`${API_URL}/api/forms/recover-meta`, {
        url: url.href,
        emailDomain: domain,
        emailSender: sender,
      });

      const updateObj = {
        emailLogic: {
          emailDomain: domain,
          emailSender: sender,
          logic,
        },
      };
      this.rootStore.forms.updateForm({ url, diff: updateObj });
      this.rootStore.success('Successfully updated recover form settings', toastTypes.saveEmailSettings);
      this.rootStore.clearToast('saveEmailSettings', toastTypes.saveEmailSettings);
    } catch (err) {
      this.rootStore.failure('Failed to update recover form settings', toastTypes.saveEmailSettings);
    }
    this.loading.saveEmailSettings = false;
  }

  @action
  saveEmailLogic = async ({
    url, logic, emailDomain, emailSender,
  }) => {
    this.loading.saveEmailLogic = true;
    try {
      await axios.post(`${API_URL}/api/forms/recover-logic`, {
        url,
        logic,
        emailDomain,
        emailSender,
      });

      const updateObj = { emailLogic: { logic }, emailDomain, emailSender };
      this.rootStore.forms.updateForm({ url, diff: updateObj });
      this.rootStore.success('Successfully updated recover logic', toastTypes.saveEmailLogic);
      this.rootStore.clearToast(toastTypes.saveEmailLogic);
    } catch (err) {
      this.rootStore.failure('Failed to save recover logic', toastTypes.saveEmailLogic);
    }
    this.loading.saveEmailLogic = false;
  }

  @action
  saveRecoverEmails = async ({
    url, logic, emailDomain, emailSender,
  }) => {
    this.loading.saveEmailLogic = true;
    if (logic.some(template => !template.minuteDifference || !template.subject)) {
      this.rootStore.failure('E-mails need a subject and hour difference!', toastTypes.saveEmailLogic);
    } else {
      try {
        await axios.post(`${API_URL}/api/forms/recover-logic`, {
          url,
          logic,
          emailDomain,
          emailSender,
        });
        const updateObj = { emailLogic: { logic }, emailDomain, emailSender };
        this.rootStore.forms.updateForm({ url, diff: updateObj });
        this.rootStore.success('Successfully updated recover logic', toastTypes.saveEmailLogic);
        this.rootStore.clearToast(toastTypes.saveEmailLogic);
      } catch (error) {
        this.rootStore.failure('Failed to save recover logic', toastTypes.saveEmailLogic);
      }
    }
    this.loading.saveEmailLogic = false;
  }


  @action
  getNSRecords = async ({ emailDomain }) => {
    this.loading.getNSRecords = true;
    try {
      const { data } = await axios.get(`${API_URL}/api/records/retrieve-ns-records`);
      this.rootStore.success('Successfully retrieved NS records', toastTypes.getNSRecords);
      this.rootStore.clearToast(toastTypes.getNSRecords);
      return data.records;
    } catch (err) {
      this.rootStore.failure(`Failed to get NS records for domain: ${emailDomain}`, toastTypes.getNSRecords);
    }
    this.loading.getNSRecords = false;
    return null;
  }

  @action
  addEmailDomain = async ({ emailDomain }) => {
    this.loading.addEmailDomain = true;
    try {
      const { data } = await axios.post(`${API_URL}/api/records/setup-dns-records`, {
        emailDomain,
      });
      if (data.success) {
        await this.getEmailDomains();
        this.setActiveEmailDomainName(null, { value: emailDomain });
        this.rootStore.success(`Successfully added email domain: ${emailDomain}!`, toastTypes.addEmailDomain);
        this.rootStore.clearToast(toastTypes.addEmailDomain);
      } else {
        this.rootStore.failure(`Failed to add domain: ${emailDomain}`, toastTypes.addEmailDomain);
      }
    } catch (err) {
      this.rootStore.failure(`Failed to add domain: ${emailDomain}`, toastTypes.addEmailDomain);
    }
    this.loading.addEmailDomain = false;
  }

  @action
  verifyRecords = async ({ emailDomain = this.activeEmailDomainName }) => {
    this.loading.verifyRecords = true;
    try {
      const { data } = await axios.post(`${API_URL}/api/records/verify-dns-records`, {
        emailDomain,
      });
      if (data.success) {
        await this.getEmailDomains();
        this.rootStore.success('Verified DNS records!', toastTypes.verifyRecords);
        this.rootStore.clearToast(toastTypes.verifyRecords);
      } else {
        this.rootStore.failure(`Failed to verify domain: ${emailDomain}`, toastTypes.verifyRecords);
      }
    } catch (err) {
      this.rootStore.failure(`Failed to verify domain: ${emailDomain}`, toastTypes.verifyRecords);
    }
    this.loading.verifyRecords = false;
  }
}

export default EmailManagement;
