import React, { Component, Fragment } from 'react';
import {
  Button, Form, Grid, Header, Message, Segment, Checkbox, Modal,
} from 'semantic-ui-react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import { colors } from '../../../enums';
import tos from '../../../assets/tos';
import './LoginForm.css';

@inject('store')
@observer
class LoginForm extends Component {
  state = {
    status: 'Register',
    modalOpen: false,
    modalType: 'tos',
    formData: {
      fullName: '',
      company: '',
      phone: '',
      email: '',
      websiteUrl: '',
      password: '',
      passwordConfirm: '',
      tosChecked: false,
    },
  }

  componentDidMount() {
    const {
      partner, hmac, shop, code,
    } = this.props.store.initQueryParams;
    /** Shopify auto-login */
    if (hmac && shop) {
      const currentUrl = new URL(window.location.href);
      this.props.store.auth.shopifyLogin(currentUrl.search);
    }
    /** SFMC auto-login */
    if (partner === 'salesforcemarketingcloud' && code) {
      this.props.store.auth.salesforceMarketincCloudLogin(code);
    }
  }

  resetPassword(email) {
    this.props.store.auth.sendCustomPasswordResetEmail(email)
      .then(() => {
        this.props.store.success(`Reset email successfully sent to ${this.state.formData.email}`);
      })
      .catch(() => {
        this.props.store.failure(`An account could not be created for ${this.state.formData.email}. Please contact support.`);
      });
  }

  handleOpen = modalType => this.setState({ modalOpen: true, modalType })

  handleClose = () => this.setState({ modalOpen: false })

  goToSignup = () => {
    this.setState({
      status: 'Register',
    });
  }

  goToLogin = () => {
    this.setState({
      status: 'Login',
    });
  }

  formInputHandler = (e, { name, value }) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  }

  tosToggle = () => this.setState({
    formData: {
      ...this.state.formData,
      tosChecked: !this.state.formData.tosChecked,
    },
  })

  createHubSpotClient = (customerData) => {
    const dataArray = Object.entries(customerData).map((entry) => {
      const [key, value] = entry;
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      return `${encodedKey}=${encodedValue}`;
    });
    const formBody = dataArray.join('&');
    fetch('https://forms.hubspot.com/uploads/form/v2/4595743/2f4d76ba-f873-4350-997c-a0693db2f47d', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
      body: formBody,
    });
  }

  @action
  formSubmitHandler = () => {
    const { password, passwordConfirm, tosChecked } = this.state.formData;
    const {
      email, fullName, company, phone, websiteUrl,
    } = Object.keys(this.state.formData)
      .reduce((acc, key) => {
        acc[key] = this.state.formData[key].trim
          ? this.state.formData[key].trim()
          : this.state.formData[key];
        return acc;
      }, {});

    if (this.state.status === 'Login') {
      this.props.store.auth.login(email, password)
        .then(() => {
          const { partner = '', instance } = this.props.store.initQueryParams;
          if (partner.toLowerCase() === 'wix' && instance) {
            this.props.store.integrations.WiX.linkAccounts(instance);
          }
        })
        .catch((err) => {
          this.props.store.failure(err.message);
          this.props.store.auth.loading = false;
        });
    } else if (this.state.status === 'Register') {
      if (password !== passwordConfirm) {
        this.props.store.failure('Passwords do not match.');
      } else if (!fullName || !phone || !company) {
        this.props.store.failure('All fields are required.');
      } else if (!tosChecked) {
        this.props.store.failure('Please agree to the terms and conditions.');
      } else {
        this.props.store.auth.register(email, password, { fullName, phone, company })
          .then(() => {
            const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            this.createHubSpotClient({
              email,
              phone,
              name: fullName,
              company,
              website_url: websiteUrl,
              get_started_with_capture_: true,
              utm: queryParams.utm || '',
            });

            const { partner = '', instance } = this.props.store.initQueryParams;

            if (partner.toLowerCase() === 'wix' && instance) {
              this.props.store.integrations.WiX.linkAccounts(instance);
            }
          })
          .catch((err) => {
            this.props.store.failure(err.message);
            this.props.store.auth.loading = false;
          });
      }
    }
  }

  sendResetEmail = () => {
    this.props.store.auth.sendCustomPasswordResetEmail(this.state.formData.email.trim())
      .then(() => {
        this.props.store.success(`Reset email successfully sent to ${this.state.formData.email}`);
      })
      .catch((e) => {
        this.props.store.failure('Reset email could not be sent.');
      });
  }

  modal = () => (
    <Modal
      open={this.state.modalOpen}
      onClose={this.handleClose}
    >
      {
        this.state.modalType === 'tos'
          ? (
            <Fragment>
              <Modal.Header>Terms and Conditions</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    {tos}
                  </p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({
                  modalOpen: false,
                })}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  onClick={() => this.setState({
                    modalOpen: false,
                    formData: {
                      ...this.state.formData,
                      tosChecked: true,
                    },
                  })}
                >
                  I agree
                </Button>
              </Modal.Actions>
            </Fragment>
          ) : (
            <Fragment>
              <Modal.Header>Reset your password</Modal.Header>
              <Modal.Content>
                <Form>
                  <Form.Field>
                    <label>
                      Please enter your email address associated with the account.
                    </label>
                    <Form.Input
                      value={this.state.formData.email}
                      onChange={this.formInputHandler}
                      name="email"
                      placeholder="email"
                    />
                  </Form.Field>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({
                  modalOpen: false,
                })}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  onClick={async () => {
                    await this.sendResetEmail();
                    this.setState({
                      modalOpen: false,
                    });
                  }}
                >
                  Reset
                </Button>
              </Modal.Actions>
            </Fragment>
          )
      }
    </Modal>
  )

  render() {
    const { status } = this.state;
    const {
      email, password, passwordConfirm, fullName, tosChecked, company, phone, websiteUrl,
    } = this.state.formData;
    const { mobile, width } = this.props.store.ui;

    return (
      <div id="LoginForm">
        <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={mobile ? { width: `${width - 24}px`, maxWidth: '450px' } : { width: '450px' }}>
            <div id="MovologyLogo" />
            <Header as="h2" style={{ color: colors.primary }} textAlign="center">
              { status === 'Login' ? 'Log-in to your account' : 'Create an Account'}
              <Header.Subheader>
                { status === 'Login' ? false : 'Getting started is easy'}
              </Header.Subheader>
            </Header>
            <Form size="large" onSubmit={this.formSubmitHandler}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  name="email"
                  value={email}
                  onChange={this.formInputHandler}
                  placeholder="E-mail address"
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  name="password"
                  value={password}
                  onChange={this.formInputHandler}
                  placeholder="Password"
                  type="password"
                />
                {
                  status === 'Register' && (
                    <Fragment>
                      <Form.Input
                        fluid
                        icon="lock"
                        iconPosition="left"
                        name="passwordConfirm"
                        value={passwordConfirm}
                        onChange={this.formInputHandler}
                        placeholder="Confirm Password"
                        type="password"
                      />
                      <Form.Input
                        fluid
                        icon="address book"
                        iconPosition="left"
                        name="fullName"
                        value={fullName}
                        onChange={this.formInputHandler}
                        placeholder="Name"
                      />
                      <Form.Input
                        fluid
                        icon="phone"
                        iconPosition="left"
                        name="phone"
                        value={phone}
                        onChange={this.formInputHandler}
                        placeholder="Onboarding Phone Number"
                      />
                      <Form.Input
                        fluid
                        icon="cloud"
                        iconPosition="left"
                        name="websiteUrl"
                        value={websiteUrl}
                        onChange={this.formInputHandler}
                        placeholder="Website URL"
                      />
                      <Form.Input
                        fluid
                        icon="building"
                        iconPosition="left"
                        name="company"
                        value={company}
                        onChange={this.formInputHandler}
                        placeholder="Company"
                      />
                      <Form.Field>
                        <Checkbox
                          style={{ verticalAlign: 'middle' }}
                          onChange={this.tosToggle}
                          checked={tosChecked}
                          label=""
                        />
                        <span>
                          I have read and agree to the
                          {' '}
                          <span className="linkStyling" onClick={() => this.handleOpen('tos')}>
                            Terms and Services.
                          </span>
                        </span>
                      </Form.Field>
                    </Fragment>
                  )
                }
                <Button
                  style={{ backgroundColor: colors.primary, color: 'white' }}
                  fluid
                  size="large"
                  loading={this.props.store.auth.loading}
                  disabled={this.props.store.auth.loading}
                  content={this.state.status}
                />
              </Segment>
            </Form>

            <Message>
              {
                status === 'Login'
                  ? 'New to us?'
                  : 'Already have an account?'
              }
              { status === 'Login'
                ? <span className="linkStyling" onClick={this.goToSignup}> Sign Up</span>
                : <span className="linkStyling" onClick={this.goToLogin}> Login</span>
              }
              <br />
              <span className="linkStyling" onClick={() => this.handleOpen('reset')}>Forgot password?</span>
            </Message>
          </Grid.Column>
        </Grid>

        {this.modal()}
      </div>
    );
  }
}

export default withRouter(LoginForm);
