require('dotenv').config();

let FIREBASE_CONFIGS;
let API_URL;
let STRIPE_API_KEY;
let STRIPE_PRODUCTS;
let DASHBOARD_URL;

if (process.env.REACT_APP_NODE_ENV === 'production') {
  STRIPE_API_KEY = 'pk_live_ktEBpXqNqInnlQdcnswvUBbr';
  API_URL = 'https://movologist.net';
  DASHBOARD_URL = 'https://dashboard.movology.com';
  FIREBASE_CONFIGS = {
    apiKey: 'AIzaSyD_9cmpGFPb66ha8KJ41l8GmXiHZDuq99E',
    authDomain: 'mov-configs.firebaseapp.com',
    databaseURL: 'https://mov-configs.firebaseio.com',
    projectId: 'mov-configs',
    storageBucket: 'mov-configs.appspot.com',
    messagingSenderId: '421258545567',
  };
} else if (process.env.REACT_APP_NODE_ENV === 'development') {
  STRIPE_API_KEY = 'pk_test_hajNoRwmzd4KzMeLNteLZmrL';
  API_URL = 'https://movdigital.net';
  DASHBOARD_URL = 'https://dashboard.movtest.net';
  FIREBASE_CONFIGS = {
    apiKey: 'AIzaSyBhTQRmSaKmf21PcNLdtv-c503DrI3Qq2g',
    authDomain: 'dev-mov-configs.firebaseapp.com',
    databaseURL: 'https://dev-mov-configs.firebaseio.com',
    projectId: 'dev-mov-configs',
    storageBucket: 'dev-mov-configs.appspot.com',
    messagingSenderId: '425559631845',
  };
} else {
  STRIPE_API_KEY = 'pk_live_ktEBpXqNqInnlQdcnswvUBbr';
  // API_URL = 'https://movologist.net';
  // DASHBOARD_URL = 'https://dashboard.movology.com';
  FIREBASE_CONFIGS = {
    apiKey: 'AIzaSyD_9cmpGFPb66ha8KJ41l8GmXiHZDuq99E',
    authDomain: 'mov-configs.firebaseapp.com',
    databaseURL: 'https://mov-configs.firebaseio.com',
    projectId: 'mov-configs',
    storageBucket: 'mov-configs.appspot.com',
    messagingSenderId: '421258545567',
  };
  // STRIPE_API_KEY = 'pk_test_hajNoRwmzd4KzMeLNteLZmrL';
  API_URL = 'http://localhost:3001';
  DASHBOARD_URL = 'http://localhost:3000';
  // FIREBASE_CONFIGS = {
  //   apiKey: 'AIzaSyBhTQRmSaKmf21PcNLdtv-c503DrI3Qq2g',
  //   authDomain: 'dev-mov-configs.firebaseapp.com',
  //   databaseURL: 'https://dev-mov-configs.firebaseio.com',
  //   projectId: 'dev-mov-configs',
  //   storageBucket: 'dev-mov-configs.appspot.com',
  //   messagingSenderId: '425559631845',
  // };
}

export {
  FIREBASE_CONFIGS,
  API_URL,
  DASHBOARD_URL,
  STRIPE_API_KEY,
  STRIPE_PRODUCTS,
};
