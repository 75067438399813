import React, { Component } from 'react';
import _ from 'lodash';
import {
  Segment, Grid, Statistic, Header, Image, Loader, Dimmer,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Bar } from 'react-chartjs-2';
// import { keygen } from '../../../helper';
import { colors } from '../../../enums';

const loader = (
  <Dimmer active>
    <Loader active inline="centered" size="large" />
  </Dimmer>
);

const GetAMP = () => (
  <Grid>
    <Grid.Column>
      <Grid.Row textAlign="center" style={{ paddingTop: '24px' }}>
        <Image
          src="https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2Famp_logo_v1.png?alt=media&token=59b4f6c4-9da6-4af1-847a-322cb6de6eee"
          size="medium"
          centered
        />
      </Grid.Row>
      <Grid.Row style={{ paddingTop: '48px' }}>
        <Image
          src="https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/creative_assets%2FMOVology%2Famp_steps.jpg?alt=media&token=8f257af0-73d1-4d17-a5b4-10644e750660"
          centered
          style={{ width: '80%', height: 'auto' }}
          bordered
        />
      </Grid.Row>
      <Grid.Row style={{ paddingTop: '48px' }}>
        <Header textAlign="center">
          Interested? Contact our sales team at
          {' '}
          <a href="mailto: sales@automatedremarketing.com">sales@automatedremarketing.com</a>
        </Header>
      </Grid.Row>
    </Grid.Column>
  </Grid>
);

@inject('store')
@observer
class AMPPage extends Component {
  constructor(props) {
    super(props);
    this.auth = this.props.store.auth;
    this.amp = this.props.store.amp;
  }

  componentDidMount() {
    const { user = {} } = this.auth;
    if (user.features && user.features.amp && _.isEmpty(this.amp.ampData)) {
      this.amp.getAmpData();
    }
  }

  render() {
    const { user } = this.auth;
    if (this.auth.loading) {
      return loader;
    }
    const { ampData } = this.amp;
    if (user.features.AMP && !_.isEmpty(ampData)) {
      const overviewStatConfigs = [
        {
          label: 'Total Visitors',
          value: ampData.overview.totalVisits,
          style: { borderBottom: `4px solid ${colors.teal}` },
        },
        {
          label: 'Unique Visitors',
          value: ampData.overview.uniqueVisits,
          style: { borderBottom: `4px solid ${colors.purple}` },
        },
      ];
      const timeSeries = ampData['time-series'].stats;
      const dates = timeSeries.map(unit => unit.type.slice(0, 10));
      const totalVisits = timeSeries.map(unit => unit.total);
      const uniqueVisits = timeSeries.map(unit => unit.unique);
      const barGraphConfig = {
        labels: dates,
        datasets: [
          {
            label: 'Total Visitors',
            data: totalVisits,
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Unique Visitors',
            data: uniqueVisits,
            backgroundColor: 'rgb(75, 192, 192)',
          },
        ],
      };
      const barGraphOptions = {
        plugins: {
          labels: {
            fontSize: 0,
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              lineWidth: 2,
            },
          }],
          yAxes: [{
            stacked: true,
          }],
        },
      };

      const mediaReachStats = [
        // {
        //   label: 'Emails',
        //   value: ampData['media-channel-reach'][0].value,
        //   style: { borderBottom: `4px solid ${colors.teal}` },
        // },
        // {
        //   label: 'Socials',
        //   value: ampData['media-channel-reach'][1].value,
        //   style: { borderBottom: `4px solid ${colors.red}` },
        // },
        {
          label: 'Display',
          value: ampData['media-channel-reach'][2].value,
          style: { borderBottom: `4px solid ${colors.orange}` },
        },
        {
          label: 'Direct Mail',
          value: ampData['media-channel-reach'][3].value,
          style: { borderBottom: `4px solid ${colors.yellow}` },
        },
      ];

      return (
        <Grid divided>
          <Grid.Row columns={2}>
            {overviewStatConfigs.map(({ label, value, style }) => (
              <Grid.Column key={label} textAlign="center">
                <Segment raised style={style}>
                  <Statistic size="tiny" style={{ margin: 0 }}>
                    <Statistic.Value>
                      {value.toLocaleString()}
                    </Statistic.Value>
                    <Statistic.Label>
                      {label}
                    </Statistic.Label>
                  </Statistic>
                </Segment>
              </Grid.Column>
            ))}
          </Grid.Row>
          <Grid.Row>
            <Bar data={barGraphConfig} options={barGraphOptions} />
          </Grid.Row>
          <Grid.Row columns={2}>
            {mediaReachStats.map(({ label, value, style }) => (
              <Grid.Column key={label} textAlign="center">
                <Segment raised style={style}>
                  <Statistic size="tiny" style={{ margin: 0 }}>
                    <Statistic.Value>
                      {value.toLocaleString()}
                    </Statistic.Value>
                    <Statistic.Label>
                      {label}
                    </Statistic.Label>
                  </Statistic>
                </Segment>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      );
    }
    return (<GetAMP />);
  }
}

export default AMPPage;
