/* eslint-disable camelcase */

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Menu, Icon } from 'semantic-ui-react';
import qs from 'query-string';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import configs from '../configs';

import Setup from './Setup';
import Pricing from './Pricing';


@inject('store')
@observer
class Shopify extends Component {
  state = {
    activeTab: 'Setup',
  }

  componentDidMount() {
    this.props.store.integrations.Shopify.getConfigs();
    const {
      code, hmac, shop, state, charge_id,
    } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (code) {
      this.props.store.integrations.Shopify.getTokens({
        code, hmac, shop, state,
      })
        .then(() => {
          this.props.history.replace(this.props.location.pathname);
        });
    }

    if (charge_id) {
      this.props.store.integrations.Shopify.activateCharge(charge_id)
        .then(() => this.props.store.integrations.Shopify.getConfigs());
    }
  }

  handleMenuClick = (e, { name }) => {
    this.props.history.replace(name);
    this.setState({
      activeTab: name,
    });
  }

  hasIntegration = () => _.get(this.props.store.integrations.Shopify, ['configs', 'storeDetails'])

  render() {
    return (
      <>
        <Menu>
          <Menu.Item onClick={() => this.props.history.push('/integrations')}>
            <Icon name="arrow left" />
            <Header as="h3" content={configs.Shopify.displayName} style={{ marginTop: 0 }} />
          </Menu.Item>

          <Menu.Item
            name="Setup"
            content="Setup"
            onClick={this.handleMenuClick}
            active={this.state.activeTab === 'Setup'}
          />

          {
            this.hasIntegration() ? (
              <Menu.Item
                name="IntegrationPricing"
                content="Integration Pricing"
                onClick={this.handleMenuClick}
                active={this.state.activeTab === 'IntegrationPricing'}
              />
            ) : null
          }

        </Menu>

        <Switch>
          <Route path="/integrations/Shopify/Setup" component={Setup} />
          <Route path="/integrations/Shopify/IntegrationPricing" component={Pricing} />
          <Redirect from="/integrations/Shopify" to="/integrations/Shopify/Setup" />
        </Switch>
      </>
    );
  }
}

export default Shopify;
