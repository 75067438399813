import { observable, action, computed } from 'mobx';

class UI {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  width = 1200;

  @computed
  get mobile() {
    return this.width < 768;
  }

  @action
  onWindowResize = (e, { width }) => {
    this.width = width;
  }
}

export default UI;
