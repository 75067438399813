import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL, DASHBOARD_URL } from '../../configs';
import { toastTypes } from '../../enums';

class WiX {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    enabled: false,
  };

  @observable
  embeddedScriptParams = {};

  @observable
  loading = false;

  @observable
  authUrl = `https://www.wix.com/app-oauth-installation/consent?token=&appId=0012f1cd-2f95-40f3-8962-bb51278751d4&redirectUrl=${encodeURIComponent(DASHBOARD_URL)}/integrations/WiX&state=WiX`

  @action
  reset = () => {
    this.configs = {};
    this.loading = false;
  }

  getAuthUrl = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/Wix/auth-url`);
      if (res.data.success) {
        this.authUrl = res.data.authUrl;
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  }

  @action
  linkAccounts = async (instanceToken) => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/Wix/link-accounts`, { instanceToken });
      if (data.success) {
        this.rootStore.success('Successfully linked Movology & Wix accounts!', toastTypes.linkAccounts);
        this.rootStore.clearToast(toastTypes.linkAccounts);
      }
    } catch (err) {
      this.rootStore.failure('Failed to link Mov & Wix accounts', toastTypes.linkAccounts);
    }
    this.loading = false;
  }

  @action
  getConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Wix/config`);

      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (err) {
      // this.rootStore.failure('Could not find integration configs');
    } finally {
      this.loading = false;
    }
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/Wix/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.wixUpdateConfigs);
        this.rootStore.clearToast(toastTypes.wixUpdateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.wixUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading = false;
    }
  }

  @action
  getEmbeddedScriptParams = async () => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Wix/embedded-scripts`);
      if (data.success) {
        this.embeddedScriptParams = data.embeddedScriptParams;
      } else {
        throw new Error();
      }
    } catch (e) {
      // this.rootStore.failure('Failed to fetch embedded scripts');
    }
    this.loading = false;
  }
}

export default WiX;
