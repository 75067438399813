import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  Header, Card, Segment, List, Statistic, Button, Divider, Icon,
} from 'semantic-ui-react';

@inject('store')
@observer
class Pricing extends Component {
  upgradePlan = async (name) => {
    const { paymentAuthLink } = await this.props.store.integrations.Shopify.paymentUrl(name);
    window.location.href = paymentAuthLink;
  }

  renderUpgrade = (name) => {
    const currentPlan = _.get(this.props.store.integrations.Shopify.configs, 'integrationPricingTier');
    if (name === currentPlan) {
      return 'Current Plan';
    }

    return (
      <Button onClick={() => { this.upgradePlan(name); }}>Select Plan</Button>
    );
  }

  get pricingTiers() {
    const { tierList } = this.props.store.integrations.Shopify;

    return (
      tierList.map(tierObj => (
        <Card key={tierObj.name}>
          <Card.Content>
            <Card.Header>{tierObj.name}</Card.Header>
            <Card.Meta>{tierObj.header}</Card.Meta>
            <Divider />

            <Icon name={tierObj.icon} color={tierObj.color} size="massive" />

            <Statistic>
              <Statistic.Value>
                $
                {tierObj.price}
              </Statistic.Value>
              <Statistic.Label>per month</Statistic.Label>
            </Statistic>

            <Card.Description>
              <List>
                {tierObj.planDescription.map(description => (
                  <List.Item key={description}>
                    <List.Icon name="right caret" />
                    <List.Content>{description}</List.Content>
                  </List.Item>
                ))}
              </List>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            {
              this.renderUpgrade(tierObj.name)
            }
          </Card.Content>
        </Card>
      ))
    );
  }

  render() {
    return (
      <>
        <Header as="h3" attached="top">
          Pricing
        </Header>

        <Segment attached>
          <Card.Group centered>
            {this.pricingTiers}
          </Card.Group>
        </Segment>
      </>
    );
  }
}

export default Pricing;
