import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Table, Button, Loader,
} from 'semantic-ui-react';

import TemplateRow from './TemplateRow';
import Modals from './Modals';

@inject('store')
@observer
class Templates extends Component {
  state = {
    activeName: '',
    targetName: '',
    modalOpen: false,
    modalType: null,
    emailDomainName: '',
  }

  componentDidUpdate() {
    const { activeEmailDomainName } = this.props.store.emailManagement;
    if (this.state.emailDomainName !== activeEmailDomainName) {
      this.handleEmailDomainChange(activeEmailDomainName);
    }
  }

  handleEmailDomainChange(activeEmailDomainName) {
    this.setState({ emailDomainName: activeEmailDomainName, activeName: '' });
  }

  get templateRows() {
    const { templates = [] } = this.props.store.emailManagement;
    return templates.map((template) => {
      const { name } = template;
      return (
        <TemplateRow
          template={template}
          key={name}
          onClick={this.handleRowClick}
          active={this.state.activeName === name}
          openModal={this.handleModalOpen}
        />
      );
    });
  }

  handleRowClick = (e, props) => {
    const { name } = props.template;
    const { activeName } = this.state;
    const newName = activeName === name ? null : name;

    this.setState({ activeName: newName });
  }

  handleModalOpen = (e, props) => {
    this.setState({
      modalOpen: true,
      modalType: props.type,
      targetName: props.name || '',
    });
  }

  handleModalClose = () => this.setState({
    activeName: '',
    targetName: '',
    modalOpen: false,
    modalType: null,
  });

  render() {
    return (
      <Fragment>
        <div className="sideScrollingTable">
          <Table striped padded unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={14}>Name</Table.HeaderCell>
                <Table.HeaderCell width={1}>Created</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.store.emailManagement.loading.getTemplates
                ? <Table.Row><Table.Cell><Loader active inline="centered" /></Table.Cell></Table.Row>
                : this.templateRows
              }
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Button
                    positive
                    labelPosition="left"
                    floated="left"
                    type="uploadTemplates"
                    onClick={this.handleModalOpen}
                    icon="cloud upload"
                    content="Upload Templates"
                  />
                  {/* <Button
                    positive
                    labelPosition="left"
                    floated="left"
                    type="addTemplate"
                    onClick={this.handleModalOpen}
                    icon="plus"
                    content="Add Template"
                  /> */}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>

        <Modals
          open={this.state.modalOpen}
          type={this.state.modalType}
          templateName={this.state.targetName}
          onClose={this.handleModalClose}
        />
      </Fragment>
    );
  }
}

export default Templates;
