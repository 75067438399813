/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  Grid, Button, Segment, Input,
  Header, Form, Radio,
} from 'semantic-ui-react';
import ConfirmationModal from './ConfirmationModal';
import './Settings.css';

@inject('store')
@observer
class Settings extends Component {
  constructor(props) {
    super(props);
    const { user = {} } = this.props.store.auth;
    const tempState = {
      email: user.email || '',
      phoneNumber: user.phone_number || '',
      twoFaType: user.twoFaType || '',
      twoFaCode: '',
      modalOpen: false,
      success: false,
    };

    if (tempState.phoneNumber.indexOf('+1') === 0) {
      tempState.phoneNumber = user.phone_number.substring(2);
    }

    this.state = tempState;
  }

  componentDidUpdate(prevProps) {
    this.updateUserProps(prevProps, this.props);
  }

  updateUserProps(prevProps, props = this.props) {
    if (!_.isEqual(prevProps.user, props.user)) {
      this.setState(_.pick(props.user, ['email', 'phoneNumber', 'twoFaType']));
    }
  }

  handleFormInput = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  handleModalOpen = () => this.setState({ modalOpen: true, success: false });

  handleModalClose = () => this.setState({ modalOpen: false, success: false });

  updateButtonHandler = () => {
    const { twoFaType, phoneNumber } = this.state;
    if (twoFaType === 'text'
      && (phoneNumber.trim().length !== 10
      || +phoneNumber.trim() <= 0)) {
      this.props.store.failure('Please format the phone number correctly.');
    } else {
      this.handleModalOpen();
    }
  }

  confirmButtonHandler = async () => {
    const res = await this.props.store.auth.editTwoFa(
      this.state.twoFaType.trim(),
      this.state.twoFaCode.trim(),
      `+1${this.state.phoneNumber}`.trim(),
    );

    this.setState({ success: res.success });
  }

  render() {
    const { twoFaType, phoneNumber } = this.state;

    const { user } = this.props.store.auth;
    const generateUserInfoGrid = [
      { name: 'Email', value: user.email, className: '' },
      { name: 'Status', value: user.status, className: 'capitalize' },
      { name: 'Role', value: user.role, className: 'capitalize' },
      { name: 'Plan Name', value: user.planName, className: 'capitalize' },
      { name: 'Plan Start Date', value: user.planStartDate },
    ].map(({ name, value, className }, index) => (
      <Grid.Row key={index}>
        <Grid.Column>
          {name}
        </Grid.Column>
        <Grid.Column className={className}>
          {value}
        </Grid.Column>
      </Grid.Row>
    ));

    return (
      <Fragment>
        <Header id="TabTitle" as="h2">
          Settings
        </Header>

        <Segment>
          <Grid columns={2}>
            {generateUserInfoGrid}
          </Grid>
        </Segment>

        <Header as="h3">
          Two Factor Authentication
        </Header>

        <Segment>
          <Form onSubmit={this.updateButtonHandler}>
            <Form.Group inline>
              <label>2FA Type</label>
              <Form.Field
                control={Radio}
                label="Text"
                name="twoFaType"
                value="text"
                checked={twoFaType === 'text'}
                onChange={this.handleFormInput}
              />
              <Form.Field
                control={Radio}
                label="Email"
                name="twoFaType"
                value="email"
                checked={twoFaType === 'email'}
                onChange={this.handleFormInput}
              />
              <Form.Field
                control={Radio}
                label="Disabled"
                name="twoFaType"
                value=""
                checked={twoFaType === ''}
                onChange={this.handleFormInput}
              />
            </Form.Group>
            <Form.Field>
              <label>Phone Number</label>
              <Input
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleFormInput}
                placeholder="1234567890"
                label="+1"
              />
            </Form.Field>
            <Button
              primary
              onClick={this.updateButtonHandler}
              disabled={
                (user.phone_number === `+1${phoneNumber}`
                && user.twoFaType === twoFaType)
                || ((twoFaType === 'text' || twoFaType === '') && !phoneNumber)
              }
            >
              Update
            </Button>
          </Form>
        </Segment>

        <ConfirmationModal
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          confirm={this.confirmButtonHandler}
          formInput={this.handleFormInput}
          original2FaType={user.twoFaType}
          {...this.state}
        />
      </Fragment>
    );
  }
}

export default Settings;
