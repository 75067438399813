import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Segment, Grid, Header, Image, Step, Button, Label,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import jessica from '../../../../assets/images/jessica.jpg';

@inject('store')
@observer
class SetupProgress extends Component {
  @computed
  get stepIndex() {
    const { domains } = this.props.store.auth.user;
    let stepIndex = 0;

    if (domains && domains.length > 0) {
      stepIndex = 1;
    }

    if (Object.keys(this.props.store.forms.formLinks).length) {
      stepIndex = 2;
    }

    return stepIndex;
  }

  steps = () => ([
    {
      key: 'script',
      // icon: 'code',
      title: 'Install Snippet',
      active: this.stepIndex === 0,
      completed: this.stepIndex > 0,
      onClick: () => { this.props.history.push('/setup'); },
    },
    {
      key: 'form',
      // icon: 'clipboard outline',
      title: 'Add a Form',
      active: this.stepIndex === 1,
      disabled: this.stepIndex === 0,
      completed: this.stepIndex > 1,
      onClick: () => { this.props.history.push('/forms'); },
    },
    {
      key: 'done',
      // icon: 'paper plane outline',
      title: 'Start Tracking',
      active: this.stepIndex === 2,
      disabled: this.stepIndex === 0 || this.stepIndex === 1,
      completed: this.stepIndex > 2,
    },
  ])

  render() {
    const { mobile } = this.props.store.ui;

    return (
      <div
        style={{ marginTop: '2.5rem', marginBottom: '3rem' }}
      >
        <Grid columns="equal" stackable>
          <Grid.Row>
            <Grid.Column>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ minWidth: '90px', minHeight: '90px' }}>
                  <Image
                    circular
                    size="tiny"
                    src={jessica}
                    style={{ width: 80, height: 80, border: 'solid 1px lightgrey' }}
                  />
                </div>

                <Header
                  as="div"
                  size="huge"
                  style={{ marginTop: 0, display: 'inline-block' }}
                  className="noEmphasisHeader"
                >
                  Hey, I'm Jessica. Need any help?
                  <Header.Subheader style={{ maxWidth: 386 }}>
                    Let's chat! It only takes a few short minutes to make sure your forms are all set to produce awesome results!
                  </Header.Subheader>
                </Header>
              </div>

              <Segment basic textAlign="center" style={{ marginTop: 0 }}>
                <Button
                  as="a"
                  href="https://meetings.hubspot.com/jessica-m/qa-session-with-a-team-member"
                  target="_blank"
                  icon="phone"
                  labelPosition="left"
                  content="Set Up a Call"
                  color="teal"
                />
                <Button
                  as="a"
                  href="mailto:sales@movology.com"
                  target="_blank"
                  icon="envelope outline"
                  labelPosition="left"
                  content="Email Us"
                  color="teal"
                  style={{ width: 156.27 }}
                />
              </Segment>

            </Grid.Column>
            <Grid.Column>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Segment compact raised>
                  {
                    !mobile ? (
                      <Label ribbon color="blue" size="huge">
                        { this.stepIndex === 2
                          ? 'We\'ll start tracking after we make sure everything\'s in order!'
                          : 'Complete these steps to start recovering lost leads!'
                        }
                      </Label>
                    ) : null
                  }
                  <Step.Group ordered fluid widths={this.steps().length} items={this.steps()} />
                </Segment>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(SetupProgress);
