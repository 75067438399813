/* eslint no-param-reassign: 0 */
/* eslint react/no-array-index-key: 0 */
import _ from 'lodash';
import set from 'lodash/fp/set';
import React, { Component, Fragment } from 'react';
import {
  Button, Segment, Header, Form, Grid, Divider, Dropdown, Input, Icon,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { arrayMove } from '../../../../helper';
import { colors } from '../../../../enums';
import './Individual.css';

const FormGroup = ({ fields, perRow = 2 }) => _.chunk(fields, perRow).map(chunk => (
  <Form.Group widths="equal" key={chunk.map(field => field.name).join('-')}>
    {chunk.map(props => (
      <div className="field" key={props.key}>
        <label>
          {props.labelprop}
          {' '}
          <span data-position={props.dataposition} data-tooltip={props.datatooltip}>
            <Icon disabled color="grey" name="info circle" />
          </span>
        </label>
        {
        props.key === 'text'
          ? <Form.TextArea {..._.pickBy(props, val => val !== undefined)} />
          : <Form.Input {..._.pickBy(props, val => val !== undefined)} />
        }
      </div>
    ))}
  </Form.Group>
));

const LogicRow = (props) => {
  const {
    logic, fields, emailNumber: index, color, onRemove,
  } = props;
  const { creative: templateName } = logic;
  const firstRowField = fields.filter(field => field.key !== 'text');
  const secondRowField = fields.filter(field => field.key === 'text');

  return (
    <Segment className="moveCursor" style={{ borderTop: `2px solid ${color}` }}>
      <Grid relaxed>
        <Grid.Column floated="left" width={12}>
          <Header>
            <strong>{`${index + 1}. ${templateName}`}</strong>
          </Header>
        </Grid.Column>
        <Grid.Column id="removeIconPlacement" floated="right" textAlign="right">
          <Button basic circular icon="trash alternate" index={index} onClick={onRemove} />
        </Grid.Column>
      </Grid>
      <Divider />
      <Form>
        <FormGroup fields={firstRowField} perRow={3} />
        <FormGroup fields={secondRowField} perRow={1} />
      </Form>
    </Segment>
  );
};

const SortableLogicRow = SortableElement(LogicRow);

const LogicContainer = (props) => {
  const {
    items, fields, emailColors, onRemove,
  } = props;
  return (
    <div>
      {items.map((logic, i) => {
        const { creative: templateName } = logic;
        return (
          <SortableLogicRow
            key={`${templateName}-${i}`}
            logic={logic}
            fields={fields[i]}
            index={i}
            emailNumber={i}
            color={emailColors[templateName]}
            onRemove={onRemove}
          />
        );
      })}
    </div>
  );
};

const SortableLogicContainer = SortableContainer(LogicContainer);

@inject('store')
@observer
class Individual extends Component {
  get formNode() {
    const node = _.get(this.props, ['store', 'forms', 'forms', this.url.hostname, 'paths', this.url.pathname], null);
    // convert all logic minuteDifference to hourDifference for recover
    const logic = _.get(node, 'emailLogic.logic', [])
      .map(({
        creative, minuteDifference, subject, text, hourDifference = minuteDifference / 60,
      }) => ({
        creative,
        hourDifference,
        subject,
        text,
      }));
    _.set(node, 'emailLogic.logic', logic);

    return node;
  }

  constructor(props) {
    super(props);
    const { selection } = props;
    this.url = new URL(selection.startsWith('https://') ? selection : `https://${selection}`);

    const { emailLogic = {} } = this.formNode;
    this.state = {
      settings: {
        domain: emailLogic.emailDomain || '',
        sender: emailLogic.emailSender || '',
      },
      logic: emailLogic.logic || [],
      dropdown: {
        search: '',
      },
    };
  }

  onInputChange = (e, { name, value }) => {
    // lodash fp funcs are immutable
    this.setState(prevState => set(name, value, prevState));
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ logic }) => ({
      logic: arrayMove(logic, oldIndex, newIndex),
    }));
  };

  get emailColors() {
    return this.props.store.emailManagement.templates.reduce((emailColors, template, i) => {
      const movColors = Object.values(colors);
      emailColors[template.name] = movColors[i % movColors.length];
      return emailColors;
    }, {});
  }

  get dropdownOptions() {
    return this.props.store.emailManagement.templates.map((template) => {
      const { name } = template;
      return {
        key: name,
        text: name,
        value: name,
        label: { empty: true, circular: true, style: { backgroundColor: this.emailColors[name] } },
      };
    });
  }

  get emailDropdownOptions() {
    return _.reduce(
      this.props.store.emailManagement.emailDomains,
      (options, domainSettings, emailDomain) => {
        if (domainSettings.status === 'active') {
          options.push({
            key: emailDomain,
            text: emailDomain,
            value: emailDomain,
          });
        }
        return options;
      },
      [],
    );
  }

  createEmailDomainsDropdown() {
    return (
      <Form.Select
        name="settings.domain"
        label="Email Domain"
        onChange={this.onInputChange}
        placeholder="Email Domain"
        options={this.emailDropdownOptions}
        value={this.state.settings.domain}
      />
    );
  }

  get emailSettingsFields() {
    return _.map(this.state.settings, (val, key) => ({
      key,
      name: `settings.${key}`,
      label: _.startCase(key),
      value: val,
      onChange: this.onInputChange,
    }));
  }

  get logicFields() {
    return this.state.logic
      .map((config, i) => _.map(
        config,
        (val, key) => {
          const fieldConfig = {
            key,
            name: `logic.[${i}].${key}`,
            labelprop: _.startCase(key),
            dataposition: 'right center',
            value: val,
            onChange: this.onInputChange,
          };
          switch (key) {
            case 'creative':
              fieldConfig.labelprop = 'Template Name';
              fieldConfig.datatooltip = 'Name of selected e-mail.';
              fieldConfig.readOnly = true;
              break;
            case 'minuteDifference':
              fieldConfig.key = 'hourDifference';
              fieldConfig.name = `logic.[${i}].hourDifference`;
              fieldConfig.labelprop = 'Hour Difference';
              fieldConfig.value = Number(val) / 60;
              fieldConfig.datatooltip = 'Hours until above e-mail is sent or abandoned (e.g. 24, 36.5).';
              fieldConfig.type = 'number';
              break;
            case 'hourDifference':
              fieldConfig.datatooltip = 'Hours until above e-mail is sent or abandoned (e.g. 24, 36.5).';
              fieldConfig.type = 'number';
              break;
            case 'subject':
              fieldConfig.datatooltip = 'Subject for e-mail template.';
              break;
            case 'text':
              fieldConfig.datatooltip = '(Optional) Body of e-mail if user is unable to display HTML.';
              break;
            default:
          }

          return fieldConfig;
        },
      ));
  }

  addEmail = (e, { value }) => {
    this.setState((prevState) => {
      const { logic } = prevState;
      return {
        logic: [...logic, {
          creative: value,
          hourDifference: 24,
          subject: '',
          text: '',
        }],
      };
    });
  }

  removeEmail = (e, { index }) => {
    this.setState((prevState) => {
      const { logic } = prevState;
      return {
        logic: [...logic.slice(0, index), ...logic.slice(index + 1, logic.length)],
      };
    });
  }

  saveEmailLogic = async () => {
    if (this.state.logic.some(logic => !logic.hourDifference || !logic.subject)) {
      this.props.store.failure('E-mails need a subject and hour difference!');
    } else {
      await this.props.store.emailManagement.saveEmailLogic({
        url: this.url,
        logic: this.state.logic.map((config) => {
          const configWithMinuteDiff = _.pick({
            ...config,
            minuteDifference: Number(config.hourDifference) * 60,
          }, ['creative', 'minuteDifference', 'subject', 'text']);

          return configWithMinuteDiff;
        }),
      });

      this.setState({
        logic: _.get(this.formNode, 'emailLogic.logic', []),
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Button.Group>
          <Button
            basic
            onClick={this.props.back}
            icon="angle left"
          />
        </Button.Group>
        {this.props.selection}
        <Header as="h3" attached="top">Email Settings</Header>
        <Segment attached>
          <Form>
            <Form.Group widths="equal">
              {this.createEmailDomainsDropdown()}
              <Form.Input
                name="settings.sender"
                placeholder="Noreply noreply@"
                label="Sender"
                value={this.state.settings.sender}
                onChange={this.onInputChange}
                data-tooltip="Format: Noreply noreply@ (<name of sender> <address name>@)"
              />
            </Form.Group>
          </Form>
        </Segment>
        <Segment attached="bottom">
          <Button
            positive
            icon="save"
            content="Save Settings"
            labelPosition="left"
            loading={this.props.store.emailManagement.loading.saveEmailSettings}
            disabled={this.props.store.emailManagement.loading.saveEmailSettings}
            onClick={async () => {
              await this.props.store.emailManagement.saveEmailSettings({
                url: this.url,
                domain: this.state.settings.domain,
                sender: this.state.settings.sender,
              });
              const { emailLogic } = this.formNode;
              this.setState({
                settings: {
                  domain: emailLogic.emailDomain || '',
                  sender: emailLogic.emailSender || '',
                },
              });
            }}
          />
        </Segment>

        <Header as="h3" attached="top">
          Recover E-mails
          {' '}
          <span data-position="right center" data-tooltip="Order of e-mails can be rearranged by dragging and dropping.">
            <Icon disabled color="grey" name="info circle" size="small" />
          </span>
        </Header>

        <Segment attached>
          {
          this.state.logic.length
            ? (
              <SortableLogicContainer
                items={this.state.logic}
                fields={this.logicFields}
                onSortEnd={this.onSortEnd}
                emailColors={this.emailColors}
                onRemove={this.removeEmail}
                distance={2}
                className="moveCursorHelper"
              />
            )
            : 'No e-mails are currently configured.'
        }
        </Segment>
        <Segment attached="bottom">
          <Button
            positive
            icon="save"
            content="Save"
            labelPosition="left"
            loading={this.props.store.emailManagement.loading.saveEmailLogic}
            disabled={this.props.store.emailManagement.loading.saveEmailLogic}
            onClick={this.saveEmailLogic}
          />
          <Dropdown
            icon="add"
            text="Add E-mail"
            className="icon"
            floating
            labeled
            button
            style={{ backgroundColor: colors.teal, color: '#fff', zIndex: 9999 }}
          >
            <Dropdown.Menu>
              <Input
                icon="search"
                iconPosition="left"
                className="search"
                name="dropdown.search"
                onClick={e => e.stopPropagation()}
                value={this.state.dropdown.search}
                onChange={this.onInputChange}
              />
              <Dropdown.Divider />
              <Dropdown.Header icon="mail" content="E-mails" />
              <Dropdown.Menu scrolling>
                {
                  this.dropdownOptions
                    .filter(option => option.value.includes(this.state.dropdown.search))
                    .map(option => (<Dropdown.Item {...option} onClick={this.addEmail} />))
                }
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        </Segment>
      </Fragment>
    );
  }
}

export default Individual;
