import OMI366 from './OMI366';
import HubSpot from './HubSpot';
import Salesforce from './Salesforce';
import WiX from './WiX';
import GoogleAdwords from './GoogleAdwords';
import GoogleTagManager from './GoogleTagManager';
import Shopify from './Shopify';
import Mailchimp from './Mailchimp';
import SalesforceMarketingCloud from './SalesforceMarketingCloud';
import Typeform from './Typeform';
import Pipedrive from './Pipedrive';
import Blitz from './Blitz';
import Fanbridge from './Fanbridge';

class Integrations {
  constructor(rootStore) {
    this.rootStore = rootStore;

    this.OMI366 = new OMI366(rootStore);
    this.HubSpot = new HubSpot(rootStore);
    this.Salesforce = new Salesforce(rootStore);
    this.WiX = new WiX(rootStore);
    this.GoogleAdwords = new GoogleAdwords(rootStore);
    this.GoogleTagManager = new GoogleTagManager(rootStore);
    this.Shopify = new Shopify(rootStore);
    this.Mailchimp = new Mailchimp(rootStore);
    this.SalesforceMarketingCloud = new SalesforceMarketingCloud(rootStore);
    this.Typeform = new Typeform(rootStore);
    this.Pipedrive = new Pipedrive(rootStore);
    this.Blitz = new Blitz(rootStore);
    this.Fanbridge = new Fanbridge(rootStore);
  }

  reset() {
    this.OMI366.reset();
    this.HubSpot.reset();
    this.Salesforce.reset();
    this.WiX.reset();
    this.GoogleAdwords.reset();
    this.GoogleTagManager.reset();
    this.Shopify.reset();
    this.Mailchimp.reset();
    this.SalesforceMarketingCloud.reset();
    this.Typeform.reset();
    this.Blitz.reset();
    this.Pipedrive.reset();
    this.Fanbridge.reset();
  }
}

export default Integrations;
