/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import {
  Table, Button, Label, Pagination, Icon,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { computed, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { colors, TABLE_ITEMS_PER_PAGE } from '../../../../enums';

@inject('store')
@observer
class SummaryOverview extends Component {
  state = {
    activePage: 1,
  }

  headerMap = {
    page_view: 'Page Views',
    sessions: 'Unique Visitors',
    forms_started: 'Forms Started',
    unique_valid_abandoned_emails: 'Abandons w/ Email',
    emails_delivered: 'Emails Delivered',
    unique_email_opens: 'Unique Email Opens',
    recaptures: 'Recaptures',
    submits: 'Submits',
  }

  handlePaginationChange = (e, { activePage }) => this.setState({ activePage })

  get headerRow() {
    return ['Form Url', ...this.statKeys.map(statKey => this.headerMap[statKey] || statKey)];
  }

  get statKeys() {
    const stKeys = ['forms_started', 'unique_valid_abandoned_emails', 'submits'];
    const gaKeys = ['page_view', 'sessions'];
    const remarketKeys = ['emails_delivered', 'unique_email_opens', 'recaptures'];
    const { auth } = this.props.store;
    if (auth.user.analytics) {
      stKeys.push(...gaKeys);
    }
    if (auth.paidPlan) {
      stKeys.push(...remarketKeys);
    }
    return stKeys;
  }

  @computed
  get summaryData() {
    const { column, direction } = this.sortState;
    const {
      filters, createSummaryRows,
    } = this.props.store.analytics;

    const summaryDataLocalStorage = localStorage.getItem('summaryData');
    const summaryDataCachedDate = localStorage.getItem('summaryDataCachedDate');
    const summaryDataTimeSpan = Number(localStorage.getItem('summaryDataTimeSpan'));
    const summaryDataDomainFilter = localStorage.getItem('summaryDataDomainFilter') === 'null' ? null : localStorage.getItem('summaryDataDomainFilter');
    const summaryDataPathFilter = localStorage.getItem('summaryDataPathFilter') === 'null' ? null : localStorage.getItem('summaryDataPathFilter');
    const currentDate = moment().format('l');

    if (!summaryDataLocalStorage
      || JSON.parse(summaryDataLocalStorage).length === 0
      || summaryDataCachedDate !== currentDate
      || summaryDataTimeSpan !== filters.timeSpanInMonths
      || summaryDataDomainFilter !== filters.domain
      || summaryDataPathFilter !== filters.path) {
      /** Store dashboard cache meta */
      localStorage.setItem('summaryDataCachedDate', currentDate);
      localStorage.setItem('summaryDataTimeSpan', filters.timeSpanInMonths);
      localStorage.setItem('summaryDataDomainFilter', filters.domain);
      localStorage.setItem('summaryDataPathFilter', filters.path);

      const summaryData = createSummaryRows(this.statKeys)
        .sort((a, b) => {
          const colIndex = this.headerRow.indexOf(column);
          let comp = 0;

          if (!Number.isNaN(Number(a[colIndex]))) {
            comp = Number(a[colIndex]) - Number(b[colIndex]);
          } else if (a[colIndex] > b[colIndex]) {
            comp = 1;
          } else if (a[colIndex] < b[colIndex]) {
            comp = -1;
          }

          if (direction === 'ascending') {
            return comp;
          } else if (direction === 'descending') {
            return -comp;
          } else {
            return 0;
          }
        });

      localStorage.setItem('summaryData', JSON.stringify(summaryData));
    } else {
      const sortedSummaryData = JSON.parse(summaryDataLocalStorage).sort((a, b) => {
        const colIndex = this.headerRow.indexOf(column);
        let comp = 0;

        if (!Number.isNaN(Number(a[colIndex]))) {
          comp = Number(a[colIndex]) - Number(b[colIndex]);
        } else if (a[colIndex] > b[colIndex]) {
          comp = 1;
        } else if (a[colIndex] < b[colIndex]) {
          comp = -1;
        }

        if (direction === 'ascending') {
          return comp;
        } else if (direction === 'descending') {
          return -comp;
        } else {
          return 0;
        }
      });
      localStorage.setItem('summaryData', JSON.stringify(sortedSummaryData));
    }
    return JSON.parse(localStorage.getItem('summaryData'));
  }

  @observable
  sortState = {
    column: 'Forms Started',
    direction: 'descending',
  }

  @action
  handleSort = (clickedColumn) => {
    if (this.sortState.column !== clickedColumn) {
      this.sortState = {
        column: clickedColumn,
        direction: 'descending',
      };
    } else {
      this.sortState.direction = this.sortState.direction === 'ascending' ? 'descending' : 'ascending';
    }
  }

  noRowsRibbon = () => (
    <Table.Row>
      <Table.Cell>
        <Label ribbon color="green" size="large">
        Looks like you have no data yet. If you haven&lsquo;t added any forms, click&nbsp;
          <span
            style={{ color: 'Khaki' }}
            className="linkStyling"
            onClick={() => { this.props.history.push('/forms'); }}
          >
              here
          </span>
        &nbsp;to start adding forms.
        </Label>
      </Table.Cell>
    </Table.Row>
  )

  buildRows = () => (this.summaryData
    .slice(
      0 + (this.state.activePage - 1) * TABLE_ITEMS_PER_PAGE,
      0 + (this.state.activePage * TABLE_ITEMS_PER_PAGE),
    )
    .map((row, key2) => (
      <Table.Row key={key2}>
        {
          row.map((col, key3) => (
            <Table.Cell key={key3} {...key3 === 6 ? { style: { color: colors.primary } } : {}}>
              { key3 === 0
                ? <a href={`https://${col}`} target="_blank" rel="noopener noreferrer">{`https://${col}`}</a>
                : col.toLocaleString()}
            </Table.Cell>
          ))
        }
      </Table.Row>
    ))
  )

  render() {
    const rows = this.summaryData.length === 0 ? this.noRowsRibbon() : this.buildRows();

    return (
      <div style={{ marginTop: '1rem', width: '100%' }} className="sideScrollingTable">
        <Table
          sortable
          striped
          celled
          unstackable
        >
          <Table.Header>
            <Table.Row>
              {
                this.headerRow.map(headerItem => (
                  <Table.HeaderCell
                    key={headerItem}
                    sorted={this.sortState.column === headerItem ? this.sortState.direction : null}
                    onClick={() => { this.handleSort(headerItem); }}
                  >
                    {headerItem}
                  </Table.HeaderCell>
                ))
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { rows }
          </Table.Body>
          <Table.Footer>
            {
              Math.ceil(this.summaryData.length / TABLE_ITEMS_PER_PAGE) > 1
                ? (
                  <Table.Row>
                    <Table.HeaderCell colSpan="7" className="posRelative">
                      <Pagination
                        activePage={this.state.activePage}
                        onPageChange={this.handlePaginationChange}
                        totalPages={Math.ceil(this.summaryData.length / TABLE_ITEMS_PER_PAGE)}
                        style={{ position: 'relative', left: '50%', transform: 'translateX(-50%)' }}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                ) : null
            }
            <Table.Row>
              <Table.HeaderCell colSpan="7">
                <Button
                  primary
                  icon
                  labelPosition="left"
                  disabled={this.summaryData.length === 0}
                  as={CSVLink}
                  filename="summarized_recapture_data.csv"
                  data={[
                    this.headerRow,
                    ...this.summaryData,
                  ]}
                >
                  <Icon name="download" />
                  Download Summary as CSV
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

export default withRouter(SummaryOverview);
