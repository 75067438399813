/* eslint-disable no-param-reassign */
import _ from 'lodash';
import axios from 'axios';
import { observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import { API_URL } from '../configs';
import { toastTypes } from '../enums';

class Forms {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @persist('object')
  @observable
  forms = {};

  @observable
  formLinks = {};

  @observable
  currentUrl;

  @observable
  urlsInProgress = [];

  @observable
  loading = false;

  @action
  reset = () => {
    this.formLinks = {};
    this.currentUrl = undefined;
    this.loading = false;
  }

  @action
  getFormLinks = async () => {
    this.loading = true;

    const res = await axios.get(`${API_URL}/api/forms/get-all-forms`);
    if (res.data.success && res.data.data) {
      this.forms = res.data.data;
      const mergedUrl = {};
      const domains = Object.keys(res.data.data);

      for (let i = 0; i < domains.length; i += 1) {
        const pathNodes = Object.values(res.data.data)[i].paths || {};
        const paths = Object.keys(pathNodes);

        for (let j = 0; j < paths.length; j += 1) {
          mergedUrl[`${domains[i]}${paths[j]}`] = res.data.data[domains[i]].paths[paths[j]];

          // updating minuteDifference to hourDifference for recover
          const emailLogicConfig = _.get(mergedUrl, `${domains[i]}${paths[j]}.emailLogic.logic`);
          if (emailLogicConfig) {
            for (let k = 0; k < emailLogicConfig.length; k += 1) {
              const { minuteDifference } = emailLogicConfig[k];
              const hourDifferenceValue = minuteDifference / 60;

              emailLogicConfig[k] = _.omit(emailLogicConfig[k], ['minuteDifference']);

              emailLogicConfig[k].hourDifference = hourDifferenceValue;
            }
          }

          // OMI366 drip campaign check
          const campaignIds = _.get(res, ['data', 'data', domains[i], 'paths', paths[j], 'integrations', 'OMI366', 'dripCampaigns'], []);
          _.merge(this.rootStore.integrations.OMI366.assignedDripCampaigns, {
            [domains[i]]: {
              [paths[j]]: {
                campaignIds,
              },
            },
          });
        }
      }
      this.formLinks = mergedUrl;
    } else {
      this.formLinks = [];
    }

    this.loading = false;
    return this.formLinks;
  }

  @action
  removeFormLink = async (url) => {
    try {
      this.loading = true;
      await axios.delete(`${API_URL}/api/forms/edit-form`, {
        data: { url: `https://${url}` },
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.getFormLinks();
      this.rootStore.success('Form removed successfully.');
      this.loading = false;
    }
  }

  @action
  addFormLink = async (url) => {
    try {
      this.loading = true;

      this.urlsInProgress.push(url);

      const res1 = await axios.post(`${API_URL}/api/forms/edit-form`, { url });

      if (!res1.data.success) {
        throw new Error({ msg: 'failed to create entry' });
      }

      const res2 = await axios.post(`${API_URL}/api/analytics/create`, { urls: [url] });

      if (!res2.data.success) {
        throw new Error({ msg: 'failed to create entry' });
      }

      await this.verifySnippetInstall(url);
      this.rootStore.success('Your form has been added.', toastTypes.addFormLink);
      this.rootStore.clearToast(toastTypes.addFormLink);
    } catch (err) {
      console.error(err);
      this.rootStore.failure('Your form was not added.', toastTypes.addFormLink);
    } finally {
      this.urlsInProgress = this.urlsInProgress.filter(cur => cur !== url);
      this.loading = false;
    }
  }

  @action
  verifySnippetInstall = async (url) => {
    this.currentUrl = url;
    this.loading = true;
    try {
      if (url.indexOf('https://') === 0) {
        url = url.substring(8);
      }
      await axios.post(`${API_URL}/api/um/verify-snippet-install`, { url: [url] });
      await this.getFormLinks();
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
      this.currentUrl = null;
    }
  }

  @action
  updateForm = ({ url, diff }) => {
    try {
      const { hostname, pathname } = url;
      const currentForm = _.get(this.forms, [hostname, 'paths', pathname]);
      _.set(this.forms, [hostname, 'paths', pathname], { ...currentForm, ...diff });
    } catch (err) {
      this.rootStore.failure('Failed to update forms locally. Please refresh.');
    }
  }
}

export default Forms;
