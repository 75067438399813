import React, { Fragment } from 'react';
import {
  Segment, Grid, Statistic, Icon,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { keygen } from '../../../../helper';
import { colors } from '../../../../enums';
import './Statistics.css';

const Statistics = (props) => {
  // Generates the statistics + chart data based on form url selected. Different reduce logics
  // are required in order to account for the summary statistics instead of individual form urls.
  const { statSum } = props.store.analytics;
  const abandonsSum = statSum('abandons');
  const formsStartedSum = statSum('forms_started');
  const uniqueValidAbandonedEmailSum = statSum('unique_valid_abandoned_emails');
  const { currentSessions = 0 } = props.store.realtime.data;

  return (
    <Fragment>
      <Grid.Row>
        {/* People have abandoned this form dashboard component */}
        <Grid.Column>
          <Segment
            raised
            textAlign="center"
            style={{
              backgroundColor: colors.primary,
              border: 'none',
              padding: '3rem',
            }}
          >
            <Statistic>
              <Statistic.Value style={{ color: 'white' }}>
                {!props.store.analytics.percentView
                  ? abandonsSum.toLocaleString()
                  : `${(Math.round((abandonsSum / formsStartedSum) * 100)) || 0}%`}
              </Statistic.Value>
              <Statistic.Label style={{ color: 'white' }}>
                {
                  !props.store.analytics.percentView
                    ? abandonsSum === 1
                      ? 'person has abandoned the forms on your website.'
                      : 'people have abandoned the forms on your website.'
                    : 'of people have abandoned the forms on your website.'
                }
              </Statistic.Label>
            </Statistic>
          </Segment>
        </Grid.Column>

        {/* Real-time session component */}
        <Grid.Column>
          <Segment
            raised
            textAlign="center"
            style={{
              backgroundColor: colors.teal,
              border: 'none',
              padding: '3rem',
            }}
          >
            <Statistic>
              <Statistic.Value style={{ color: 'white' }}>
                {currentSessions}
              </Statistic.Value>
              <Statistic.Label style={{ color: 'white' }}>
                {
                  currentSessions === 1
                    ? 'person is currently completing a form'
                    : 'people are currently completing a form'
                }
              </Statistic.Label>
            </Statistic>
          </Segment>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        {[
          {
            label: 'Forms Started',
            value: formsStartedSum,
            style: { borderTop: `4px solid ${colors.purple}` },
            tooltip: 'Form started is counted when a visitor clicks into your form.',
            position: 'top left',
          },
          {
            label: 'Forms Completed',
            value: !props.store.analytics.percentView
              ? formsStartedSum - abandonsSum
              : `${(Math.round(((formsStartedSum - abandonsSum) / formsStartedSum) * 100)) || 0}%`,
            style: { borderTop: `4px solid ${colors.teal}` },
            tooltip: 'Forms completed is measured when a client clicks the submit button.',
            position: 'top center',
          },
          {
            label: 'Abandons with Email',
            value: !props.store.analytics.percentView
              ? uniqueValidAbandonedEmailSum
              : `${(Math.round(((uniqueValidAbandonedEmailSum) / formsStartedSum) * 100)) || 0}%`,
            style: { borderTop: `4px solid ${colors.red}` },
            tooltip: 'Abandons with emails is counted when a validly formatted email is input but the form is not submitted.',
            position: 'top center',
          },
          {
            label: 'Abandons without Email',
            value: !props.store.analytics.percentView
              ? abandonsSum - uniqueValidAbandonedEmailSum
              : `${(Math.round(((abandonsSum - uniqueValidAbandonedEmailSum) / formsStartedSum) * 100)) || 0}%`,
            style: { borderTop: `4px solid ${colors.orange}` },
            tooltip: 'Abandons without emails is counted when a customer abandons without inputting a complete email.',
            position: 'top right',
          },
        ].map(({
          label, value, style, tooltip, position,
        }) => (
          <Grid.Column key={keygen()} textAlign="center">
            <Segment raised style={style}>
              <span style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} data-variation="wide" data-position={position} data-tooltip={tooltip}>
                <Icon disabled color="grey" name="info circle" />
              </span>
              <Statistic size="tiny" style={{ margin: 0 }}>
                <Statistic.Value>
                  {value.toLocaleString()}
                </Statistic.Value>
                <Statistic.Label>
                  {label}
                </Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Fragment>
  );
};

export default inject('store')(observer(Statistics));
