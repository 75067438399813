/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Button } from 'semantic-ui-react';

@inject('store')
@observer
class TypeformSearch extends Component {
  typeform = this.props.store.integrations.Typeform

  handleSearchChange = (e, { value }) => this.typeform.updateSearch(value);
  handleSearch = () => this.typeform.handleSearch();
  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.typeform.handleSearch();
    }
  };

  render() {
    const { loading } = this.typeform;
    return (
      <Input placeholder="Search forms by name..." action type="text" onChange={this.handleSearchChange} onKeyDown={this.handleEnter}>
        <input />
        <Button icon="search" onClick={this.handleSearch} color="green" loading={loading.retrieveForms} />
      </Input>
    );
  }
}

export default TypeformSearch;
