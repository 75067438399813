import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import SideBanner from './SideBanner';
import LoginForm from './LoginForm';
import './LoginPage.css';

@inject('store')
@observer
class LoginPage extends Component {
  render() {
    const viewCondition = this.props.store.auth.loStatus === 'logged_in' ? (
      <Dimmer active inverted>
        <Loader className="loaderWorkaround" size="massive" />
      </Dimmer>
    )
      : (
        <React.Fragment>
          <SideBanner />
          <LoginForm />
        </React.Fragment>
      );

    return (
      <div id="LoginPage">
        {viewCondition}
      </div>
    );
  }
}

export default LoginPage;
