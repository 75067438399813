import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class Blitz {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    apiId: '',
    transactionId: '',
    apiUrl: '',
    enabled: false,
  };

  @observable
  loading = false;

  @action
  reset = () => {
    this.configs = {
      apiId: '',
      transactionId: '',
      apiUrl: '',
      enabled: false,
    };
    this.loading = false;
  }

  @action
  getConfigs = async () => {
    try {
      this.loading = true;

      const { data } = await axios.get(`${API_URL}/api/integrations/Blitz/config`);
      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (e) {
      // this.rootStore.failure('Could not find integration configs');
    } finally {
      this.loading = false;
    }
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading = true;
      const {
        apiId, transactionId, apiUrl, enabled,
      } = this.configs;

      if (apiId.length !== 25) {
        throw new Error('Invalid API ID. Must be 25 characters long');
      }

      if (transactionId.length !== 25) {
        throw new Error('Invalid Transaction ID. Must be 25 characters long');
      }

      if ((new URL(apiUrl)).hostname !== 'www.blitzleadmanager.com') {
        throw new Error('Invalid API Url. Must be valid url e.g. https://www.blitzleadmanager.com/companya/ws/ws.asmx');
      }

      const { data } = await axios.post(`${API_URL}/api/integrations/Blitz/config`, {
        apiId,
        transactionId,
        apiUrl,
        enabled,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.blitzUpdateConfigs);
        this.rootStore.clearToast(toastTypes.blitzUpdateConfigs);
      } else {
        throw new Error('Failed up update blitz config');
      }
    } catch (e) {
      this.rootStore.failure(e.message || 'Something went wrong...', toastTypes.blitzUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading = false;
    }
  }
}

export default Blitz;
