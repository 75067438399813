import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';


class SalesforceMarketingCloud {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    enabled: false,
  };

  @observable
  loading = false;

  @action
  reset = () => {
    this.configs = {};
    this.loading = false;
  }

  getConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/SalesforceMarketingCloud/config`);
      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  reset = () => {
    this.configs = {};
    this.loading = false;
  }

  updateConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/SalesforceMarketingCloud/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.salesForceMarketingCloudUpdateConfigs);
        this.rootStore.clearToast(toastTypes.salesForceMarketingCloudUpdateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.salesForceMarketingCloudUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading = false;
    }
  }

  getAuthUrl = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/SalesforceMarketingCloud/auth`);
      if (res.data.success) {
        window.open(res.data.authUrl, '_blank');
        return res.data.authUrl;
      }
    } catch (e) {
      console.error(e);
    }
  }

  verifyCode = async (code, tssd) => {
    this.loading = true;
    try {
      this.rootStore.success('Linking your Salesforce Marketing Cloud Account.', toastTypes.salesForceMarketingCloudVerifyCode);
      const { data: linkResponse } = await axios({
        method: 'POST',
        url: `${API_URL}/api/integrations/SalesforceMarketingCloud/link`,
        data: { code, tssd },
      });

      if (linkResponse.success) {
        this.getConfigs();
        this.rootStore.success('Your Salesforce Marketing Cloud Account has been linked.', toastTypes.salesForceMarketingCloudVerifyCode);
        this.rootStore.clearToast(toastTypes.salesForceMarketingCloudVerifyCode);
      } else {
        this.rootStore.failure('Unable to link your account. Please contact support.', toastTypes.salesForceMarketingCloudVerifyCode);
      }
    } catch (err) {
      this.rootStore.failure('Unable to link your account. Please contact support.', toastTypes.salesForceMarketingCloudVerifyCode);
      console.log(err);
    }
    this.loading = false;
  }
}

export default SalesforceMarketingCloud;
