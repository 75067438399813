import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import {
  Header, Segment, List, Form, Grid,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { capitalize } from 'lodash';

function paymentPanel() {
  return (
    <>
      <Header as="h3" attached="top">
        Payment
      </Header>

      <Segment attached>
        <List ordered>
          <List.Item>
            To upgrade or downgrade your plan, click the Integration Pricing tab located above.
          </List.Item>
        </List>
      </Segment>
    </>
  );
}

function setupPanel() {
  return (
    <>
      <Header as="h3" attached="top">
        Setup
      </Header>

      <Segment attached>
        <List ordered>
          <List.Item>
            Make a Shopify account
            {' '}
            <a href="https://www.shopify.com/" target="_blank" rel="noopener noreferrer">here</a>
            {' '}
            if you don’t have one already.
          </List.Item>

          <List.Item>
            Head over to
            {' '}
            <a href="https://apps.shopify.com/" target="_blank" rel="noopener noreferrer">https://apps.shopify.com/</a>
            .
          </List.Item>

          <List.Item>
            Search for: Capture by Movology.
          </List.Item>

          <List.Item>
            Click Add App.
          </List.Item>

          <List.Item>
            Authorize by clicking the Install app button.
          </List.Item>
        </List>
      </Segment>
    </>
  );
}

@inject('store')
@observer
class Setup extends Component {
  @action
  enableToggle = () => {
    const flipped = !this.props.store.integrations.Shopify.configs.enabled;
    this.props.store.integrations.Shopify.configs.enabled = flipped;
  }

  get statusPanel() {
    const { storeDetails, integrationPricingTier } = this.props.store.integrations.Shopify.configs;
    if (!storeDetails) {
      return null;
    }
    const { domain, email, name } = storeDetails;

    const statusConfigs = [
      {
        name: 'Integration Pricing Tier',
        value: capitalize(integrationPricingTier),
      },
      {
        name: 'Store Name',
        value: name,
      },
      {
        name: 'Email',
        value: email,
      },
      {
        name: 'Domain',
        value: domain,
      },
    ];

    return (
      <>
        <Header as="h3" attached="top">
          Status
        </Header>
        <Segment attached>
          <Grid columns={2}>
            {statusConfigs.map(config => (
              <Grid.Row key={config.name}>
                <Grid.Column>
                  {config.name}
                </Grid.Column>
                <Grid.Column>
                  {config.value}
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Segment>
      </>
    );
  }

  get managePanel() {
    return (
      <>
        <Header as="h3" attached="top">
          Manage Integration
        </Header>

        <Segment attached>
          <Form>
            <Form.Checkbox
              label="Enable"
              onChange={this.enableToggle}
              checked={this.props.store.integrations.Shopify.configs.enabled}
            />

            <Form.Button
              positive
              disabled={this.props.store.integrations.Shopify.loading}
              loading={this.props.store.integrations.Shopify.loading}
              onClick={() => this.props.store.integrations.Shopify.updateConfigs('Shopify')}
            >
              Update
            </Form.Button>
          </Form>
        </Segment>
      </>
    );
  }

  render() {
    return (
      <>
        {this.props.store.integrations.Shopify.configs.storeDetails ? null : setupPanel()}

        {this.props.store.integrations.Shopify.configs.storeDetails ? paymentPanel() : null}

        {this.props.store.integrations.Shopify.configs.storeDetails ? this.statusPanel : null}

        {this.props.store.integrations.Shopify.configs.storeDetails ? this.managePanel : null}
      </>
    );
  }
}

export default withRouter(Setup);
