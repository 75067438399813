/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import {
  Card, Button, Image, Icon, Input, Label,
} from 'semantic-ui-react';

@inject('store')
@observer
class TypeformCards extends Component {
  Typeform = this.props.store.integrations.Typeform
  render() {
    return (
      <Card.Group>
        {this.Typeform.formData.forms.map(form => (
          <Card key={form.id} raised color={form.approved ? 'teal' : 'grey'}>
            <Card.Content textAlign="center">
              <Card.Header>{form.title}</Card.Header>
              <Card.Meta>
                <a
                  href={form._links.display}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {form._links.display}
                </a>
              </Card.Meta>
              <Card.Description>
                <Input
                  fluid
                  label={(
                    <Label>
                      <Image
                        src="https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2Fgtm_icon.svg?alt=media&token=26149fff-16ba-4622-b3c2-ec127540006c"
                        size="mini"
                        avatar
                      />
                    </Label>
                      )}
                  labelPosition="left"
                  placeholder="Container ID"
                  key={form.id}
                  name={form.id}
                  value={form.settings.google_tag_manager || ''}
                  onChange={this.Typeform.updateFormContainerId}
                />
              </Card.Description>
            </Card.Content>
            <Card.Content textAlign="center">
              {form.updatedTs
                ? (
                  <>
                    <Icon name="linkify" />
                          Form Linked:
                    {' '}
                    {moment(form.updatedTs).format('MM/DD/YYYY')}
                  </>
                )
                : (
                  <>
                    <Icon name="unlink" />
                          Form Not Linked
                  </>
                )}
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Button
                color="teal"
                disabled={(/^GTM-[A-Z0-9]{1,7}$/i).test(form.settings.google_tag_manager) === false}
                loading={this.Typeform.formsLoading.has(form.id)}
                onClick={() => this.Typeform
                  .approveForm(form.id, form.settings.google_tag_manager, form._links.display)}
              >
                    Approve
              </Button>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    );
  }
}

export default TypeformCards;
