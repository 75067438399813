import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Menu, Icon } from 'semantic-ui-react';
import qs from 'query-string';
import configs from '../configs';

import Setup from './Setup';

@inject('store')
@observer
class Fanbridge extends Component {
  state = {
    activeTab: 'Setup',
  }

  componentDidMount() {
    this.props.store.integrations.Fanbridge.getConfigs();
    const { code } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (code) {
      this.props.store.integrations.Fanbridge.getTokens(code);
    }
  }

  handleMenuClick = (e, { name }) => {
    this.setState({ activeTab: name });
  }

  displayMenuPanels = () => {
    switch (this.state.activeTab) {
      case 'Setup':
        return <Setup />;
      default:
        return <div>Something went wrong...</div>;
    }
  }

  render() {
    return (
      <>
        <Menu>
          <Menu.Item onClick={() => this.props.history.push('/integrations')}>
            <Icon name="arrow left" />
            <Header as="h3" content={configs.Fanbridge.displayName} style={{ marginTop: 0 }} />
          </Menu.Item>

          <Menu.Item
            name="Setup"
            content="Setup"
            onClick={this.handleMenuClick}
            active={this.state.activeTab === 'Setup'}
          />
        </Menu>

        {this.displayMenuPanels()}
      </>
    );
  }
}

export default Fanbridge;
