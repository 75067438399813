import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import {
  Header, Segment, List, Form,
} from 'semantic-ui-react';

@inject('store')
@observer
class Setup extends Component {
  blitzStore = this.props.store.integrations.Blitz
  @action
  handleChange = (e, { name, value }) => {
    this.blitzStore.configs[name] = value;
  }

  @action
  enableToggle = () => {
    this.blitzStore.configs.enabled = !this.blitzStore.configs.enabled;
  }

  render() {
    return (
      <>
        <Header as="h3" attached="top">
          Setup
        </Header>
        <Segment attached>
          <List ordered>
            <List.Item>
              Make an account with Blitz
              {' '}
              <a
                href="https://www.blitzleadmanager.com/login/Registration.aspx?partner=Movology"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              {' '}
              if you have not done so already.
            </List.Item>
            <List.Item>
              Navigate to the &quot;My Profile&quot; screen within Blitz Lead Manager.
            </List.Item>
            <List.Item>
              Copy and paste the API URL, API ID, and Transaction ID below then click update.
            </List.Item>
            <List.Item>
              Your future captures will be added as leads daily within Blitz Lead Manager.
            </List.Item>
          </List>
        </Segment>

        <Header as="h3" attached="top">
          Manage Integration
        </Header>

        <Segment attached>
          <Form>
            <Form.Input
              fluid
              label="API URL"
              name="apiUrl"
              disabled={this.blitzStore.loading}
              value={this.blitzStore.configs.apiUrl}
              onChange={this.handleChange}
              placeholder="https://www.blitzleadmanager.com/companya/ws/ws.asmx"
            />
            <Form.Input
              fluid
              label="API ID"
              name="apiId"
              disabled={this.blitzStore.loading}
              value={this.blitzStore.configs.apiId}
              onChange={this.handleChange}
            />
            <Form.Input
              fluid
              label="Transaction ID"
              name="transactionId"
              disabled={this.blitzStore.loading}
              value={this.blitzStore.configs.transactionId}
              onChange={this.handleChange}
            />

            <Form.Checkbox
              label="Enable"
              onChange={this.enableToggle}
              checked={this.blitzStore.configs.enabled}
            />

            <Form.Button
              positive
              disabled={this.blitzStore.loading}
              loading={this.blitzStore.loading}
              onClick={() => this.blitzStore.updateConfigs('OMI366')}
            >
              Update
            </Form.Button>
          </Form>
        </Segment>
      </>
    );
  }
}

export default Setup;
