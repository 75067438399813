/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { Component } from 'react';
import {
  Header, Segment, Table, Label, Modal, Button, Divider, Icon,
} from 'semantic-ui-react';
import _ from 'lodash';
import './index.css';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class DripCampaigns extends Component {
  state = {
    modalOpen: false,
    formDomainToBeUpdated: null,
    formPathToBeUpdated: null,
    newCampaignArray: [],
  }

  openModal = (formDomainToBeUpdated, formPathToBeUpdated) => this.setState({
    formDomainToBeUpdated,
    formPathToBeUpdated,
    modalOpen: true,
    newCampaignArray: this.props.store.integrations.OMI366.assignedDripCampaigns[formDomainToBeUpdated][formPathToBeUpdated].campaignIds,
  })

  closeModal = () => this.setState({
    modalOpen: false, formDomainToBeUpdated: null, formPathToBeUpdated: null, newCampaignArray: [],
  })

  addCampaignIdToForm = (campaignId) => {
    this.setState({
      newCampaignArray: [...this.state.newCampaignArray, campaignId],
    });
  }

  removeCampaignIdFromForm = (campaignId) => {
    this.setState({
      newCampaignArray: this.state.newCampaignArray.filter(campaign => campaignId !== campaign),
    });
  }

  generateRows() {
    const rowArray = [];

    Object.keys(this.props.store.integrations.OMI366.assignedDripCampaigns).forEach((domain) => {
      Object.keys(this.props.store.integrations.OMI366.assignedDripCampaigns[domain]).forEach((path) => {
        rowArray.push(<Table.Row key={domain + path}>
          <Table.Cell collapsing>
            {domain + path}
          </Table.Cell>
          <Table.Cell>
            {this.props.store.integrations.OMI366.assignedDripCampaigns[domain][path].campaignIds
              .map(campaignId => (_.get(this, ['props', 'store', 'integrations', 'OMI366', 'availableDripCampaigns', campaignId, 'CampaignName'], null) && (
                <Label key={domain + path + campaignId}>
                  {this.props.store.integrations.OMI366.availableDripCampaigns[campaignId].CampaignName}
                </Label>
              )
              ))}
          </Table.Cell>
          <Table.Cell collapsing>
            <Button
              positive
              icon="edit"
              onClick={() => this.openModal(domain, path)}
            />
          </Table.Cell>
        </Table.Row>);
      });
    });

    return rowArray;
  }

  render() {
    return (
      <>
        <Header as="h3" attached="top">
          Drip Campaigns
        </Header>

        <Segment attached className="sideScrollingTable">
          <Table basic="very" celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Form Url</Table.HeaderCell>
                <Table.HeaderCell>Drip Campaigns</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.generateRows()}
            </Table.Body>
          </Table>
        </Segment>

        <Modal
          open={this.state.modalOpen}
          onClose={this.state.closeModal}
        >
          <Modal.Header>Edit your campaign assignments</Modal.Header>
          <Modal.Content>
            <Header as="h4">Available Campaigns</Header>
            {
              Object.keys(this.props.store.integrations.OMI366.availableDripCampaigns)
                .filter(campaignId => (!this.state.newCampaignArray.includes(campaignId)))
                .map(campaignId => (
                  <Label key={campaignId}>
                    {this.props.store.integrations.OMI366.availableDripCampaigns[campaignId].CampaignName}
                    {' '}
                    <Icon
                      name="plus"
                      onClick={() => { this.addCampaignIdToForm(campaignId); }}
                      id="plusBtnOverride"
                    />
                  </Label>
                ))
            }

            <Divider />

            <Header as="h4">Assigned Campaigns</Header>
            {
              this.state.newCampaignArray.map(campaignId => (
                <Label key={campaignId}>
                  {this.props.store.integrations.OMI366.availableDripCampaigns[campaignId].CampaignName}
                  {' '}
                  <Icon
                    name="delete"
                    onClick={() => { this.removeCampaignIdFromForm(campaignId); }}
                  />
                </Label>
              ))
            }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              positive
              disabled={this.props.store.integrations.OMI366.loading}
              loading={this.props.store.integrations.OMI366.loading}
              onClick={async () => {
                await this.props.store.integrations.OMI366.updateDripCampaigns(this.state.formDomainToBeUpdated, this.state.formPathToBeUpdated, this.state.newCampaignArray);
                this.closeModal();
              }}
            >
              Update
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default DripCampaigns;
