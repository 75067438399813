export default `MOVology Terms & Conditions / Software as a Service Agreement

By checking the “I have read and agree to the terms of service" option to subscribe to MOVology’s application services, (i) you (“Customer”) are consenting to be bound by and become a party to this agreement ("Agreement"), and (ii) you represent and warrant that you have the authority to cause such entity to enter into this Agreement and to become bound by this Agreement. If Customer does not agree to all of the terms of this Agreement, you must not check or approve this option.
This Agreement sets forth the terms and conditions of Customer's use of Application Services provided by MOVology.

1. DEFINITIONS. In addition to any other terms defined elsewhere in this Agreement, the following terms will have the following meanings in this Agreement
“Affiliate” shall mean, with respect to a party, any entity, whether incorporated or not, that directly or indirectly controls, is controlled by, or is under common control with such party or its corporate parent, where “control” (or variants of it) shall mean the ability (whether directly or indirectly) to direct the affairs of another by means of ownership, contract or otherwise.
“Application Services” shall mean the online, internet-based applications provided by MOVology and ordered by Customer pursuant to an Order Form.
“Confidential Information” shall have the meaning set forth in Section 6.
“Customer Data” shall mean all electronic data or information submitted by Customer, harvested from the Customer’s website, provided by the Customer’s back office systems, provided by our own customers, provided by Customer partners, created or supplied by the customer, within the Application Services and transactional data resulting from Customer’s use of the Application Services.
“Duration” shall be as specified in each SOW, Statement of Work, and shall mean the estimated time from the Project Start Date to complete the Professional Services described in such SOW. Duration is an estimate of the time to complete the Professional Services and assumes that Customer’s availability, participation and cooperation have not delayed the Project Start Date or MOVology’s provision of the Professional Services.
“Malicious Code” shall mean viruses, worms, time bombs, Trojan horses and other harmful or malicious code, files, scripts, agents or programs.
“Non-Public Personal Information” shall mean personally identifiable information, including, without limitation, an individual's name, email address, telephone number, social security numbers, financial account numbers (i.e. credit card, checking account, savings account, etc.), medical, employment, or insurance numbers, and passport numbers.
“Opt-In” means to provide express consent as to the manner in which Non-Public Personal Information pertaining to an individual may be used and shared.
“Opt-Out” means to withdraw consent to use an individual's Non-Public Personal Information.
“Order Form” shall mean the ordering documents for Customer’s purchases of Application Services from MOVology that are executed hereunder by the parties from time to time. Order Forms shall be deemed incorporated herein. “Professional Services” shall mean the implementation, integration, consulting and similar services described in a SOW.
“Project Start Date” shall mean some date mutually agreed upon by the parties upon which MOVology shall commence provision of Professional Services under a SOW.
“Services” shall mean the Application Services and Professional Services collectively.
“SOW Effective Date” shall mean the SOW Effective Date set forth in each SOW.
“Start Date” shall mean the date on which MOVology shall make the Application Services available to Customer as set forth in an applicable Order Form.
“Statement of Work” or “SOW” shall mean MOVology’s form for ordering Professional Services which specifies the scope and schedule of Professional Services to be performed by MOVology for Customer and the fees applicable thereto and which SOW has been executed by Customer and MOVology. Each SOW entered into hereunder shall reference this Agreement and be governed by the terms hereof. In the event of a conflict or inconsistency between a term in a SOW and this Agreement, the terms set forth in the SOW shall control.
“Subscription Term” shall mean the subscription period for Application Services set forth on an applicable Order Form.
“Term” shall have the meaning set forth in Section 10.1.

2. APPLICATION SERVICES

2.1 Provision of Application Services. MOVology shall make the Application Services available to Customer pursuant to this Agreement and all Order Forms during a Subscription Term. Customer’s purchase of Application Services is not contingent upon the delivery of any future functionality or features. All rights not expressly granted to Customer hereunder are reserved by MOVology and its licensors.

2.2 Customer Affiliates. Customer Affiliates may not use Customer’s instance of the Application Services or the other Services without prior written authorization of MOVology. Customer's Affiliates may purchase and use subscriptions to the Application Services subject to the terms of this Agreement by executing Order Forms hereunder.

3. TERMS OF USE

3.1 Customer Responsibilities. Customer is responsible for all activities that occur in Customer’s account(s). Customer shall: (a) have sole responsibility for the accuracy, quality, integrity, legality, reliability, and appropriateness of all Customer Data; (b) prevent unauthorized access to, or use of, the Application Services, and notify MOVology promptly of any such unauthorized access or use; and (c) comply with all applicable local, state, federal and foreign laws in using the Application Services. Customer shall not upload to, or store within, the Application Services (and the Customer Data shall not contain) any Non-Public Personal Information. Customer understands and acknowledges that: (i) MOVology may, in its reasonable discretion, refuse to distribute any offer that MOVology reasonably believes is defamatory, infringing, or otherwise unlawful; (ii) MOVology has no obligation to review message content, recipient addresses or other Customer Data; (iii) all recipient addresses are supplied solely by Customer and MOVology has no obligation to supply or “scrub” any message recipient list; and (iv) Customer is solely responsible for the creation, initiation and sending of offers via the Application Services, including, but not limited to, the content, recipients, and timing of such offers.

3.2 User Guidelines. Customer shall use the Application Services solely for their internal business purposes as contemplated by this Agreement and shall not: (a) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the Application Services available to any third party except as contemplated by this Agreement; (b) send via or store within the Application Services infringing, obscene, threatening, defamatory, fraudulent, abusive, or otherwise unlawful or tortious material, including material that is harmful to children or violates third party privacy rights; (c) send via the Application Services any unsolicited commercial or non-commercial communication; (d) send via, upload to, or store within the Application Services any Malicious Code; (e) interfere with or disrupt the integrity or performance of the Application Services or the data contained therein; or (f) attempt to gain unauthorized access to the Application Services or its related systems or networks.

3.3 Email Footer. Customer acknowledges and agrees that it shall cause a default footer to be added to each email created as a result of Customer's use of the Application Services, which footer shall include (a) Customer’s or a Customer Affiliate’s physical mailing address; (b) links to MOVology’s or email Marketing Vendor’s profile update and unsubscribe centers; (c) a link to Customer's Privacy Policy; and (d) an attribution that the offer was powered by MOVology.

4. FEES & PAYMENT

4.1 Application Services Fees. Customer shall pay all fees specified in all Order Forms and SOW’s hereunder. Except as otherwise specified in an Order Form, fees are based on the Application Services purchased and not actual usage; payment obligations are non-cancelable; fees paid are non-refundable; and the Application Services purchased cannot be decreased during the Subscription Term.

4.2 Invoicing and Payment. Except as otherwise provided, all fees are quoted and payable in United States dollars. Fees for Services will be invoiced in advance and in accordance with the applicable Order Form and SOW’s. Customer shall pay invoices as stated in such Order Form and SOW’s. The initial invoice will include the applicable Setup Fee, First Month License Fee and the applicable Annual Review Fee. Subsequent Monthly License Fees are due on the first of the month. Customer is responsible for maintaining complete and accurate billing and contact information within the Application Services. Except as otherwise provided in an Order Form, Fees will be due upon receipt of MOVology’s invoice

4.3 Overdue Payments. Customer’s failure to pay, as set forth herein shall constitute a material breach of this Agreement and MOVology may impose late fees as contemplated in each Order Form and/or SOW. If Customer’s account is 30 days or more overdue (except with respect to charges then under reasonable and good faith dispute), MOVology may, in addition to any of its other rights or remedies, suspend Customer’s access to the Application Services and/or suspend provision of Professional Services, without liability to Customer, until such amounts are paid in full. If such failure to pay has not been cured within 30 days of the due date, then upon written notice MOVology may terminate this Agreement and any or all outstanding Order Forms and/or Statements of Work. In the event of termination for non-payment the client will be held liable for the remaining unpaid value of the Order Form.

4.4 Taxes. Unless otherwise stated, MOVology’s fees do not include any direct or indirect local, state, federal or foreign taxes, levies, duties or similar governmental assessments of any nature, including value-added, use or withholding taxes (collectively, “Taxes”). Customer is responsible for paying all Taxes associated with its purchases hereunder, excluding taxes based on MOVology’s net income or property. If MOVology has the legal obligation to pay or collect Taxes for which Customer is responsible under this Section, the appropriate amount shall be invoiced to and paid by Customer, unless Customer provides MOVology with a valid tax exemption certificate authorized by the appropriate taxing authority.

4.5 Audit Rights. MOVology may license one or more of the Application Services under a commission or other transactional basis as set forth in an Order Form or SOW. Customer agrees to assist MOVology in the determination of such calculations and agrees that MOVology shall have the right to extract information from the Customer Data as MOVology may reasonably require to determine the appropriate charges hereunder. Upon written request from MOVology, Customer shall provide such other information as MOVology may reasonably request for purposes of calculating variable charges.

5. PROPRIETARY RIGHTS

5.1 Reservation of Rights. Subject to the limited rights expressly granted hereunder, MOVology reserves all rights, title and interest in and to the Application Services, including all related intellectual property rights. No rights are granted to Customer hereunder other than as expressly set forth herein.

5.2 Restrictions. Customer shall not (a) modify, copy or create derivative works based on the Application Services; (b) frame or mirror any content forming part of the Application Services, other than on Customer’s own intranets or otherwise for its own internal business purposes; (c) reverse engineer the Application Services; or (d) access the Application Services in order to (i) build a competitive product or service, or (ii) copy any ideas, features, functions or graphics of the Application Services.

5.3 Customer Data. As between MOVology and Customer, Customer exclusively owns all rights, title and interest in and to all Customer Data. However, MOVology is granted a royalty-free perpetual license to have access to and audit Customer Data to validate and/or compute fees for any Application Service. In addition, MOVology shall receive from customers who are governed by the terms of this agreement, a perpetual royalty-free license to include Customer Data that is de-identified as to Customer being the source and is non-visitor specific for MOVology research, analysis, reporting including the right to sublicense and sell the same as part of MOVology business. Such data, which may be used by MOVology, shall be non-client specific and non-visit specific. Customer agrees that it shall not obscure or interfere with MOVology gathering of any of the foregoing elements of Customer Data.

5.4 Improvements. MOVology shall own all rights, title and interest, including all intellectual property rights, in and to any improvements to the Application Services or any new programs, upgrades, modifications or enhancements developed by MOVology in connection with rendering the Application Services to Customer, even when refinements and improvements result from Customer’s request. To the extent, if any, that ownership in such refinements and improvements does not automatically vest in MOVology by virtue of this Agreement or otherwise, Customer hereby transfers and assigns (and, if applicable, shall cause its Affiliates to transfer and assign) to MOVology all rights, title, and interest which Customer or its Affiliates may have in to such refinements and improvements.

5.5 Professional Services Deliverables. MOVology hereby grants Customer and its Affiliates a worldwide, perpetual, non-exclusive, non-transferable, royalty-free license to use for its internal business purposes anything developed by MOVology for Customer under a SOW (“Deliverables”). The foregoing license shall not include the right to modify and creative derivative works from the Deliverables. MOVology shall retain all ownership rights to the Deliverables.

5.6 Publicity; Trademarks. Neither party may issue press releases or any other public announcement of any kind relating to this agreement without the other party’s prior written consent. Notwithstanding the foregoing, during the Term, either party may include the name and logo of the other party in lists (including on its website) of customers or vendors in accordance with the other party’s standard logo and/or trademark usage guidelines. In addition, MOVology may use the trademarks and trade names of Customer solely in connection with its authorized provision of the Application Services. Except as set forth herein, neither party may use the trademarks and trade names of the other party without the prior written consent of the other party.

6. CONFIDENTIALITY, PRIVACY and SECURITY

6.1 Definition of Confidential Information. As used herein, “Confidential Information” means all confidential and proprietary information of a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”) that (a) if disclosed orally is designated as confidential at the time of disclosure, (b) if disclosed in writing is marked as “Confidential” and/or “Proprietary”, or (c) that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure, including, without limitation, the terms and conditions of this Agreement (including pricing and other terms reflected in all Order Forms hereunder), the Customer Data, provision of the Services, business and marketing plans, technology and technical information, product designs, and business processes. Notwithstanding the foregoing, each party may disclose the existence and terms of this Agreement, in confidence, to a potential purchaser of or successor to any portion of such party’s business resulting from the reorganization, spin-off, or sale of all or a portion of all of the assets of any business, division, or group of such party. Confidential Information (except for Customer Data) shall not include any information that: (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party; (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party; (iii) was independently developed by the Receiving Party without breach of any obligation owed to the Disclosing Party; or (iv) is received from a third party without breach of any obligation owed to the Disclosing Party.

6.2 Confidentiality. The Receiving Party shall not disclose or use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement, except with the Disclosing Party’s prior written permission. Notwithstanding the foregoing, the Receiving Party may disclose such Confidential Information to those of its employees and contractors who need to know such information for purposes of performing the Services and certifies that such employees and contractors have agreed, either as a condition of employment or in order to obtain the Confidential Information, to be bound by terms and conditions substantially similar to those in this Agreement. The Receiving Party shall use the same degree of care to protect the Confidential Information as it uses to protect its own information of a confidential and proprietary nature, but in no event shall it use less than a reasonable degree of care.

6.3 Compelled Disclosure. If the Receiving Party is compelled by law to disclose Confidential Information of the Disclosing Party, it shall provide the Disclosing Party with prior notice of such compelled disclosure (to the extent legally permitted) and reasonable assistance, at Disclosing Party’s cost, if the Disclosing Party wishes to contest the disclosure.

6.4 Remedies. If the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential Information of the Disclosing Party in breach of confidentiality protections hereunder, the Disclosing Party shall have the right, in addition to any other remedies available to it, to seek injunctive relief to enjoin such acts, it being specifically acknowledged by the parties that any other available remedies are inadequate.

6.5 Survival. Notwithstanding the expiration or termination of this Agreement for any reason, the obligations of confidentiality and non-use set forth in this Section shall extend for a period of two years after such expiration or termination.

7. WARRANTIES & DISCLAIMERS

7.1 Customer Warranties. Customer represents and warrants that: (a) the Customer Data shall not infringe on any copyright, patent, trade secret or other proprietary right held by any third party; (b) Customer shall not use the Application Services in a manner that violates any international, federal, state, or local law or regulation relating to individual privacy or the distribution of email and other one-to-one digital messages; and (c) Customer engages in no activity in violation of the UIGE Act nor shall Customer use the Application Services to advertise or otherwise promote any activities that would violate the UIGE Act.

7.2 MOVology Warranties.

A. Application Services. MOVology represents and warrants that: (a) the functionality of the Application Services will not be materially decreased during a Subscription Term; (b) MOVology shall utilize software and other security means to prevent the Application Services from containing or transmitting Malicious Code; and (c) it owns or otherwise has sufficient rights in the Application Services to grant to Customer the rights to use the Application Services granted herein.

B. Professional Services. MOVology represents and warrants that Professional Services will be performed in a professional and workmanlike manner in accordance with generally accepted industry standards. Customer must report any deficiencies in the Professional Services to MOVology in writing within 90 days of performance of such Professional Services in order to receive warranty remedies. For any breach of the warranty in this Section 8.3(B), Customer’s exclusive remedy, and MOVology’s entire liability, shall be the re- performance of the Professional Services. If MOVology is unable to re-perform the Professional Services as warranted within 30 days of receipt of notice of breach, Customer shall be entitled to terminate the applicable SOW for cause and to recover the fees paid to MOVology for the deficient Professional Services.

8. INDEMNIFICATION

8.1 By MOVology. MOVology shall defend, indemnify and hold Customer harmless against any loss, damage or costs (including reasonable attorneys’ fees) incurred in connection with claims, demands, suits or proceedings (“Claims”) made or brought against Customer by a third party alleging that Customer’s use of the Application Services or Deliverables within the scope of this Agreement infringes the intellectual property rights of such third party; provided, however, that MOVology shall have no such indemnification obligation to the extent such infringement: (a) relates to use of the Application Services or Deliverables in combination with other software, data products, processes, or materials not provided by MOVology to the extent which the infringement would not have occurred but for the combination; (b) arises from or relates to modifications to the Application Services or Deliverables not made or authorized by MOVology; or (c) where Customer continues the activity or use constituting or contributing to the infringement after notification thereof by MOVology.

8.2 By Customer. Customer shall defend, indemnify, and hold MOVology harmless against any loss, damage, or costs (including reasonable attorneys’ fees) incurred in connection with a Claim that (a) Customer has used the Application Services in violation of the terms of use set forth in this Agreement; (b) Customer Data, and/or any materials provided to MOVology necessary to perform the Professional Services, infringe the intellectual property rights of a third party; (c) MOVology’s use, in connection with its provision of the Application Services as provided herein, of any Customer Data has otherwise harmed a third party; or (d) Customer has violated the UIGE Act.

8.3 Procedure. As an express condition to the indemnifying party’s obligation under this Section 9, the party seeking indemnification must: (a) promptly notify the indemnifying party in writing of the applicable Claim for which indemnification is sought; and (b) provide the indemnifying party with all non-monetary assistance, information and authority reasonably required for the defense and settlement of such Claim. The indemnifying party may select counsel for defense of the Claim and direct the course of any litigation or other disputed proceedings concerning the Claim. The indemnified party may select its own counsel and direct its own defense of a Claim if it chooses to do so, but it must bear the costs of its own counsel and any activities in any disputed proceeding conducted by counsel of its choosing. The indemnifying party may settle any Claim; to the extent it seeks a money payment, with or without the consent of the indemnified party. The indemnifying party must obtain the indemnified party’s consent to any settlement to the extent it consents to injunctive relief or contains contract terms governing future activities that would materially affect the indemnified party’s business or interests, said consent not to be unreasonably withheld, conditioned or delayed.

9. LIMITATIONS

9.1 Limitation of Liability. EXCEPT FOR MOVology’s INDEMNITY OR DUE TO A BREACH OF ITS OBLIGATIONS UNDER ARTICLE 7 HEREOF, IN NO EVENT SHALL MOVology’s AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, EXCEED THE LESSER OF $100,000 OR THE AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM CUSTOMER HEREUNDER IN THE 12 MONTHS PRECEDING THE INCIDENT GIVING RISE TO LIABILITY.

9.2 Exclusion of Consequential and Related Damages. IN NO EVENT SHALL MOVology HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY LOST PROFITS OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR SPECIAL DAMAGES OF ANY KIND OR NATURE HOWEVER CAUSED AND, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, WHETHER OR NOT CUSTOMER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

9.3 Time to File Claim. NO CLAIM MAY BE BROUGHT BY CUSTOMER UNDER THIS AGREEMENT MORE THAN ONE YEAR AFTER THE ACCRUAL OF THE CLAIM.

10. TERM & TERMINATION

10.1 Term of Agreement. This Agreement commences on the Effective Date and continues until terminated as set forth in this Section 10 (the “Term”).

10.2 Term of Subscriptions. Subscriptions to the Application Services commence on the Start Date and continue for the Subscription Term specified in the applicable Order Form. Unless otherwise set forth in an Order Form, subscriptions shall automatically renew for additional periods of one year for the fees set forth in the applicable Order Form unless either party gives the other notice of non-renewal at least 30 days prior to the end of the relevant Subscription Term.

10.3 Term of Statement of Work. Each SOW shall commence on the SOW Effective Date and continue until the Professional Services described therein are completed. MOVology may terminate a SOW for convenience upon five business days’ prior written notice if Customer has not authorized work to begin under such SOW within 30 calendar days of the SOW Effective Date.

10.4 Termination for Cause. A party may terminate this Agreement for cause: (a) upon 30 days’ written notice of a material breach to the other party if such breach remains uncured at the expiration of such period; or (b) immediately if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors. MOVology may immediately terminate this Agreement upon notice to Customer if (i) MOVology or Customer receives notice from any governmental entity that MOVology (in connection with its provision of the Application Services to Customer) or Customer is alleged to be in violation of the UIGE Act, or (ii) MOVology learns of any Customer activity that MOVology deems, in its sole discretion, to be in violation of the UIGE Act. Upon any termination for cause by Customer, MOVology shall refund Customer any prepaid fees covering the remainder of the Subscription Term after the date of termination. Termination for cause by Customer shall not relieve Customer of the obligation to pay any fees accrued or payable to MOVology prior to the effective date of termination. Upon any termination for cause by MOVology, Customer shall remain obligated to pay all fees owed for the remainder of the Subscription Term, all of which fees shall become immediately due and payable in full.

10.5 Default and Termination. In the event that either Party materially defaults in the performance of any of its duties or obligations under this Agreement and does not substantially cure such default, or commence a cure, within thirty (30) days after being given written notice specifying the default, the non-defaulting Party may, by giving written notice thereof to the defaulting Party, terminate this Agreement. Either party may, by giving (30) days written notice terminate this Agreement. 10.6 Customer Data. Following the termination or expiration of this Agreement, Customer shall have 30 days to access its account and download / export Customer Data. Upon expiration of such 30-day period, MOVology shall convert Customer’s account to an inactive status.

11. GENERAL PROVISIONS

11.1 Relationship of the Parties. The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between the parties.

11.2 No Third-Party Beneficiaries. There are no third-party beneficiaries to this Agreement.

11.3 Notices. All notices under this Agreement shall be in writing and shall be deemed to have been given upon: (a) personal delivery; (b) the second business day after mailing; (c) the second business day after sending by confirmed facsimile; or (d) the second business day after sending by email. Notices to MOVology shall be addressed to the attention of its President. Notices to Customer shall be addressed to Customer’s signatory of this Agreement unless otherwise designated below.

11.4 Waiver and Cumulative Remedies. No failure or delay by either party in exercising any right under this Agreement shall constitute a waiver of that right. Other than as expressly stated herein, the remedies provided herein are in addition to, and not exclusive of, any other remedies of a party at law or in equity.

11.5 Severability. Any provision of this Agreement which is prohibited and unenforceable in any jurisdiction shall, as to such jurisdiction, be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions hereof or affecting the validity or enforceability of such provisions in any other jurisdiction.

11.6 Assignment. Neither party may assign any of its rights or obligations hereunder, whether by operation of law or otherwise, without the prior written consent of the other party (not to be unreasonably withheld, conditioned or delayed). Notwithstanding the foregoing, either party may assign this Agreement in its entirety (including all Order Forms), without consent of the other party, in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets not involving a direct competitor of the other party. Any attempt by a party to assign its rights or obligations under this Agreement in breach of this Section shall be void and of no effect. Subject to the foregoing, this Agreement shall bind and inure to the benefit of the parties, their respective successors and permitted assigns.

11.7 Governing Law. This Agreement shall be governed exclusively by the internal laws of the State of California, without regard to its conflicts of laws rules. The state and federal courts located in Orange County (Santa Ana), California shall have exclusive jurisdiction to adjudicate any dispute arising out of or relating to this Agreement. Each party consents to the exclusive jurisdiction of such courts and also waives any right to jury trial in connection with any action or litigation in any way arising out of or related to this Agreement.

11.8 Attorneys’ Fees. If either party hereto resorts to legal action for the redress of a breach of this Agreement, the prevailing party shall be entitled to an award of costs and reasonable attorney’s fees,

11.9 Entire Agreement. This Agreement, including all Exhibits, Order Forms and SOW’s, constitutes the entire agreement between the parties, and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. No modification, amendment, or waiver of any provision of this Agreement shall be effective unless in writing and signed by the party against whom the modification, amendment or waiver is to be asserted. To the extent of any conflict or inconsistency between the provisions in the body of this Agreement and any Exhibit, Order Form, or Statement of Work, the terms of this Agreement shall prevail unless expressly stated otherwise in the applicable Exhibit, Order Form, or SOW. Notwithstanding any language to the contrary therein, no terms or conditions stated in a Customer purchase order or in any other Customer order documentation (excluding Order Forms and Statements of Work) shall be incorporated into or form any part of this Agreement, and all such terms or conditions shall be null and void. The language used in this Agreement shall be deemed to be language chosen by both parties hereto to express their mutual intent, and no rule of strict construction against either party shall apply to rights granted herein or to any term of condition of this Agreement.`;
