import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Segment, Header, Grid, Button, Statistic, Icon,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import moment from 'moment';
import { colors } from '../../../../enums';
import { addCenterLegendToDoughnuts, restoreOriginalDoughnutDraw } from '../../../shared/DoughnutCenterLegend';
import './Chart.css';

const DOUGHNUT_CHART_OPTIONS = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    labels: {
      render: 'value',
      fontSize: 16,
      fontColor: 'white',
    },
  },
  legend: {
    display: false,
  },
};

const BLANK_DOUGHNUT_CHART_OPTIONS = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    labels: false,
  },
  legend: {
    display: false,
  },
};

const BAR_CHART_OPTIONS = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    labels: {
      fontSize: 0,
    },
  },
  scales: {
    xAxes: [{
      stacked: true,
      gridLines: {
        lineWidth: 2,
      },
    }],
    yAxes: [{
      stacked: true,
    }],
  },
};

@inject('store')
@observer
class Chart extends Component {
  componentDidMount() {
    addCenterLegendToDoughnuts();
  }

  componentWillUnmount() {
    restoreOriginalDoughnutDraw();
  }

  data = () => {
    const { chartData } = this.props.store.analytics;
    return {
      labels: chartData.map(([dateStart]) => moment(dateStart).add(1, 'day').format('MMM DD')),
      datasets: [
        {
          label: 'Forms Completed',
          backgroundColor: colors.teal,
          borderColor: colors.teal,
          pointBorderColor: colors.teal,
          pointHoverBackgroundColor: colors.teal,
          pointHoverBorderColor: colors.teal,
          data: chartData.map(([, data]) => Number(data.forms_started) - Number(data.abandons)),
        },
        {
          label: 'Abandons with Email',
          backgroundColor: colors.red,
          borderColor: colors.red,
          pointBorderColor: colors.red,
          pointHoverBackgroundColor: colors.red,
          pointHoverBorderColor: colors.red,
          data: chartData.map(([, data]) => Number(data.unique_valid_abandoned_emails)),
        },
        {
          label: 'Abandons without Email',
          backgroundColor: colors.orange,
          borderColor: colors.orange,
          pointBorderColor: colors.orange,
          pointHoverBackgroundColor: colors.orange,
          pointHoverBorderColor: colors.orange,
          data: chartData
            .map(([, data]) => Number(data.abandons) - Number(data.unique_valid_abandoned_emails)),
        },
      ],
    };
  }

  recaptureData = () => {
    if (this.props.store.analytics.totalSaves) {
      return {
        labels: ['Direct Saves', 'Influenced Saves'],
        datasets: [{
          data: [
            this.props.store.analytics.totalDirectRecaptures,
            this.props.store.analytics.totalInfluencedSaved,
          ],
          backgroundColor: [colors.teal, colors.orange],
          hoverBackgroundColor: [colors.teal, colors.orange],
        }],
        text: this.props.store.analytics.totalSaves.toLocaleString(),
      };
    }
    return {
      labels: ['No Data'],
      datasets: [{
        data: [100],
        backgroundColor: [colors.teal],
        hoverBackgroundColor: [colors.teal],
      }],
      text: '',
    };
  }

  render() {
    const { paidPlan } = this.props.store.auth;
    return (
      <Grid.Row>
        <Grid.Column width={12}>
          <Segment raised size="small" style={{ minHeight: '40vh' }}>
            <Header as="h4">Form Metrics</Header>
            <div id="MovologyMetrics">
              <Bar
                options={BAR_CHART_OPTIONS}
                data={this.data()}
              />
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column width={4}>
          <Segment
            raised
            size="small"
            style={paidPlan ? { minHeight: '40vh' } : { minHeight: '40vh', backgroundColor: 'lightgrey' }}
          >
            <div style={paidPlan ? {} : {
              filter: 'opacity(50%)', pointerEvents: 'none',
            }}
            >
              <Header as="h4">
                Recapture Metrics
              </Header>
              <span
                style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
                data-variation="wide"
                data-position="top right"
                data-tooltip="Direct saves are counted when a client completes a form using the remarket email link. Influenced saves are counted when a client opens the email and completes a form not from the remarket email."
              >
                <Icon disabled color="grey" name="info circle" />
              </span>
              <div
                style={{
                  padding: '0 1.5rem',
                  position: 'relative',
                  width: '100%',
                  height: '40vh',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '30vh',
                  }}
                >
                  <Doughnut
                    options={
                      this.props.store.analytics.totalSaves
                        ? DOUGHNUT_CHART_OPTIONS
                        : BLANK_DOUGHNUT_CHART_OPTIONS
                    }
                    data={this.recaptureData()}
                  />
                </div>

                <br />

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Statistic size="tiny">
                    <Statistic.Value>
                      {this.props.store.analytics.statSum('emails_delivered').toLocaleString()}
                    </Statistic.Value>
                    <Statistic.Label>
                      Emails Sent
                    </Statistic.Label>
                  </Statistic>

                  <Statistic size="tiny">
                    <Statistic.Value>
                      {this.props.store.analytics.totalSaves.toLocaleString()}
                    </Statistic.Value>
                    <Statistic.Label>
                      Total Saves
                    </Statistic.Label>
                  </Statistic>
                </div>
              </div>
            </div>
            {
              !paidPlan && (
              <Button
                primary
                content="Premium Feature"
                onClick={() => this.props.history.push('/faq')}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
              )
            }
          </Segment>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default withRouter(Chart);
