import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Tab } from 'semantic-ui-react';

import PaymentSetup from './PaymentSetup';
import AccountDetails from './AccountDetails';
import CapturePlansUpgrade from './PaymentSetup/CapturePlansUpgrade';

@inject('store')
@observer
class Payments extends Component {
  panes = () => {
    const { account } = this.props.store.accountStatus;
    const tabArr = [];

    if (Object.keys(account).length > 0 && account.subscriptions.data.length > 0) {
      tabArr.unshift({
        menuItem: 'Upgrade Plan',
        render: () => <CapturePlansUpgrade />,
      });
      tabArr.unshift({
        menuItem: 'Account Details',
        render: () => <AccountDetails />,
      });
    } else {
      tabArr.unshift({
        menuItem: 'Capture Plans',
        render: () => <PaymentSetup />,
      });
    }
    return tabArr;
  }

  render() {
    return (
      <Tab
        panes={this.panes()}
        menu={{ secondary: true, pointing: true }}
      />
    );
  }
}

export default Payments;
