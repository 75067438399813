/* eslint no-underscore-dangle: 0 */
import _ from 'lodash';
import { observable, action } from 'mobx';

class Realtime {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this._realtimeRef = null;
  }

  @observable loading = {
    bindRealtimeData: false,
  }

  @observable data = {
    currentSessions: 0,
  }

  get realtimeRef() {
    if (this._realtimeRef !== null) {
      return this._realtimeRef;
    }
    const { clientId } = this.rootStore.auth.user;
    if (clientId) {
      this._realtimeRef = this.rootStore
        .firebase.database().ref(`realtimeData/${clientId}`);
    }

    return this._realtimeRef;
  }

  @action
  bindRealtimeData = () => new Promise((resolve, reject) => {
    let initialCall = false;
    const timeout = setTimeout(() => reject(new Error('Timeout error; exceeded 10s')), 10000);

    this.realtimeRef.on('value', (snapshot) => {
      this.data = {
        ...this.realtime,
        ...snapshot.val() || {},
      };

      if (initialCall === false) {
        initialCall = true;
        resolve(true);
        clearTimeout(timeout);
      }
    });
  });

  @action reset = () => {
    this.loading = _.mapValues(this.loading, () => false);
    this.data = {
      currentSessions: 0,
    };
  }
}

export default Realtime;
