import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import {
  Header, Segment, Grid, List, Button, Divider, Modal, Icon,
} from 'semantic-ui-react';
import CardInput from '../PaymentSetup/CardInput';

@inject('store')
@observer
class AccountDetails extends Component {
  state = {
    open: false,
  }

  formatMoney = (num) => {
    const numText = num.toString();

    if (numText.length === 1) {
      return 'Free';
    }

    const cent = numText.slice(numText.length - 2);
    const dollar = numText.slice(0, numText.length - 2);
    return `$${dollar}.${cent} per Month`;
  }

  handelUpdate = () => {
    this.props.store.payments.updateStripePayment();
  };

  handleCancel = async () => {
    this.props.store.accountStatus.cancelSubscription();
  };

  close = () => this.setState({ open: false })

  open = () => this.setState({ open: true })

  modal = () => {
    const { open } = this.state;
    return (
      <Modal
        trigger={(
          <Button labelPosition="left" icon onClick={this.open}>
            <Icon name="x" />
            Cancel Subscription
          </Button>
        )}
        open={open}
        size="small"
      >
        <Header content="Cancel Subscription" />
        <Modal.Content>
          <p>
              Are you sure you want to cancel?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close} color="red">
            <Icon name="remove" />
            No
          </Button>
          <Button onClick={this.handleCancel} color="green">
            <Icon name="checkmark" />
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const {
      account: {
        sources: { data: payments },
        subscriptions,
      },
    } = this.props.store.accountStatus;

    const { loading } = this.props.store.payments;
    const subData = _.get(subscriptions, ['data', '0', 'items', 'data'], []);
    const planData = _.get(subData, ['0', 'plan']);

    return (
      <Fragment>
        <Segment>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">Your Subscriptions</Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h3">Payment Settings</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <List bulleted>
                    <List.Item>
                      Name:
                      { ` ${planData.metadata.name}` }
                    </List.Item>
                    <List.Item>
                      Cost:
                      { ` ${this.formatMoney(planData.amount)}` }
                    </List.Item>
                    <List.Item>
                      Usage Limit:
                      { ` ${planData.metadata.limit}` }
                    </List.Item>
                    <List.Item>
                      Billing Date:
                      { ` ${moment(subscriptions.data[0].current_period_end * 1000).format('MM-DD-YYYY')}` }
                    </List.Item>
                    {
                      subscriptions.data[0].cancel_at
                        ? (
                          <List.Item>
                          Cancel Date:
                            { ` ${moment(subscriptions.data[0].current_period_end * 1000).format('MM-DD-YYYY')}` }
                          </List.Item>
                        )
                        : null
                    }
                  </List>
                  {
                    !subscriptions.data[0].cancel_at_period_end
                      ? this.modal()
                      : null
                  }
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <List bulleted>
                    <List.Item>
                      {
                        `Payment: **** **** **** ${payments[0].last4} - ${payments[0].brand}`
                      }
                    </List.Item>
                    <List.Item>
                      {
                        `Expiration Date: ${payments[0].exp_month}/${payments[0].exp_year}`
                      }
                    </List.Item>
                  </List>
                  <Segment>
                    Change Payment Source
                    <CardInput />
                  </Segment>
                  <Button
                    labelPosition="left"
                    icon
                    color="green"
                    onClick={this.handelUpdate}
                    loading={loading}
                    disabled={loading}
                  >
                    <Icon name="check" />
                    Update Payment
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
        </Segment>
      </Fragment>
    );
  }
}

export default AccountDetails;
