import _ from 'lodash';
import React, { Component } from 'react';
import {
  Grid, Tab, Dropdown, Button,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import EmailSettings from './EmailSettings';
import Templates from './Templates';
import Unsubscribes from './Unsubscribes';
import AddEmailDomainModal from './EmailSettings/Modals/AddEmailDomain';

@inject('store')
@observer
class Remarket extends Component {
  state = {
    modalOpen: false,
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  }

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  }

  panes = [
    {
      menuItem: 'Email Settings',
      render: () => <EmailSettings handleModalOpen={this.handleModalOpen} />,
    },
    {
      menuItem: 'Templates',
      render: () => <Templates />,
    },
    {
      menuItem: 'Unsubscribes',
      render: () => <Unsubscribes />,
    },
  ];

  get selectOptions() {
    return _.map(this.props.store.emailManagement.emailDomains, (domainSettings, emailDomain) => ({
      key: emailDomain,
      text: emailDomain,
      value: emailDomain,
    }));
  }

  createDomainDropdown(emailDomainCount = _.size(this.props.store.emailManagement.emailDomains)) {
    if (emailDomainCount > 0) {
      return (
        <div style={{ pointerEvents: 'auto', width: '70%', float: 'right' }}>
          <Dropdown
            style={
              emailDomainCount < 3
                ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                : {}
            }
            selection
            options={this.selectOptions}
            onChange={this.props.store.emailManagement.setActiveEmailDomainName}
            value={this.props.store.emailManagement.activeEmailDomainName}
          />
          {
            emailDomainCount < 3
              ? <Button icon="add" positive data-tooltip="Add Email Domain" data-position="bottom right" attached="right" onClick={this.handleModalOpen} />
              : null
          }
        </div>
      );
    }
    return null;
  }

  render() {
    const emailDomainCount = _.size(this.props.store.emailManagement.emailDomains);
    return (
      <Grid>
        { emailDomainCount
          ? (
            <Grid.Row style={{ position: this.props.store.ui.mobile ? 'inherit' : 'absolute', zIndex: 3, pointerEvents: 'none' }}>
              <Grid.Column textAlign="right">
                {this.createDomainDropdown(emailDomainCount)}
              </Grid.Column>
            </Grid.Row>
          )
          : null
        }
        <Grid.Row style={{ marginTop: '0.5em', zIndex: 2 }}>
          <Grid.Column>
            <Tab
              panes={this.panes}
              menu={{ secondary: true, pointing: true }}
            />
          </Grid.Column>
        </Grid.Row>
        <AddEmailDomainModal onClose={this.handleModalClose} open={this.state.modalOpen} />
      </Grid>
    );
  }
}

export default Remarket;
