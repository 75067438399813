import _ from 'lodash';
import React, { Component } from 'react';
import {
  Modal, Button, Form, Input,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Dashboard } from '@uppy/react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

@inject('store')
@observer
class EmailModal extends Component {
  defaultState = {
    /** template state */
    name: '',
    template: '',
    description: '',
    tag: '',
    comment: '',
    /** test email state */
    to: this.props.store.auth.user.email || '',
    from: `test@${this.props.store.emailManagement.activeEmailDomainName}`,
    subject: '',
    text: '',
    formData: '',
  }

  state = this.defaultState

  componentDidUpdate(prevProps) {
    const { templateName = '' } = this.props;
    if (prevProps.templateName !== templateName) {
      this.updateNameInputs(templateName);
    }
  }

  handleInput = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  handleCancel = () => {
    this.setState(this.defaultState, this.props.onClose);
  }

  addTemplateModal = (props = this.props) => {
    const inputs = [
      'name', 'template', 'description', 'tag', 'comment',
    ];

    const placeholders = [
      'Template Name',
      'Template HTML',
      'Description',
      'Tag',
      'Comment',
    ];

    const formFields = placeholders
      .map((placeholder, i) => this.createFormField(inputs[i], placeholder));

    return (
      <Modal size="large" open={props.open} onClose={this.handleCancel}>
        <Modal.Header>Add Email Template</Modal.Header>
        <Modal.Content>
          <Form>
            {formFields}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={async () => {
              await props.store.emailManagement.addTemplate(
                _.pick(this.state, inputs),
              );
              this.handleCancel();
            }}
            loading={this.props.store.emailManagement.loading.addTemplate}
            disabled={this.props.store.emailManagement.loading.addTemplate}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  uploadTemplatesModal = (props = this.props) => (
    <Modal size="large" open={props.open} onClose={this.handleCancel}>
      <Modal.Header>Upload Email Templates</Modal.Header>
      <Modal.Content>
        <Dashboard
          uppy={this.props.store.emailManagement.uppy}
          width="100%"
          showProgressDetails
          hideUploadButton
          hideRetryButton
          locale={{
            strings: {
              dropHereOr: 'Drop files/templates here or %{browse}',
              browse: 'browse',
            },
          }}
          note={`Limitations
          | Max Count: 10 Templates.
          | Max Filesize: < 1024KB
          | Valid Formats: .html, .htm, .hbm, .handlebars
          `}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="cloud upload"
          content="Upload"
          labelPosition="left"
          onClick={async () => {
            await props.store.emailManagement.uploadTemplates();
            this.handleCancel();
          }}
          loading={this.props.store.emailManagement.loading.uploadTemplates}
          disabled={this.props.store.emailManagement.loading.uploadTemplates}
        />
      </Modal.Actions>
    </Modal>
  )

  deleteTemplateModal = (props = this.props) => (
    <Modal size="small" open={props.open} onClose={this.handleCancel}>
      <Modal.Header>
        Are you sure you want to delete:
        <strong>{`  ${this.props.templateName}`}</strong>
      </Modal.Header>
      <Modal.Actions>
        <Button
          negative
          icon="delete"
          content="Delete"
          labelPosition="left"
          onClick={async () => {
            await props.store.emailManagement.deleteTemplate(props.templateName);
            this.handleCancel();
          }}
          loading={this.props.store.emailManagement.loading.deleteTemplate}
          disabled={this.props.store.emailManagement.loading.deleteTemplate}
        />
      </Modal.Actions>
    </Modal>
  )

  sendTestEmailModal = (props = this.props) => {
    const inputs = [
      'name', 'to', 'from', 'subject', 'text', 'formData',
    ];

    const formFields = inputs
      .map((inputName, i) => this.createFormField(inputs[i], _.startCase(inputName)));

    return (
      <Modal size="small" open={props.open} onClose={this.handleCancel}>
        <Modal.Header>Send Test Email</Modal.Header>
        <Modal.Content>
          <Form>
            {formFields}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="mail"
            content="Send Test E-mail"
            labelPosition="left"
            onClick={async () => {
              if (this.validateFormData()) {
                await props.store.emailManagement.sendTestEmail(
                  _.pick(this.state, inputs),
                );
                this.handleCancel();
              } else {
                props.store.failure('Badly formatted JSON!');
              }
            }}
            loading={this.props.store.emailManagement.loading.sendTestEmail}
            disabled={this.props.store.emailManagement.loading.sendTestEmail}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  errorModal = (props = this.props) => (
    <Modal size="large" open={props.open} onClose={this.handleCancel}>
      <Modal.Header>Something went wrong...</Modal.Header>
    </Modal>
  )

  selectModalType = (type = this.props.type) => {
    switch (type) {
      case 'addTemplate':
        return this.addTemplateModal;
      case 'uploadTemplates':
        return this.uploadTemplatesModal;
      case 'deleteTemplate':
        return this.deleteTemplateModal;
      case 'sendTestEmail':
        return this.sendTestEmailModal;
      default:
        return this.errorModal;
    }
  }

  updateNameInputs(newTemplateName) {
    this.setState({
      name: newTemplateName || '',
      subject: `TEST - ${newTemplateName}`,
    });
  }

  validateFormData(formData = this.state.formData) {
    try {
      if (formData !== '') {
        JSON.parse(formData);
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  createFormField(name, placeholder = '') {    
    return (
      <Form.Field key={name}>
        <label>
          {_.startCase(name)}
          {name === 'formData' ? (
            <Button.Group>
              <Button
                basic
                compact
                fitted
                icon="info circle"
                data-tooltip="Optional: Please provide data as JSON"
                data-position="right center"
              />
            </Button.Group>
          ) : null}
        </label>
        <Input
          placeholder={placeholder}
          name={name}
          value={this.state[name]}
          onChange={this.handleInput}
        />
      </Form.Field>
    );
  }

  render() {
    const { type } = this.props;
    return this.selectModalType(type)(this.props);
  }
}

export default EmailModal;
