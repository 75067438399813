import { Chart } from 'react-chartjs-2';
// import { colors } from '../../../enums';

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;

export const addCenterLegendToDoughnuts = (h = 2) => {
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw(...args) {
      originalDoughnutDraw.apply(this, args);

      const { width, height, ctx } = this.chart;

      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.fillStyle = 'black';

      const { text } = this.chart.config.data;

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / h;

      ctx.fillText(text, textX, textY);
    },
  });
};

export const restoreOriginalDoughnutDraw = () => {
  Chart.controllers.doughnut.prototype.draw = originalDoughnutDraw;
};
