import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Grid, Header, Card, Icon, Label, Divider,
} from 'semantic-ui-react';

@inject('store')
@observer
class CapturePlans extends Component {
  selectPlan = (planId) => {
    this.props.store.payments.planSelector(planId);
  }

  formatMoney = (num) => {
    if (!num) {
      return '';
    }
    const numText = num.toString();

    if (numText.length === 1) {
      return 'Free';
    }

    const cent = numText.slice(numText.length - 2);
    const dollar = numText.slice(0, numText.length - 2);
    return `$${dollar}.${cent} per Month`;
  }

  /**
   * planObj is the users current plan
   * selectedPlan is the plan they will choose or upgrade to
   */
  displayCheck = (id) => {
    const { selectedPlan } = this.props.store.payments;
    const { accountStatus } = this.props.store;
    let { planObj } = accountStatus;
    planObj = planObj || {};
    if (id === planObj.id) {
      return (
        <Label color="grey" corner="right">
          <Icon name="pin" />
        </Label>
      );
    }

    if (id === selectedPlan[0]) {
      return (
        <Label color="green" corner="right">
          <Icon name="check" />
        </Label>
      );
    }
    return null;
  }

  createPlanCard = (planObj) => {
    const {
      nickname, amount, metadata, id,
    } = planObj;
    const {
      limit, domains, email, description, hide,
    } = metadata;

    if (hide) {
      return null;
    }

    return (
      <Grid.Column align="center" key={id}>
        <div style={{ marginTop: '10px' }}>
          <Card
            link
            id={id}
            onClick={() => { this.selectPlan(id); }}
          >
            <Card.Header textAlign="center">
              <Divider hidden fitted />
              <Header as="h2">
                {nickname}
              </Header>
            </Card.Header>
            <Card.Content>
              {this.displayCheck(id)}
              {description}
              <Divider hidden fitted />
              {this.formatMoney(amount)}
              <Divider hidden fitted />
              {email === 'true' ? 'Unlock Targeted Email Remarketing ' : 'No Access to Remarketing'}
              <Divider hidden fitted />
              {`Form Tracking on ${domains === '1' ? '1 Domain' : `${domains} Domains`}`}
              <Divider hidden fitted />
              {limit}
            </Card.Content>
          </Card>
        </div>
      </Grid.Column>
    );
  }

  render() {
    const { availablePlans } = this.props.store.payments;
    return (
      <div>
        <Grid>
          <Grid.Row columns={3} divided>
            {
              availablePlans
                .sort((a, b) => a.metadata.order - b.metadata.order)
                .map(planObj => this.createPlanCard(planObj))
            }
            <Grid.Column align="center">
              <div style={{ marginTop: '10px' }}>
                <Card>
                  <Card.Header textAlign="center">
                    <Divider hidden fitted />
                    <Header as="h2">
                      Enterprise Plus
                    </Header>
                  </Card.Header>
                  <Card.Content>
                    Expecting High Volume?
                    <Divider hidden fitted />
                    Contact Sales Directly
                    <Divider hidden fitted />
                    And Get A Customer Quote
                    <Divider hidden fitted />
                    sales@automatedremarketing.com
                  </Card.Content>
                </Card>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default CapturePlans;
