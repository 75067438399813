import _ from 'lodash';
import axios from 'axios';
import {
  action, observable, computed,
} from 'mobx';
import { API_URL } from '../configs';
import { planNames, toastTypes } from '../enums';

class Auth {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.firebase = rootStore.firebase;

    this.firebase.auth().onIdTokenChanged(this.idTokenCallback);
    this.attachInterceptor();
  }

  @observable
  user = {
    /** Derived from fb auth claims */
    clientId: null,
    twoFaType: null,
    role: null,
    /** Derived from fb auth idToken */
    user_id: null,
    email: null,
    email_verified: null,
    phone_number: null,
    /** Derived from client's node */
    company: null,
    displayTour: null,
    domains: null,
    planName: null,
    accountStatus: null,
    planStartDate: null,
    analytics: null,
    features: {},
  };

  @observable
  loStatus = localStorage.getItem('lo_status');

  @observable
  loading = false;

  clientNodeRef = null;
  fbListeners = [];
  authInterceptor = null;
  idToken = null;

  @computed
  get setupStatus() {
    return _.isEmpty(this.user.domains) || _.isEmpty(this.rootStore.forms.formLinks);
  }

  @computed
  get paidPlan() {
    const { planName } = this.user;
    return planName && planName !== planNames.free;
  }

  @action
  reset = () => {
    this.user = {
      clientId: null,
      twoFaType: null,
      role: null,
      user_id: null,
      email: null,
      email_verified: null,
      phone_number: null,
      company: null,
      displayTour: null,
      domains: null,
      planName: null,
      accountStatus: null,
      planStartDate: null,
      analytics: null,
    };

    this.fbListeners = false;
    this.clientNodeRef.off();
    axios.interceptors.request.eject(this.authInterceptor);
    this.authInterceptor = null;
    this.idToken = null;

    this.loading = false;
    this.removeLoStatus();
    this.removeSummaryDataCache();
  }

  @action
  setLoStatus = () => {
    localStorage.setItem('lo_status', 'logged_in');
    this.loStatus = 'logged_in';
  }

  @action
  removeLoStatus = () => {
    localStorage.removeItem('lo_status');
    this.loStatus = null;
  }

  removeSummaryDataCache = () => {
    localStorage.removeItem('summaryData');
    localStorage.removeItem('summaryDataCachedDate');
    localStorage.removeItem('summaryDataTimeSpan');
  }

  attachInterceptor = () => {
    this.authInterceptor = axios.interceptors.request.use((config) => {
      const { headers } = config;
      headers.Authorization = this.idToken;
      return config;
    }, (err) => {
      console.error('interceptorError', err);
    });
  }

  @action
  updateClientNodeData = async (clientId = this.user.clientId) => {
    const snapshot = await this.firebase.database()
      .ref(`clients/${clientId}`)
      .once('value');

    const {
      company, status, displayTour, domains, planName,
      accountStatus, planStartDate, analytics, features,
    } = snapshot.val() || {};

    this.user = {
      ...this.user,
      company,
      status,
      displayTour,
      domains,
      planName,
      accountStatus,
      planStartDate,
      analytics,
      features,
    };
  }

  /** Initial call grabs client node */
  bindClientNodeToUser = (clientNodeRef = this.clientNodeRef) => new Promise((resolve, reject) => {
    let initialCall = false;
    const timeout = setTimeout(() => reject(new Error('Timeout error; exceeded 10s')), 10000);

    clientNodeRef.on('value', (snapshot) => {
      this.user = {
        ...this.user,
        ...snapshot.val() || {},
      };

      if (initialCall === false) {
        initialCall = true;
        resolve(true);
        clearTimeout(timeout);
      }
    });
  });

  @action
  idTokenCallback = async (user) => {
    if (user) {
      this.setLoStatus();
      // Merges user obj with custom idToken claims
      const idTokenResult = await this.firebase.auth().currentUser.getIdTokenResult(true);
      const { claims, token } = idTokenResult;
      this.idToken = token;

      if (claims) {
        this.user = {
          ...this.user,
          ...idTokenResult.claims,
        };
      }

      if (this.clientNodeRef === null) {
        this.clientNodeRef = this.firebase.database().ref(`clients/${claims.clientId}`);
        try {
          await this.bindClientNodeToUser();
        } catch (err) {
          this.rootStore.failure('Failed to get client data. Please try refreshing this page');
        }
        this.rootStore.initAppState();
      }
    } else {
      /** user is no longer logged in */
      this.rootStore.resetAppState();
      this.removeLoStatus();
      this.removeSummaryDataCache();
    }
  }

  @action
  register = async (email, password, userInfo) => {
    this.loading = true;

    const userObj = await this.firebase.auth().createUserWithEmailAndPassword(email, password);

    if (userObj) {
      // Scaffolding API call, which creates the firebase db client node entry
      const { uid } = userObj.user;
      await axios.post(`${API_URL}/api/um/setup`, { uid, userInfo });

      this.setLoStatus();
    }

    this.loading = false;
  }

  @action
  login = async (email, password) => {
    this.loading = true;

    const userObj = await this.firebase.auth().signInWithEmailAndPassword(email, password);

    if (userObj) {
      this.setLoStatus();
    }

    this.loading = false;
  }

  @action
  customLogin = async (idToken) => {
    await this.firebase.auth().signInWithCustomToken(idToken);
  }

  @action
  shopifyLogin = async (qs) => {
    this.loading = true;
    try {
      const { data } = await axios.get(`${API_URL}/api/integrations/Shopify/auth${qs}`);
      this.customLogin(data.idToken);
    } catch (e) {
      this.rootStore.failure('Could not login with Shopify.');
      console.error(e);
    }
    this.loading = false;
  }

  @action
  wixLogin = async (instance) => {
    this.loading = true;
    try {
      const { data } = await axios.get(`${API_URL}/api/integrations/Wix/custom-login?instance=${instance}`);
      await this.customLogin(data.idToken);
    } catch (err) {
      this.signOut();
      this.rootStore.failure('Could not login through Wix Dashboard. Please login or register if you have not done so.');
    }
    this.rootStore.integrations.WiX.linkAccounts(instance);
    this.loading = false;
  }

  @action
  salesforceMarketincCloudLogin = async (code) => {
    this.loading = true;
    try {
      const { data } = await axios.get(`${API_URL}/api/integrations/SalesforceMarketingCloud/verify?code=${code}`);
      await this.customLogin(data.idToken);
    } catch (err) {
      this.rootStore.failure('Could not login through Salesforce Marketing Cloud Dashboard. Please login or register if you have not done so.');
    }
    this.loading = false;
  }

  @action
  sendCustomVerificationEmail = async (email) => {
    this.loading = true;
    try {
      await axios.post(`${API_URL}/api/um/custom-verification-email`, { email });
      this.rootStore.success('Verification email sent!', toastTypes.sendCustomVerificationEmail);
      this.rootStore.clearToast(toastTypes.sendCustomVerificationEmail);
    } catch (e) {
      console.error(e);
      this.rootStore.failure('Could not send verification email.', toastTypes.sendCustomVerificationEmail);
    } finally {
      this.loading = false;
    }
  }

  @action
  sendCustomPasswordResetEmail = async (email) => {
    this.loading = true;
    try {
      await axios.post(`${API_URL}/api/um/custom-pw-reset`, { email });
      this.rootStore.success('Password reset email sent!', toastTypes.sendCustomPasswordResetEmail);
      this.rootStore.clearToast(toastTypes.sendCustomPasswordResetEmail);
    } catch (e) {
      console.error(e);
      this.rootStore.failure('Could not send password reset email.', toastTypes.sendCustomPasswordResetEmail);
    } finally {
      this.loading = false;
    }
  }

  signOut = () => {
    this.firebase.auth().signOut();
    this.removeLoStatus();
    this.removeSummaryDataCache();
  }

  grabAllClaims = async (forceRefresh = true) => {
    // By default, calling this function will refresh the claims as well.
    const idToken = await this.firebase.auth().currentUser.getIdTokenResult(forceRefresh);
    return idToken.claims;
  }

  // 2FA
  sendCode = async () => {
    try {
      await axios.post(`${API_URL}/api/auth/send-2fa-code`);
      this.rootStore.success('Code sent!');
    } catch (err) {
      console.error(err);
    }
  }

  editTwoFa = async (twoFaType, twoFaCode, phoneNumber) => {
    this.loading = true;

    try {
      await axios.post(`${API_URL}/api/auth/edit-2fa`, {
        twoFaType,
        twoFaCode,
        phoneNumber,
      });

      await this.grabAllClaims();
      return { success: true };
    } catch (err) {
      return {
        error: `Couldn't edit 2FA ${twoFaType}`,
      };
    } finally {
      this.loading = false;
    }
  }

  verifyCode = async (twoFaCode) => {
    this.loading = true;
    try {
      const res = await axios.post(`${API_URL}/api/auth/verify`, {
        twoFaCode,
      });

      const { token: customToken } = res.data;
      if (customToken) {
        await this.rootStore.firebase.auth().signInWithCustomToken(customToken);
        await this.rootStore.firebase.auth().currentUser.getIdTokenResult(true);
        this.rootStore.success('Successfully verified code.', toastTypes.verifyCode);
        this.rootStore.clearToast(toastTypes.verifyCode);
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (e) {
      this.rootStore.failure('Could not verify code.', toastTypes.verifyCode);
      console.error(e);
      return { success: false };
    } finally {
      this.loading = false;
    }
  }

  updateClaims = async (propertiesToUpdate, twoFaCode) => {
    try {
      await axios.post(`${API_URL}/api/auth/update-user`, {
        ...propertiesToUpdate,
        twoFaCode,
      });

      return this.grabAllClaims();
    } catch (err) {
      console.error(err);
      return {
        error: 'Couldn\'t update the properties.',
      };
    }
  }
}

export default Auth;
