import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import {
  Header, Segment, List, Form,
} from 'semantic-ui-react';

@inject('store')
@observer
class Setup extends Component {
  @action
  handleChange = (e, { name, value }) => {
    this.props.store.integrations.OMI366.configs[name] = value;
  }

  @action
  enableToggle = () => {
    this.props.store.integrations.OMI366.configs.enabled = !this.props.store.integrations.OMI366.configs.enabled;
  }

  render() {
    return (
      <>
        <Header as="h3" attached="top">
          Setup
        </Header>
        <Segment attached>
          <List ordered>
            <List.Item>
              First, make an account with 366
              {' '}
              <a
                href="https://366.io/SignUp?Partner=Mov052"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </List.Item>
            <List.Item>
              Then, go to the
              {' '}
              <a
                href="https://366.io/CompanyProfile/CompanyProfile"
                target="_blank"
                rel="noopener noreferrer"
              >
                Company Profile page
              </a>
              {' '}
              to find the App Key and the App Secret Key.
            </List.Item>
            <List.Item>
              Copy and paste the App Key and the App Secret below, and click update.
            </List.Item>
            <List.Item>
              Your future captures will be created as contacts on 366 and you can now manage your email campaigns through their dashboard.
            </List.Item>
          </List>
        </Segment>

        <Header as="h3" attached="top">
          Manage Integration
        </Header>

        <Segment attached>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="App Key"
                name="appKey"
                disabled={this.props.store.integrations.OMI366.loading}
                value={this.props.store.integrations.OMI366.configs.appKey}
                onChange={this.handleChange}
              />

              <Form.Input
                fluid
                label="App Secret"
                name="appSecret"
                disabled={this.props.store.integrations.OMI366.loading}
                value={this.props.store.integrations.OMI366.configs.appSecret}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Checkbox
              label="Enable"
              onChange={this.enableToggle}
              checked={this.props.store.integrations.OMI366.configs.enabled}
            />

            <Form.Button
              positive
              disabled={this.props.store.integrations.OMI366.loading}
              loading={this.props.store.integrations.OMI366.loading}
              onClick={() => this.props.store.integrations.OMI366.updateConfigs('OMI366')}
            >
              Update
            </Form.Button>
          </Form>
        </Segment>
      </>
    );
  }
}

export default Setup;
