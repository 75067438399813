import _ from 'lodash';
import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class Typeform {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    enabled: false,
  };

  @observable
  loading = {
    configs: false,
    getTokens: false,
    forms: false,
    updateConfigs: false,
  };

  @observable
  formsLoading = new Set();

  @observable
  formData = {};

  @observable
  activePage = 1

  @observable
  search = ''

  @action
  updateActivePage(activePage) {
    this.activePage = activePage;
    const queryParams = {
      page: activePage,
      search: this.search,
    };
    this.retrieveForms(queryParams);
  }

  @action
  updateSearch(searchQuery) {
    this.search = searchQuery;
  }

  @action
  handleSearch() {
    this.retrieveForms({
      search: this.search,
    });
  }

  @action
  updateFormContainerId = (e, { name, value }) => {
    const targetForm = this.formData.forms.find(form => form.id === name);
    _.set(targetForm, 'settings.google_tag_manager', value);
  }

  @action
  reset = () => {
    this.configs = {};
    this.loading = _.mapValues(this.loading, () => false);
  }


  getAuthUrl = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/Typeform/auth-url`);
      if (res.data.success) {
        window.open(res.data.authUrl, '_blank');
        return res.data.authUrl;
      }
      throw new Error('Failed to get auth url...');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  @action
  getConfigs = async () => {
    try {
      this.loading.configs = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Typeform/config`);

      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }

      if (this.configs.enabled && !this.loading.retrieveForms) {
        this.retrieveForms({ search: this.search, page: this.activePage });
      }
    } catch (e) {
      console.error(e);
    }

    this.loading.configs = false;
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading.configs = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/Typeform/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.typeFormUpdateConfigs);
        this.rootStore.clearToast(toastTypes.typeFormUpdateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.typeFormUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading.configs = false;
    }
  }

  getTokens = async (code) => {
    try {
      this.loading.getTokens = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Typeform/get-tokens?code=${code}`);

      if (data.success) {
        this.rootStore.success('Typeform integration successful.', toastTypes.typeFormGetTokens);
        this.rootStore.clearToast(toastTypes.typeFormGetTokens);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Typeform integration unsuccessful.', toastTypes.typeFormGetTokens);
    } finally {
      this.loading.getTokens = false;
    }
  }

  @action
  retrieveForms = async (queryParams) => {
    try {
      this.loading.retrieveForms = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Typeform/retrieve-forms`, { params: queryParams });

      if (data.success) {
        this.formData = data.forms;
      }
    } catch (e) {
      this.rootStore.failure('Failed to retrieve forms...');
    } finally {
      this.loading.retrieveForms = false;
    }
  }

  @action
  approveForm = async (formId, gtmId, typeformUrl) => {
    try {
      this.formsLoading.add(formId);
      if (!gtmId) {
        throw new Error();
      }

      const { data: connectRes } = await axios.post(`${API_URL}/api/integrations/Typeform/forms/${formId}/gtm-container/${gtmId}`);
      const { data: approveRes } = await axios.post(`${API_URL}/api/integrations/Typeform/approve-form?formId=${formId}`);

      if (connectRes.success && approveRes.success) {
        axios.post(`${API_URL}/api/analytics/create`, { urls: [typeformUrl] });
        this.rootStore.success('Form approved!', toastTypes.approveForm);
        this.rootStore.clearToast(toastTypes.approveForm);
        this.retrieveForms({ search: this.search, page: this.activePage });
        this.rootStore.forms.getFormLinks();
      }
    } catch (e) {
      this.rootStore.failure('Form approval unsuccessful.', toastTypes.approveForm);
    } finally {
      this.formsLoading.delete(formId);
    }
  }
}

export default Typeform;
