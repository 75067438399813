import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Menu, Icon } from 'semantic-ui-react';
import configs from '../configs';

import Setup from './Setup';

@inject('store')
@observer
class SalesforceMarketingCloud extends Component {
  state = {
    activeTab: 'Setup',
  }

  componentDidMount() {
    this.props.store.integrations.SalesforceMarketingCloud.getConfigs();
    const { code, partner, tssd } = this.props.store.initQueryParams;
    if (partner === 'salesforcemarketingcloud' && code) {
      this.props.store.integrations.SalesforceMarketingCloud.verifyCode(code, tssd);
    }
  }

  handleMenuClick = (e, { name }) => {
    this.setState({ activeTab: name });
  }

  displayMenuPanels = () => {
    switch (this.state.activeTab) {
      case 'Setup':
        return <Setup />;
      default:
        return <div>Something went wrong...</div>;
    }
  }

  render() {
    return (
      <>
        <Menu>
          <Menu.Item onClick={() => this.props.history.push('/integrations')}>
            <Icon name="arrow left" />
            <Header as="h3" content={configs.SalesforceMarketingCloud.displayName} style={{ marginTop: 0 }} />
          </Menu.Item>

          <Menu.Item
            name="Setup"
            content="Setup"
            onClick={this.handleMenuClick}
            active={this.state.activeTab === 'Setup'}
          />
        </Menu>
        {this.displayMenuPanels()}
      </>
    );
  }
}

export default SalesforceMarketingCloud;
