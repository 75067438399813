import axios from 'axios';
import { action, observable } from 'mobx';
import { API_URL } from '../configs';

class Notifications {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  notifications = {};

  @action
  reset = () => {
    this.notifications = {};
  }

  @action
  attachNotificationsListener = async () => {
    const { clientId } = this.rootStore.auth.user;
    this.rootStore.firebase.database()
      .ref(`clientNotifications/${clientId}`).on('value', (snapshot) => {
        this.notifications = snapshot.val() ? snapshot.val() : {};
      });
  }

  @action
  deleteNotification = async (notificationId) => {
    try {
      await axios.delete(`${API_URL}/api/notifications/${notificationId}`);
    } catch (err) {
      console.error(err);
    }
  }
}

export default Notifications;
