import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Header, Segment, Form, Grid, Divider, Button, Icon,
} from 'semantic-ui-react';
import CardInput from './CardInput';
import CapturePlans from './CapturePlans';

@inject('store')
@observer
class PaymentSetup extends Component {
  toogleAnnual = () => {
    this.props.store.payments.selectedPlans = {};
  }

  render() {
    const {
      availablePlans, selectedPlan, promoValue, promoCode, loading,
      appliedPromoCode, email, total, inputHandler,
      checkPayment, verifyPromoCode,
    } = this.props.store.payments;

    return (
      <Fragment>
        <Segment>
          <CapturePlans />
        </Segment>
        <Divider hidden />
        <Header id="TabTitle" as="h2">
          Payment Details
        </Header>
        <Segment color="green">
          <Grid divided stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header size="tiny">Billing Email</Header>
                <Form.Input
                  fluid
                  value={email}
                  onChange={inputHandler}
                  name="email"
                />

                <Divider hidden />
                <Header size="tiny">Payment</Header>
                <CardInput />
                <Divider hidden />

                <Header size="tiny">Promo Code</Header>
                <Form>
                  <Form.Field inline className="inlineInputButton">
                    <Form.Input
                      fluid
                      name="promoCode"
                      value={promoCode}
                      onChange={inputHandler}
                    />
                    <span>
                      <Form.Button onClick={verifyPromoCode}>
                        Apply
                      </Form.Button>
                    </span>
                  </Form.Field>
                </Form>

              </Grid.Column>

              <Grid.Column>
                <Divider horizontal>
                  <Header size="tiny">Services</Header>
                </Divider>
                <Grid columns={2}>
                  {
                    Object.keys(selectedPlan).map(plan => (
                      <Grid.Row key={plan} color={plan === 'CaptureMetered' ? 'grey' : null}>
                        <Grid.Column>
                          {availablePlans.find(availablePlan => availablePlan.id === selectedPlan[plan]).nickname}
                          {plan === 'captureMetered' ? (
                            <span data-tooltip="This amount is calculated by the amount of captures per month and billed at the end of your billing period.">
                              {' '}
                              <Icon name="info circle" />
                            </span>
                          ) : ''}

                          {plan === 'recoverMetered' ? (
                            <span data-tooltip="This amount is calculated by adding up all emails sent at the end of your billing period. $99 per 10,000 emails">
                              {' '}
                              <Icon name="info circle" />
                            </span>
                          ) : ''}

                        </Grid.Column>
                        <Grid.Column textAlign="right">
                          {`$${(availablePlans.find(availablePlan => availablePlan.id === selectedPlan[plan]).amount / 100) || 0} USD`}

                        </Grid.Column>
                      </Grid.Row>
                    ))
                  }
                  <Grid.Row>
                    <Grid.Column />
                  </Grid.Row>
                </Grid>

                <Divider horizontal>
                  <Header size="tiny">Total</Header>
                </Divider>

                <Grid columns={2}>
                  {
                    Object.keys(appliedPromoCode).length > 0
                    && (
                      <Grid.Row>
                        <Grid.Column>
                          {appliedPromoCode.message}
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                          {`-${promoValue} USD`}
                        </Grid.Column>
                      </Grid.Row>
                    )
                  }
                  <Grid.Row>
                    <Grid.Column>
                      Payment Today
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      {`$${total} USD`}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider hidden />

          <div className="justifyRight">
            <Button
              icon
              labelPosition="left"
              positive
              onClick={checkPayment}
              loading={loading}
              disabled={loading}
            >
              <Icon name="check" />
              Confirm Payment
            </Button>
          </div>
        </Segment>
      </Fragment>
    );
  }
}

export default PaymentSetup;
