import React from 'react';
import { Responsive } from 'semantic-ui-react';
import './SideBanner.css';

export default () => (
  <Responsive as="div" id="SideBanner" minWidth={768}>
    <div className="bannerBackground">
      <div className="bannerImage" />
    </div>
  </Responsive>
);
