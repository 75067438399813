import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Grid, Segment, Table, Icon, Button, Message,
} from 'semantic-ui-react';

const RECOVER_SETUP_DOCS_LINK = 'https://docs.movology.com/docs/setting-up-recover/';
@inject('store')
@observer
class EmailSettings extends Component {
  createDomainStatusIcon(status = this.props.store.emailManagement.activeEmailDomain.status) {
    switch (status) {
      case 'active':
      case 'verified':
        return <Icon name="checkmark" color="green" />;
      case 'unverified':
        return <Icon name="cancel" color="red" />;
      default:
        return null;
    }
  }

  createVerifyButton(status = this.props.store.emailManagement.activeEmailDomain.status) {
    const loading = this.props.store.emailManagement.loading.verifyRecords;
    switch (status) {
      case 'active':
      case 'unverified':
        return (
          <Button
            size="tiny"
            positive
            style={{ marginLeft: '1em' }}
            onClick={this.props.store.emailManagement.verifyRecords}
            loading={loading}
            // tooltip props break the loader...
            {...(loading
              ? {}
              : { 'data-tooltip': 'Verify that NS Records have been installed', 'data-position': 'right center' }
            )}
          >
            Verify Records
          </Button>
        );
      default:
        return null;
    }
  }

  createRecordsTable(records = this.props.store.emailManagement.activeEmailDomain.dnsRecords) {
    return (
      <div className="sideScrollingTable">
        <Table unstackable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan="4">DNS Records</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>TTL</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(records, record => (
              <Table.Row key={`${record.type}-${record.name}-${record.value}`} textAlign="center">
                <Table.Cell>{record.type}</Table.Cell>
                <Table.Cell>{record.name}</Table.Cell>
                <Table.Cell>{record.value}</Table.Cell>
                <Table.Cell>{record.TTL}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }

  render() {
    const { activeEmailDomain, activeEmailDomainName } = this.props.store.emailManagement;

    return (
      <Fragment>
        {_.size(this.props.store.emailManagement.emailDomains)
          ? null
          : (
            <Fragment>
              <Message warning floating icon>
                <Icon name="bullhorn" />
                <Message.Content>
                  <Message.Header>
                    You haven&apos;t added a domain yet! Hit the button on the right to add one now.
                    <Button icon labelPosition="left" positive onClick={this.props.handleModalOpen} style={{ marginLeft: '1em' }}>
                      <Icon name="add" />
                    Add Domain
                    </Button>
                  </Message.Header>
                </Message.Content>
              </Message>
            </Fragment>
          )}
        <Message
          floating
          info
          icon
        >
          <Icon name="bullhorn" />
          <Message.Content>
            <Message.Header>
              Recover setup instructions can be found
              {' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={RECOVER_SETUP_DOCS_LINK}
              >
              here.
              </a>
            </Message.Header>
          </Message.Content>
        </Message>
        <Segment>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
              Email Domain
              </Grid.Column>
              <Grid.Column>
                {activeEmailDomainName}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
              Recover Plan
              </Grid.Column>
              <Grid.Column className="capitalize">
                {this.props.store.auth.user.status}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
              Domain Status
              </Grid.Column>
              <Grid.Column className="capitalize">
                {this.createDomainStatusIcon(activeEmailDomain.status)}
                {activeEmailDomain.status}
                {this.createVerifyButton(activeEmailDomain.status)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {this.createRecordsTable(activeEmailDomain.dnsRecords)}
      </Fragment>
    );
  }
}

export default EmailSettings;
