import React, { Component, Fragment } from 'react';
import {
  Modal, Button, Input, Form,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class ConfirmationModal extends Component {
  state = {
    phone: '',
    email: '',
  }

  modalInputHandler = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  validatePhoneNumber = () => {
    if (this.state.phone.trim().length !== 10 || +this.state.phone.trim() <= 0) {
      this.props.store.failure('Phone number must be 10 digits.');
    } else if (this.props.phoneNumber !== this.state.phone) {
      this.props.store.failure('Phone numbers do not match.');
    } else {
      return true;
    }

    return false;
  }

  validateEmail = () => {
    if (this.props.email !== this.state.email) {
      this.props.store.failure('Email addresses do not match.');

      return false;
    } else {
      return true;
    }
  }

  fromDisabled = () => {
    const { twoFaType } = this.props;

    if (twoFaType === 'text' || twoFaType === '') {
      return (
        <Fragment>
          <Modal.Header as="h4">
            Please confirm your phone number.
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>
                  Phone Number
                </label>
                <Input
                  name="phone"
                  value={this.state.phone}
                  onChange={this.modalInputHandler}
                  placeholder="1234567890"
                  label="+1"
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button
              loading={this.props.store.auth.loading}
              onClick={() => {
                if (this.validatePhoneNumber()) {
                  this.props.confirm();
                }
              }}
              primary
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Fragment>
      );
    } else if (twoFaType === 'email') {
      return (
        <Fragment>
          <Modal.Header as="h4">
            Please confirm your email address.
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>
                  Email Address
                </label>
                <Input
                  name="email"
                  value={this.state.email}
                  placeholder="name@email.com"
                  onChange={this.modalInputHandler}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button
              loading={this.props.store.auth.loading}
              onClick={() => {
                if (this.validateEmail()) {
                  this.props.confirm();
                }
              }}
              primary
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Fragment>
      );
    }

    return (
      <div>Error</div>
    );
  }

  fromEnabled = () => {
    const { confirm, formInput, twoFaCode } = this.props;

    return (
      <Fragment>
        <Modal.Header as="h4">
          Please reenter your latest 2FA code.
        </Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Code
                {': '}
              </label>
              <Input
                name="twoFaCode"
                value={twoFaCode}
                onChange={formInput}
              />
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button
            seconary="true"
            loading={this.props.store.auth.loading}
            onClick={this.props.store.auth.sendCode}
          >
            Send Again
          </Button>
          <Button
            primary
            loading={this.props.store.auth.loading}
            onClick={confirm}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Fragment>
    );
  }

  success = () => (
    <Fragment>
      <Modal.Header as="h4">
        Success!.
      </Modal.Header>

      <Modal.Content>
        Your 2FA settings have been updated.
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          onClick={this.props.onClose}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Fragment>
  )

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        closeOnDimmerClick={false}
      >
        {
          !this.props.success
            ? this.props.original2FaType === ''
              ? this.fromDisabled()
              : this.fromEnabled()
            : this.success()
        }
      </Modal>
    );
  }
}

export default ConfirmationModal;
