import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Responsive } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';

import { userRoleType, planNames } from './enums';
import './App.css';

import DashboardLayout from './components/DashboardLayout';
import Dashboard from './components/DashboardTabs/Dashboard';
import Reporting from './components/DashboardTabs/Reporting';
import Forms from './components/DashboardTabs/Forms';
import FAQ from './components/DashboardTabs/FAQ';
import Admin from './components/DashboardTabs/Admin';
import Integrations from './components/DashboardTabs/Integrations';
import EditForms from './components/DashboardTabs/Forms/EditForms/index';

import Payments from './components/Payments';
import Setup from './components/Setup';
import Login from './components/LoginPage';
import TwoFAPage from './components/TwoFAPage';
import EmailVerificationPage from './components/EmailVerificationPage';
import Settings from './components/Settings';
import Tour from './components/Tour';
import Recover from './components/DashboardTabs/Recover';
import AMP from './components/DashboardTabs/AMP';

@inject('store')
@observer
class App extends Component {
  /** components without icon property will not appear on side nav */
  publicRouteConfigs = [
    {
      name: 'Login',
      path: '/',
      component: Login,
    },
  ]

  adminRouteConfigs = [
    {
      name: 'Admin',
      path: '/admin',
      icon: 'shield',
      component: Admin,
    },
  ]

  userRouteConfigs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: 'chart line',
      component: Dashboard,
    },
    {
      name: 'Setup',
      path: '/setup',
      icon: 'sliders horizontal',
      component: Setup,
    },
    {
      name: 'Forms',
      path: '/forms',
      icon: 'clipboard outline',
      component: Forms,
      exact: true,
    },
    {
      name: 'EditForms',
      path: '/forms/:safePath',
      component: EditForms,
      exact: true,
    },
    {
      name: 'FAQ',
      path: '/faq',
      icon: 'info',
      component: FAQ,
    },
    {
      name: 'Integrations',
      path: '/integrations/:partner?',
      icon: 'map outline',
      component: Integrations,
    },
    {
      name: 'Payments',
      path: '/payments',
      icon: 'credit card outline',
      component: Payments,
    },
    {
      name: 'Settings',
      path: '/settings',
      component: Settings,
    },
    {
      name: 'Reporting',
      path: '/reporting',
      icon: 'thumbtack',
      component: Reporting,
    },
  ]

  paidRouteConfigs = [
    {
      name: 'Recover',
      path: '/recover',
      icon: 'mail outline',
      component: Recover,
    },
    {
      name: 'AMP',
      path: '/amp',
      icon: 'paper plane outline',
      component: AMP,
    },
  ]

  componentDidUpdate = () => {
    this.reroute();
  }
  componentDidMount() {
    this.props.history.listen(() => {
      this.props.store.clearAllToasts();
    });
  }

  reroute = () => {
    const { history, history: { location: { pathname } } } = this.props;
    const { user } = this.props.store.auth;

    if (_.isEmpty(user)) {
      if (pathname !== '/') {
        history.push('/');
      }
    } else if (!user.email_verified) {
      if (pathname !== '/unverifiedEmail') {
        history.push('/unverifiedEmail');
      }
    } else if (user.twoFaType && !user.verified) {
      if (pathname !== '/authenticate') {
        history.push('/authenticate');
      }
    } else if (pathname === '/'
      || pathname === '/unverifiedEmail'
      || pathname === '/authenticate') {
      if (user.role === userRoleType.superadmin) {
        history.push('/admin');
      } else {
        history.push('/dashboard');
      }
    }
  }

  get publicRoutes() {
    return this.publicRouteConfigs.map(routeConfig => (
      <Route {...routeConfig} key={routeConfig.name} />
    ));
  }

  unverifiedEmailRoutes = () => (
    <Route path="/unverifiedEmail" component={EmailVerificationPage} />
  )

  unverified2FARoutes = () => (
    <Route path="/authenticate" component={TwoFAPage} />
  )

  get privateRoutes() {
    const routeConfigs = [
      {
        name: 'Home',
        path: '/',
        exact: true,
      },
      ...this.userRouteConfigs,
    ];

    if (this.props.store.auth.user.role === userRoleType.superadmin) {
      routeConfigs.unshift(...this.adminRouteConfigs);
    }

    if (this.props.store.auth.user.planName !== planNames.free) {
      routeConfigs.push(...this.paidRouteConfigs);
    }

    if (['wix', 'shopify'].includes(this.props.store.initQueryParams.partner)) {
      _.remove(routeConfigs, config => config.name === 'Payments');
    }

    const routes = routeConfigs.map(config => (<Route {...config} key={config.name} />));
    return (
      <DashboardLayout routeConfigs={routeConfigs}>
        { routes }
        <Tour />
      </DashboardLayout>
    );
  }

  get availableRoutes() {
    const { user } = this.props.store.auth;

    if (user.user_id === null) {
      return this.publicRoutes;
    } else if (!user.email_verified) {
      return this.unverifiedEmailRoutes();
    } else if (user.twoFaType && !user.verified) {
      return this.unverified2FARoutes();
    } else {
      return this.privateRoutes;
    }
  }

  render() {
    return (
      <Responsive
        className="App"
        fireOnMount
        onUpdate={this.props.store.ui.onWindowResize}
      >
        {this.availableRoutes}
        <ToastContainer />
      </Responsive>
    );
  }
}

export default withRouter(App);
