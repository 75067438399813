import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Form, Grid, Header, Message, Segment,
} from 'semantic-ui-react';
import { colors } from '../../enums';
import './TwoFAPage.css';

@inject('store')
@observer
class TwoFAPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
  }

  formInputHandler = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  formSubmitHandler = () => {
    this.props.store.auth.verifyCode(this.state.code);
  }

  sendCode = async () => {
    await this.props.store.auth.sendCode();
  }

  render() {
    const { code } = this.state;

    return (
      <div className="2fa-form">
        <style>
          {`
          body > div,
          body > div > div,
          body > div > div > div.2fa-form {
            height: 100%;
          }
        `}
        </style>
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              Please enter your 2FA code
            </Header>
            <Form size="large" onSubmit={this.formSubmitHandler}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  name="code"
                  value={code}
                  onChange={this.formInputHandler}
                  placeholder="2FA Code"
                />
                <Button
                  fluid
                  size="large"
                  style={{ backgroundColor: colors.primary }}
                  disabled={this.props.store.auth.loading}
                  loading={this.props.store.auth.loading}
                  content="Submit"
                />
              </Segment>
            </Form>
            <Message>
              <div>
                Lost the code?
                {' '}
                <span className="linkStyling" onClick={this.sendCode} onKeyDown={this.sendCode} role="button" tabIndex={0}>
                  Resend
                </span>
              </div>
              <div>
                Log in with a
                {' '}
                {' '}
                <span className="linkStyling" onClick={this.props.store.auth.signOut} onKeyDown={this.props.store.auth.signOut} role="button" tabIndex={0}>different account.</span>
              </div>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default TwoFAPage;
