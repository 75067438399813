export default {
  OMI366: {
    key: 'OMI366',
    displayName: '366.io',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2F366Degrees.png?alt=media&token=b5eb321e-82f6-49e5-816c-c75c81c5feea',
    desc: 'Automate your emailing campaign',
    status: 'Ready',
  },
  HubSpot: {
    key: 'HubSpot',
    displayName: 'HubSpot',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FHSLogo_color.svg?alt=media&token=1cb99cad-13e0-48fe-b656-4fe4aa3539e5',
    desc: 'Marketing, Sales, Customer Service, and CRM',
    status: 'Ready',
  },
  Salesforce: {
    key: 'Salesforce',
    displayName: 'Salesforce',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FSalesforce_Logo%20(1).png?alt=media&token=e3c1fbd4-1146-4b10-bed3-da8e6a1ee5e8',
    desc: 'Salesforce is a customer relationship management solution',
    status: 'Ready',
  },
  // WiX: {
  //   key: 'WiX',
  //   displayName: 'WiX',
  //   logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FwixLogo.png?alt=media&token=de5dca2a-0845-41b3-ad77-59b68a55e30a',
  //   desc: 'Web Development Platform',
  //   status: 'Coming Soon',
  // },
  Shopify: {
    key: 'Shopify',
    displayName: 'Shopify',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2Fshopify-logomark-logo.png?alt=media&token=2a76b012-92cf-4d28-a1f5-77975aa73069',
    desc: 'Leading Online Marketplace',
    status: 'Ready',
  },
  Mailchimp: {
    key: 'Mailchimp',
    displayName: 'Mailchimp',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FMC_Logo.jpg?alt=media&token=1174615e-4ecb-4bde-8e20-73c5409cfd5a',
    desc: 'All-in-One Marketing Platform',
    status: 'Ready',
  },
  GoogleAdwords: {
    key: 'GoogleAdwords',
    displayName: 'Google Adwords',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FGoogleAds.png?alt=media&token=cd634729-6ddd-4b54-a665-23ef3304a0d0',
    desc: 'Online Advertising Platform',
    status: 'Ready',
  },
  SalesforceMarketingCloud: {
    key: 'SalesforceMarketingCloud',
    displayName: 'Salesforce Marketing Cloud',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FSf-marketingcloud-logo.png?alt=media&token=6120bf7f-f945-4eb0-a202-5b51d6d77281',
    desc: 'Salesforce has a thing called marketing cloud. It is cool!',
    status: 'Ready',
  },
  GoogleTagManager: {
    key: 'GoogleTagManager',
    displayName: 'Google Tag Manager',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2Fgtm_logo.png?alt=media&token=dec674fe-eeeb-4135-8e69-287068c47bae',
    desc: 'Google Tag Management System',
    status: 'Ready',
  },
  Typeform: {
    key: 'Typeform',
    displayName: 'Typeform',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2Ftypeform_logo.png?alt=media&token=669dfe7e-05d9-4086-ac83-dd3066852f19',
    desc: 'Easy-to-use tool for collecting data, feedback, and more.',
    status: 'Ready',
  },
  Pipedrive: {
    key: 'Pipedrive',
    displayName: 'Pipedrive',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2Fpipedrive_logo_full.svg?alt=media&token=b1a10187-c75f-4e14-a601-eb1761bcbec9',
    desc: 'Easy-to-use, #1 user-rated CRM tool.',
    status: 'Integration in Progress',
  },
  Blitz: {
    key: 'Blitz',
    displayName: 'Blitz',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FBlitz%20Sales%20Software%20Logo%20-%20Branding%20Color.png?alt=media&token=72195158-b921-4028-b206-941509c2d7b4',
    desc: 'Web-based CRM that automate sales and marketing follow-up.',
    status: 'Ready',
  },
  Fanbridge: {
    key: 'Fanbridge',
    displayName: 'Fanbridge',
    logo: 'https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2FFanbridge%20-%20Full%20Logo.png?alt=media&token=9aad8681-0887-49f8-bd94-14e62c3673bb',
    desc: 'Fan Relationship Management (FRM) platform that merges email and social networking with direct-to-fan tools.',
    status: 'Ready',
  },
};
