import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import {
  Header, Segment, List, Form,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

@inject('store')
@observer
class Setup extends Component {
  @action
  enableToggle = () => {
    this.props.store.integrations.HubSpot.configs.enabled = !this.props.store.integrations.HubSpot.configs.enabled;
  }

  render() {
    return (
      <>
        <Header as="h3" attached="top">
          Setup
        </Header>

        <Segment attached>
          <List ordered>
            <List.Item>
              Make a HubSpot account
              {' '}
              <a href="https://app.hubspot.com/signup-v2/marketing/step/join-hubspot-account" target="_blank" rel="noopener noreferrer">here</a>
              {' '}
              if you don’t have one already.
            </List.Item>
            <List.Item>
              Click
              {' '}
              <span
                className="linkStyling"
                onClick={this.props.store.integrations.HubSpot.getAuthUrl}
              >
                here
              </span>
              {' '}
              to grant Movology’s Capture application necessary permissions.
            </List.Item>
            <List.Item>
              After authorizing our application, future captures will be added as HubSpot contacts.
            </List.Item>
          </List>
        </Segment>

        <Header as="h3" attached="top">
          Manage Integration
        </Header>

        <Segment attached>
          <Form>
            <Form.Checkbox
              label="Enable"
              onChange={this.enableToggle}
              checked={this.props.store.integrations.HubSpot.configs.enabled}
            />

            <Form.Button
              positive
              disabled={this.props.store.integrations.HubSpot.loading}
              loading={this.props.store.integrations.HubSpot.loading}
              onClick={() => this.props.store.integrations.HubSpot.updateConfigs('HubSpot')}
            >
              Update
            </Form.Button>
          </Form>
        </Segment>
      </>
    );
  }
}

export default withRouter(Setup);
