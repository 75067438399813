/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Pagination } from 'semantic-ui-react';

@inject('store')
@observer
class TypeformPagination extends Component {
  typeform = this.props.store.integrations.Typeform

  handlePageChange = (e, { activePage }) => this.typeform.updateActivePage(activePage);

  render() {
    return (
      <Pagination
        activePage={this.typeform.activePage}
        onPageChange={this.handlePageChange}
        totalPages={this.typeform.formData.page_count || 0}
      />
    );
  }
}

export default TypeformPagination;
