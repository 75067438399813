import _ from 'lodash';
import React, { Component } from 'react';
import {
  Button, Segment, Form,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import AceEditor from 'react-ace/';
import beautify from 'js-beautify';

import 'brace/mode/handlebars';
import 'brace/snippets/handlebars';
import 'brace/theme/monokai';
import 'brace/ext/language_tools';

const FormInput = props => (<Form.Input {..._.pickBy(props, val => val !== undefined)} />);

const FormGroups = ({ fields, perRow = 2 }) => _.chunk(fields, perRow).map(chunk => (
  <Form.Group widths="equal" key={chunk.map(field => field.name).join('-')}>
    {chunk.map(props => (<FormInput {...props} />))}
  </Form.Group>
));

@inject('store')
@observer
class TemplateDropdown extends Component {
  constructor(props) {
    super(props);
    const { template = {} } = this.props;

    this.originalTemplate = beautify.html(
      template.template,
      {
        indent_size: 2,
        preserve_newlines: true,
        max_preserve_newlines: 1,
        indent_handlebars: true,
        indent_inner_html: true,
        indent_with_tabs: false,
      },
    );

    this.static = {
      name: template.name,
      versionTag: template.tag,
      createdAt: template.createdAt,
      active: template.active,
    };

    this.state = {
      description: template.description,
      comment: template.comment,
      template: this.originalTemplate,
    };
  }

  get fieldConfigs() {
    return [
      ..._.map(this.static, (val, key) => ({
        key,
        name: key,
        placeholder: _.isBoolean(val) ? val.toString() : val,
        label: _.startCase(key),
        readOnly: true,
        'data-tooltip': 'Read only',
        'data-position': 'top right',
      })),
      ..._.map(
        _.omit(this.state, 'template'),
        (val, key) => ({
          key,
          name: key,
          label: _.startCase(key),
          value: val,
          onChange: this.onInputChange,
        }),
      ),
    ];
  }

  undoEditorChanges = () => {
    this.setState({
      template: this.originalTemplate,
    });
  }

  onInputChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  onEditorChange = (newValue) => {
    this.setState({
      template: newValue,
    });
  }

  render() {
    return (
      <Form>
        <FormGroups fields={this.fieldConfigs} perRow={2} />
        <Segment>
          <strong>HTML</strong>
          <AceEditor
            mode="handlebars"
            theme="monokai"
            name="html"
            value={this.state.template}
            onChange={this.onEditorChange}
            fontSize={13}
            tabSize={2}
            enableBasicAutocompletion
            highlightActiveLine
            showPrintMargin={false}
            width="100%"
            editorProps={{ $blockScrolling: true }}
          />
        </Segment>
        <Button
          icon="backward"
          content="Undo HTML Changes"
          labelPosition="left"
          onClick={this.undoEditorChanges}
        />
        <Button
          positive
          icon="edit"
          content="Update Template"
          labelPosition="left"
          onClick={async () => {
            await this.props.store.emailManagement.updateTemplate({
              ...this.static,
              ...this.state,
            });
          }}
          loading={this.props.store.emailManagement.loading.updateTemplate}
          disabled={this.props.store.emailManagement.loading.updateTemplate}
        />
      </Form>
    );
  }
}

export default TemplateDropdown;
