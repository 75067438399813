import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Modal, Button, Image, Icon,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

@inject('store')
@observer
class Tour extends Component {
  nextButtonHandler = () => {
    if (this.props.store.tour.currentTour.nextUrl) {
      this.props.history.push(this.props.store.tour.currentTour.nextUrl);
    }

    if (this.props.store.tour.tourIndex === this.props.store.tour.tourArray.length - 1) {
      this.props.store.tour.endTour();
    } else {
      this.props.store.tour.nextStep();
    }
  }

  backButtonHandler = () => {
    if (this.props.store.tour.tourIndex) {
      this.props.store.tour.backStep();
      this.props.history.goBack();
    }
  }

  render() {
    let backBtn;
    if (this.props.store.tour.tourIndex !== 0) {
      backBtn = (
        <Button
          icon="left arrow"
          labelPosition="left"
          onClick={this.backButtonHandler}
          content="Back"
          primary
        />
      );
    }

    const { currentTour } = this.props.store.tour;
    return (
      <Modal
        size="tiny"
        open={this.props.store.tour.displayTour}
        onClose={this.props.store.tour.endTour}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {currentTour.header}
        </Modal.Header>
        <Modal.Content image>
          {currentTour.message}
          {currentTour.image ? <Image wrapped size="medium" src={currentTour.image} /> : null}
        </Modal.Content>
        <Modal.Actions>
          <Button as="a" target="_blank" href="mailto:info@automatedremarketing.com">
            Email Us
          </Button>
          {backBtn}
          <Button onClick={this.nextButtonHandler} positive icon labelPosition="right">
            {
              this.props.store.tour.tourIndex === this.props.store.tour.tourArray.length - 1
                ? 'End' : 'Next'
            }
            <Icon name={this.props.store.tour.tourIndex === this.props.store.tour.tourArray.length - 1 ? 'check' : 'right arrow'} />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(Tour);
