import _ from 'lodash';
import React, { Component } from 'react';
import {
  Modal, Button, Form, Input, Message,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

const FormField = props => (
  <Form.Field key={props.name}>
    <label>{_.startCase(props.name)}</label>
    <Input {...props} />
  </Form.Field>
);

@inject('store')
@observer
class EmailModal extends Component {
  defaultState = {
    emailDomain: '',
    validEmailDomain: true,
  }

  state = this.defaultState

  handleInput = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
    this.testEmailDomain(value);
  }

  handleCancel = () => {
    this.setState(this.defaultState, this.props.onClose);
  }

  subDomainRegExp = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]*\.[a-zA-Z]{1,11}?$/

  testEmailDomain = _.debounce(
    (emailDomain = this.state.emailDomain) => this.setState({
      validEmailDomain: this.subDomainRegExp.test(emailDomain),
    }), 1000,
  );

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>Add Email Domain</Modal.Header>
        <Modal.Content>
          <Form error={this.state.validEmailDomain === false}>
            <FormField
              placeholder="rap.example.com"
              name="emailDomain"
              value={this.state.emailDomain}
              onChange={this.handleInput}
              error={this.state.validEmailDomain === false}
            />
            <Message
              error
              header="Invalid subdomain/domain. Accepted formats include: example.com, rap.example.com, test-test.rap.example.com"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="plus"
            content="Add Email Domain"
            labelPosition="left"
            onClick={async () => {
              await this.props.store.emailManagement
                .addEmailDomain({ emailDomain: this.state.emailDomain });
              this.handleCancel();
            }}
            loading={this.props.store.emailManagement.loading.addEmailDomain}
            disabled={this.props.store.emailManagement.loading.addEmailDomain
              || !this.state.validEmailDomain
              || !this.state.emailDomain
            }
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EmailModal;
