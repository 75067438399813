import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class OMI366 {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    appKey: '',
    appSecret: '',
    enabled: true,
  };

  @observable
  assignedDripCampaigns = {}

  @observable
  availableDripCampaigns = {};

  @observable
  loading = false;

  @action
  reset = () => {
    this.configs = {};
    this.assignedDripCampaigns = {};
    this.availableDripCampaigns = {};
    this.loading = false;
  }

  @action
  getConfigs = async () => {
    try {
      this.loading = true;

      const { data } = await axios.get(`${API_URL}/api/integrations/OMI366/config`);
      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (e) {
      // this.rootStore.failure('Could not find integration configs');
    } finally {
      this.loading = false;
    }
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/OMI366/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.omiUpdateConfigs);
        this.rootStore.clearToast(toastTypes.omiUpdateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.omiUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading = false;
    }
  }

  @action
  getDripCampaigns = async () => {
    try {
      this.loading = true;

      const { data } = await axios.get(`${API_URL}/api/integrations/OMI366/drip-campaigns`);

      if (data.success) {
        const { dripCampaigns } = data;
        const dripCampaignsObj = {};

        for (let i = 0; i < dripCampaigns.length; i += 1) {
          dripCampaignsObj[dripCampaigns[i].campaign_Id] = dripCampaigns[i];
        }
        this.availableDripCampaigns = dripCampaignsObj;
      }
    } catch (e) {
      // this.rootStore.failure('Failed to get OMI366 drip campaign settings.');
    } finally {
      this.loading = false;
    }
  }

  @action
  updateDripCampaigns = async (hostname, path, dripCampaignIds) => {
    try {
      this.loading = true;

      const { data } = await axios.post(`${API_URL}/api/integrations/OMI366/assign-form-to-drip-campaign`, {
        hostname, path, dripCampaignIds,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated 366 Drip Campaign settings.');
      } else {
        throw new Error();
      }
    } catch (e) {
      // this.rootStore.failure('Failed to get OMI366 drip campaign settings.');
    } finally {
      this.assignedDripCampaigns[hostname][path].campaignIds = [];
      await this.rootStore.forms.getFormLinks();
      this.loading = false;
    }
  }
}

export default OMI366;
