/* eslint-disable react/sort-comp */
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import {
  Header, Dropdown, Progress, Grid, Radio, Dimmer,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { keygen } from '../../../helper';

import Statistics from './Statistics';
import Chart from './Chart';
import SummaryOverview from './SummaryOverview';
import SetupProgress from './SetupProgress';
import './index.css';

const options = [
  { key: 1, text: '1 month', value: 1 },
  { key: 2, text: '3 months', value: 3 },
  { key: 3, text: '6 months', value: 6 },
  { key: 4, text: '1 year', value: 12 },
];

@inject('store')
@observer
class Dashboard extends Component {
  componentDidMount() {
    this.props.store.realtime.bindRealtimeData()
      .catch(() => this.props.store.failure('Failed to get realtime data'));
  }

  render() {
    const {
      loading, rawAnalytics, filters, setTimeSpan, percentView,
    } = this.props.store.analytics;
    const { setupStatus } = this.props.store.auth;
    const domains = _.get(rawAnalytics, filters.timeSpanInMonths, {});
    const paths = _.get(rawAnalytics, [filters.timeSpanInMonths, filters.domain], {});

    const loader = (
      <Dimmer active inverted>
        <Progress className="progressBar" indicating percent="100" size="medium">
          <p>Fetching Metrics</p>
          <p>This could take a few minutes, thank you for your patience.</p>
        </Progress>
      </Dimmer>
    );

    const dashboard = (
      <Fragment>
        { (this.props.store.forms.loading === false && setupStatus) ? <SetupProgress /> : null }
        <Grid columns="equal" stackable doubling>
          <Grid.Row>
            {
          !this.props.store.ui.mobile ? (
            <Grid.Column>
              <div style={{
                display: 'flex', position: 'relative', top: '0.5rem',
              }}
              >
                <div style={{ minWidth: '150px', paddingTop: '11px' }} className="showWhiteSpace">
                  Percent View
                  {'  '}
                  <Radio
                    toggle
                    checked={this.props.store.analytics.percentView}
                    onClick={() => {
                      this.props.store.analytics.percentView = !percentView;
                    }}
                    style={{ verticalAlign: 'middle' }}
                  />
                </div>

                <div
                  style={{ whiteSpace: 'nowrap', padding: '11px', paddingLeft: 0 }}
                >
                  Currently viewing:
                </div>

                <Dropdown
                  selection
                  search
                  fluid
                  placeholder="Domain Filter"
                  value={filters.domain}
                  onChange={(e, { value }) => {
                    filters.path = null;
                    filters.domain = value;
                  }}
                  options={
                    [
                      { key: 'Summary', text: 'Clear Filter', value: null },
                      ...Object.keys(domains).map(formUrl => ({
                        key: keygen(),
                        text: formUrl,
                        value: formUrl,
                      })),
                    ]}
                />

                <span className="showWhiteSpace">{'   '}</span>

                <Dropdown
                  selection
                  search
                  fluid
                  disabled={filters.domain === null}
                  placeholder="Form Url Filter"
                  value={filters.path}
                  onChange={(e, { value }) => {
                    filters.path = value;
                  }}
                  options={
                    [
                      { key: 'Summary', text: 'Clear Filter', value: null },
                      ...Object.keys(paths).map(path => ({
                        key: keygen(),
                        text: path,
                        value: path,
                      })),
                    ]}
                />

                <div style={{ whiteSpace: 'nowrap', padding: '11px' }}>
                  from the past
                  {' '}
                  <Dropdown
                    inline
                    header="Adjust time span"
                    direction="left"
                    options={options}
                    onChange={setTimeSpan}
                    value={filters.timeSpanInMonths}
                  />
                </div>
              </div>
            </Grid.Column>
          ) : (
            <Grid.Column>
              <Header style={{ marginTop: '1rem' }}>
                Summary from the past
                {' '}
                <Dropdown
                  inline
                  header="Adjust time span"
                  direction="left"
                  options={options}
                  onChange={setTimeSpan}
                  value={filters.timeSpanInMonths}
                />
              </Header>
            </Grid.Column>

          )
        }

          </Grid.Row>

          <Statistics />

          <Chart />

          <SummaryOverview />

        </Grid>
      </Fragment>
    );

    return (loading.getRawAnalytics || !this.props.store.appInitFlag) ? loader : dashboard;
  }
}

export default Dashboard;
