import _ from 'lodash';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Header, Segment, List, Form, Dropdown,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

@inject('store')
@observer
class Setup extends Component {
  componentDidMount() {
    this.props.store.integrations.GoogleAdwords.getAuthUrl();
    this.props.store.integrations.GoogleAdwords.resetInputConfigs();
  }

  audienceDropdown() {
    const {
      loading, inputConfigs, audiences, handleInput, configs,
    } = this.props.store.integrations.GoogleAdwords;
    const dropdownOptions = audiences.map((audience, i) => {
      const { id, name } = audience;
      const listType = _.startCase(audience['UserList.Type'] || audience.listType);
      let audienceName = `${name} ID: ${id} Type: ${listType}`;

      if (id === configs.userListId) {
        audienceName = `* ${audienceName}`;
      }

      return {
        key: id,
        text: audienceName,
        value: i,
      };
    });

    return (
      <Dropdown
        disabled={!inputConfigs.enabled || loading.getAudiences}
        placeholder="Audience"
        fluid
        selection
        options={dropdownOptions}
        onChange={handleInput}
        name="audienceIdx"
        value={inputConfigs.audienceIdx}
      />
    );
  }

  render() {
    const {
      inputConfigs, authUrl, loading,
      handleInput, updateConfigs, anyLoading,
    } = this.props.store.integrations.GoogleAdwords;

    return (
      <>
        <Header as="h3" attached="top">
          Setup
        </Header>

        <Segment attached>
          <List ordered>
            <List.Item>
              Log in to your Google Adwords account.
            </List.Item>
            <List.Item>
              {'Click '}
              <a
                href={authUrl}
                target="_blank"
                rel="noopener noreferrer"
                disabled={loading.getAuthUrl}
              >
                {'here '}
              </a>
              to grant Movology&apos;s Capture application necessary permissions.
            </List.Item>
            <List.Item>
              After authorizing our application, the enabled checkbox should be checked.
            </List.Item>
            <List.Item>
              Select the audience (CRM_BASED) you would like abandons
              to be added to in the dropdown below.
            </List.Item>
            <List.Item>
              You are all set!
              Abandons will be added to the customer match audience you selected below.
            </List.Item>
          </List>
        </Segment>
        <Header as="h3" attached="top">
          Settings
        </Header>
        <Segment attached>
          <Header as="h4">Audience</Header>
          {this.audienceDropdown()}
          <br />
          <Form>
            <Form.Checkbox
              label="Enabled"
              name="enabled"
              disabled={loading.getConfigs}
              checked={inputConfigs.enabled}
              onChange={handleInput}
            />
            <Form.Button
              positive
              disabled={
                anyLoading || (inputConfigs.enabled && inputConfigs.audienceIdx === undefined)
              }
              loading={anyLoading}
              onClick={() => updateConfigs()}
            >
              Update
            </Form.Button>
          </Form>
        </Segment>
      </>
    );
  }
}

export default withRouter(Setup);
