import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Menu, Icon } from 'semantic-ui-react';
import configs from '../configs';
import Setup from './Setup';

@inject('store')
@observer
class Blitz extends Component {
  state = {
    activeTab: 'Setup',
  }

  componentDidMount() {
    this.props.store.integrations.Blitz.getConfigs();
  }

  handleMenuClick = (e, { name }) => {
    this.setState({ activeTab: name });
  }

  displayMenuPanels = () => {
    switch (this.state.activeTab) {
      case 'Setup':
        return <Setup />;
      default:
        return <div>This panel doesn&apos;t exist</div>;
    }
  }

  render() {
    return (
      <>
        <Menu>
          <Menu.Item onClick={() => this.props.history.push('/integrations')}>
            <Icon name="arrow left" />
            <Header as="h3" content={configs.Blitz.displayName} style={{ marginTop: 0 }} />
          </Menu.Item>

          <Menu.Item
            name="Setup"
            content="Setup"
            onClick={this.handleMenuClick}
            active={this.state.activeTab === 'Setup'}
          />
        </Menu>

        {this.displayMenuPanels()}
      </>
    );
  }
}

export default Blitz;
