import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class Salesforce {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    enabled: false,
  };

  @observable
  loading = false;

  @action
  reset = () => {
    this.configs = {};
    this.loading = false;
  }


  getAuthUrl = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/Salesforce/auth-url`);
      if (res.data.success) {
        window.open(res.data.authUrl, '_blank');
        return res.data.authUrl;
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Salesforce/config`);

      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/Salesforce/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.salesForceUpdateConfigs);
        this.rootStore.clearToast(toastTypes.salesForceUpdateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.salesForceUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading = false;
    }
  }

  getTokens = async (code) => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Salesforce/get-tokens?code=${code}`);

      if (data.success) {
        this.getConfigs();
        this.rootStore.success('Salesforce integration successful.', toastTypes.salesForceGetTokens);
        this.rootStore.clearToast(toastTypes.salesForceGetTokens);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Salesforce integration unsuccessful.', toastTypes.salesForceGetTokens);
    } finally {
      this.loading = false;
    }
  }
}

export default Salesforce;
