/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Modal, Form, Button } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { API_URL } from '../../../configs';

@inject('store')
@observer
class ShareModal extends Component {
  state = {
    to: this.props.sendToSelf ? this.props.store.auth.user.email : '',
    subject: 'Movology Setup Instructions',
    emailBody: '',
  }

  @observable
  loading = false;

  componentDidUpdate(prevProps) {
    if (prevProps.sendToSelf !== this.props.sendToSelf) {
      this.setState({
        to: this.props.sendToSelf ? this.props.store.auth.user.email : '',
      });
    }
  }

  formInputHandler = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  sendBtnHandler = () => {
    let { to, subject, emailBody } = this.state;
    to = to.trim();
    subject = subject.trim();
    emailBody = emailBody.trim();
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(String(to).toLowerCase())) {
      this.props.store.failure('Please format the email correctly.');
    } else if (to === '' || subject === '' || emailBody === '') {
      this.props.store.failure('All fields are required.');
    } else {
      this.sendEmail();
    }
  }

  @action
  sendEmail = async () => {
    try {
      this.loading = true;

      let { to, subject, emailBody } = this.state;
      to = to.trim();
      subject = subject.trim();
      emailBody = emailBody.trim();

      const res = await axios.post(`${API_URL}/api/um/setup-email`, {
        to,
        subject,
        emailBody,
      });

      if (res.data.success) {
        this.props.store.success('Email sent successfully!');
        this.setState({ to: '', subject: '', emailBody: '' }, this.props.onClose);
      } else {
        this.props.store.failure('Email was not sent for some reason.');
      }
    } catch (e) {
      this.props.store.failure('Email was not sent for some reason.');
    } finally {
      this.loading = false;
    }
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Share Instructions</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.sendBtnHandler}>
            <Form.Input
              required
              label="To"
              name="to"
              value={this.state.to}
              onChange={this.formInputHandler}
              placeholder="tech@company.com"
            />
            <Form.Input
              required
              name="subject"
              value={this.state.subject}
              label="Subject"
              onChange={this.formInputHandler}
              placeholder="Subject"
            />
            <Form.TextArea
              required
              name="emailBody"
              value={this.state.emailBody}
              label="Body"
              onChange={this.formInputHandler}
              placeholder="Just provide the context, we'll attach the instructions!"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button
            positive
            loading={this.loading}
            onClick={this.sendBtnHandler}
          >
            Send
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ShareModal;
