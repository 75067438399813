/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Overview from './Overview';
import Individual from './Individual';
import FormsModal from './FormsModal';

import { modalTypes } from '../../../enums';

@inject('store')
@observer
class Forms extends Component {
  @observable
  urlSelection = null;

  @observable
  modalAction = null;

  @observable
  modalOpen = false;

  @action
  modalOnClose = () => { this.modalOpen = false; }

  @action
  openModal = () => { this.modalOpen = true; }

  @action
  addBtnHandler = (e, { url }) => {
    this.modalOpen = true;
    this.modalAction = modalTypes.add;
    this.urlSelection = url;
  }

  @action
  removeBtnHandler = (e, { url }) => {
    this.modalOpen = true;
    this.modalAction = modalTypes.remove;
    this.urlSelection = url;
  }

  // @action
  editBtnHandler = (e, { url }) => {
    this.urlSelection = url;
    this.modalAction = modalTypes.edit;
    const safePath = encodeURIComponent(url);
    this.props.history.push({
      pathname: `/forms/${safePath}`,
      state: {
        data: url,
      },
    });
  }

  @action
  backBtnHandler = () => {
    this.urlSelection = null;
  }

  render() {
    return (
      <Fragment>
        <Header id="TabTitle" as="h2">
          Forms
        </Header>

        {this.urlSelection !== null && this.modalAction === modalTypes.edit
          ? (
            <Individual
              selection={this.urlSelection}
              back={this.backBtnHandler}
            />
          ) : (
            <Overview
              add={this.addBtnHandler}
              remove={this.removeBtnHandler}
              edit={this.editBtnHandler}
            />
          )}

        <FormsModal
          open={this.modalOpen}
          onClose={this.modalOnClose}
          action={this.modalAction}
          url={this.urlSelection}
        />
      </Fragment>
    );
  }
}

export default withRouter(Forms);
