import { observable, action, computed } from 'mobx';
import axios from 'axios';
import { API_URL } from '../configs';

class Tour {
  constructor(rootStore) {
    this.rootStore = rootStore;
    if (this.rootStore && this.rootStore.initQueryParams && this.rootStore.initQueryParams.partner === 'shopify') {
      this.integrationSupplementalTour('shopify');
    }
  }

  @observable
  tourIndex = 0;

  @computed
  get displayTour() {
    return this.rootStore.auth.user.displayTour || false;
  }

  @action reset = () => {
    this.rootStore.auth.user.displayTour = false;
    this.tourIndex = 0;
  }

  @action
  nextStep = () => {
    this.tourIndex += 1;
  }

  @action
  backStep = () => {
    if (this.tourIndex !== 0) {
      this.tourIndex -= 1;
    }
  }

  @action
  endTour = () => {
    this.rootStore.auth.user.displayTour = false;
    axios.post(`${API_URL}/api/um/disableTour`);
  }

  @computed
  get currentTour() {
    return this.tourArray.length <= this.tourIndex
      ? false : this.tourArray[this.tourIndex];
  }

  integrationSupplementalTour(integrationPartner) {
    switch (integrationPartner) {
      case 'shopify':
        this.tourArray.push({
          header: 'Integrations',
          message: 'Since this account has been created through a Shopify integration, let\'s take a look at the integration.',
          nextUrl: '/integrations',
        }, {
          header: 'Shopify Integration',
          message: 'Clicking on the integrations tab will give you a list of our integration partners. Clicking on Shopify will show you more details.',
          nextUrl: '/integrations/Shopify/Setup',
        }, {
          header: 'Plan Selection',
          message: 'From here, you can click the Integration Pricing sub tab and select a plan that\'s right for you.',
          nextUrl: '/integrations/Shopify/IntegrationPricing',
        }, {
          header: 'Plan Selection',
          message: 'Unsure which plan is right for you? Contact us @ info@automatedremarketing.com.',
          nextUrl: '/integrations/Shopify/IntegrationPricing',
        });
        break;
      default:
    }
  }

  tourArray = [
    {
      header: 'Welcome, Let\'s Take a Tour!',
      message: 'Thank you for registering. Let\'s get set up and see some results. But first, let us show you a few things.',
      nextUrl: '/reporting',
    },
    {
      header: 'Reporting',
      message: 'Here you will find the information about your customers. There isn’t any information yet, but once you are all set up, this is where the good stuff is.',
      nextUrl: '/dashboard',
    },
    {
      header: 'Your Dashboard',
      message: 'With our Capture upgrade you can download your customer’s information and use it to remarket back to them.',
      nextUrl: '/forms',
    },
    {
      header: 'Your Forms',
      message: 'This page is where you tell us which forms you want to us to track. You can add as many as you like, but start with at least one so we can watch them for you and Capture any leads from your website that were lost. If you need help, click the chat button below.',
      nextUrl: '/setup',
    },
    {
      header: 'Your Simple Capture Setup',
      message: 'Follow these steps to install our snippet on your website to start collecting data. *If you are creating an account through WiX or Shopify, you can ignore step 1.',
      nextUrl: '/faq',
    },
    {
      header: 'FAQ',
      message: 'If you have any questions, check our FAQs for more information or contact us so we can assist you to the best of our ability. You can also email us at info@automatedremarketing.com.',
      nextUrl: '/setup',
    },
  ];
}

export default Tour;
