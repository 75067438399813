import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import {
  Header, Segment, List, Form, Grid,
} from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';

@inject('store')
@observer
class Setup extends Component {
  componentDidMount() {
    this.props.store.integrations.WiX.getAuthUrl();
  }

  @action
  enableToggle = () => {
    const flipped = !this.props.store.integrations.WiX.configs.enabled;
    this.props.store.integrations.WiX.configs.enabled = flipped;
  }

  get statusPanel() {
    const { appInstance } = this.props.store.integrations.WiX.configs;
    if (!appInstance) {
      return null;
    }
    const { instance, site } = appInstance;

    const statusConfigs = [
      {
        name: 'App Name',
        value: instance.appName,
      },
      {
        name: 'App Version',
        value: instance.appVersion,
      },
      {
        name: 'Site Name',
        value: site.siteDisplayName,
      },
    ];

    return (
      <>
        <Header as="h3" attached="top">
          Status
        </Header>
        <Segment attached>
          <Grid columns={2}>
            {statusConfigs.map(config => (
              <Grid.Row key={config.name}>
                <Grid.Column>
                  {config.name}
                </Grid.Column>
                <Grid.Column>
                  {config.value}
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Segment>
      </>
    );
  }

  render() {
    return (
      <>
        <Header as="h3" attached="top">
          Setup
        </Header>
        <Segment attached>
          <List ordered>
            <List.Item>
              <b>
              This integration is currently in development
              and is not currently in the App Market.
              </b>
            </List.Item>
            <List.Item>
              Make a WiX account here if you don&apos; here if you don&apos;t have one already.
            </List.Item>
            <List.Item>
              Head over to
              <a href="https://www.wix.com/app-market/main" target="_blank" rel="noopener noreferrer"> https://www.wix.com/app-market/main.</a>
            </List.Item>
            <List.Item>Search for: Capture Movology</List.Item>
            <List.Item>Hit &quot;Install App&quot;</List.Item>
            <List.Item>Give Capture necessary permissions and you&apos;re all set!</List.Item>
            <List.Item>
              Future captures will be accessible through the
              <Link to="/dashboard"> dashboard </Link>
              ,
              <Link to="/reporting"> reporting </Link>
              tab, and as contacts in your WiX Hive (PENDING).
            </List.Item>
          </List>
        </Segment>
        {this.props.store.integrations.WiX.configs.appInstance ? this.statusPanel : null}
        <Header as="h3" attached="top">
          Manage Integration
        </Header>

        <Segment attached>
          <Form>
            <Form.Checkbox
              label="Enable"
              checked={this.props.store.integrations.WiX.configs.enabled}
              onClick={() => this.enableToggle()}
            />
            <Form.Button
              positive
              disabled={this.props.store.integrations.WiX.loading}
              loading={this.props.store.integrations.WiX.loading}
              onClick={() => this.props.store.integrations.WiX.updateConfigs()}
            >
              Update
            </Form.Button>
          </Form>
        </Segment>
      </>
    );
  }
}

export default withRouter(Setup);
