import React, { Component } from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Form } from 'semantic-ui-react';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class InjectedCardSection extends Component {
  @action
  handleSubmit = async (e) => {
    e.preventDefault();
    const token = await this.props.stripe.createToken();
    if (token.error) {
      this.props.store.failure(token.error.message);
      this.props.store.payments.stripeToken = {};
    } else if (token) {
      this.props.store.payments.stripeToken = token.token;
    }

    if (!this.props.store.accountStatus.account.default_source) {
      this.props.store.payments.confirmPaymentAndSubscribe();
    }
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Field inline className="inlineInputButton">
          <CardElement />
          <button
            type="submit"
            style={{ display: 'none' }}
            ref={(confirm) => { this.props.store.payments.confirmPaymentsRef = confirm; }}
          />
        </Form.Field>
      </Form>
    );
  }
}

export default injectStripe(InjectedCardSection);
