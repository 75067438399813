/* eslint-disable consistent-return */
import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class Shopify {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.shopifyApiPath = `${API_URL}/api/integrations/Shopify`;
  }

  @observable
  configs = {
    enabled: false,
  };

  @observable
  loading = false;

  @observable
  tierList = [
    {
      name: 'Startup',
      price: '149.99',
      header: 'Gotta Get That!',
      planDescription: [
        '750 Form Tracks',
        'Dashboard Access',
        'Email Sending Access',
        'Capture on 1 Domain',
      ],
      icon: 'truck',
      color: 'green',
    },
    {
      name: 'Growth',
      price: '289.99',
      header: 'Keep it Going!',
      planDescription: [
        '1500 Form Tracks',
        'Dashboard Access',
        'Email Sending Access',
        'Capture on 1 Domain',
      ],
      icon: 'train',
      color: 'yellow',
    },
    {
      name: 'Scale',
      price: '549.99',
      header: 'Mover and Shaker.',
      planDescription: [
        '3000 Form Tracks',
        'Dashboard Access',
        'Email Sending Access',
        'Capture on 1 Domain',
      ],
      icon: 'plane',
      color: 'orange',
    },
    {
      name: 'EnterpriseLite',
      price: '999.99',
      header: 'Now We\'re Talking.',
      planDescription: [
        '6000 Form Tracks',
        'Dashboard Access',
        'Email Sending Access',
        'Capture on Multiple Domains',
      ],
      icon: 'rocket',
      color: 'red',
    },
  ];

  @action
  reset = () => {
    this.configs = {};
    this.loading = false;
  }

  @action
  getConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${this.shopifyApiPath}/config`);

      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (e) {
      // this.rootStore.failure('Could not find integration configs');
    } finally {
      this.loading = false;
    }
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${this.shopifyApiPath}/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.shopifyUpdateConfigs);
        this.rootStore.clearToast(toastTypes.shopifyUpdateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.shopifyUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading = false;
    }
  }

  setup = async (code, instanceId) => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${this.shopifyApiPath}/setup`, { code, instanceId });

      if (data.success) {
        this.rootStore.success('Shopify integration successful.', toastTypes.shopifySetUp);
        this.rootStore.clearToast(toastTypes.shopifySetUp);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Shopify integration unsuccessful.', toastTypes.shopifySetUp);
    } finally {
      this.loading = false;
      this.getConfigs();
    }
  }

  getTokens = async ({
    code, hmac, shop, state,
  }) => {
    try {
      this.loading = true;
      const urlString = `${this.shopifyApiPath}/token?`
        + `code=${code}&`
        + `hmac=${hmac}&`
        + `state=${state}&`
        + `shop=${shop}`;

      const { data } = await axios.get(urlString);

      if (data.success) {
        this.getConfigs();
        this.rootStore.success('Shopify integration successful.', toastTypes.shopifyGetTokens);
        this.rootStore.clearToast(toastTypes.shopifyGetTokens);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Shopify integration unsuccessful.', toastTypes.shopifyGetTokens);
    } finally {
      this.loading = false;
    }
  }

  paymentUrl = async (name) => {
    this.rootStore.success('Redirecting to Authorize Payment', toastTypes.paymentUrl);
    this.rootStore.clearToast(toastTypes.paymentUrl);

    try {
      this.loading = true;
      const { data } = await axios.get(`${this.shopifyApiPath}/payment/plan/${name}`);
      return data;
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.paymentUrl);
    } finally {
      this.loading = false;
    }
  }

  // eslint-disable-next-line camelcase
  activateCharge = async (charge_id) => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${this.shopifyApiPath}/payment/activate`, { chargeId: charge_id });
      this.rootStore.success('Plan successfuly updated.', toastTypes.activateCharge);
      this.rootStore.clearToast(toastTypes.activateCharge);

      return data;
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.activateCharge);
    } finally {
      this.loading = false;
    }
  }
}

export default Shopify;
