/* eslint no-underscore-dangle: 0 */
import _ from 'lodash';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import {
  Header, Segment, List, Form, Grid,
} from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';

import TypeformCards from './Forms';
import TypeformSearch from './Search';
import TypeformPagination from './Pagination';

@inject('store')
@observer
class Setup extends Component {
  Typeform = this.props.store.integrations.Typeform

  componentDidMount = () => {
    if (this.Typeform.configs.enabled) {
      this.Typeform.retrieveForms();
    }
  }

  @action
  enableToggle = () => {
    this.Typeform.configs.enabled = !this.Typeform.configs.enabled;
  }

  render() {
    return (
      <>
        <Header as="h3" attached="top">
          Setup
        </Header>

        <Segment attached>
          <List ordered>
            <List.Item>
              You MUST use Google Tag Manager for our Typeform integration to work.
            </List.Item>
            <List.Item>
              You MUST be on a Typeform paid plan (allows GTM containers to be associated with your forms).
            </List.Item>
            <List.Item>
              Create a Typeform account
              {' '}
              <a
                className="linkStyling"
                href="https://admin.typeform.com/signup"
                target="_blank"
                rel="noopener noreferrer"
              >
              here
              </a>
              {' '}
              if you don’t have one already.
            </List.Item>
            <List.Item>
              Click
              {' '}
              <span
                className="linkStyling"
                onClick={this.Typeform.getAuthUrl}
                onKeyPress={this.Typeform.getAuthUrl}
                role="link"
                tabIndex={0}
              >
                here
              </span>
              {' '}
              to grant Movology’s Capture application necessary permissions.
            </List.Item>
            <List.Item>
              Your Typeform forms should be loaded below.
            </List.Item>
            <List.Item>
              If you have not done so already, add the Capture snippet to your GTM container then
              publish it.
              <List.List>
                <List.Item>
                  You can do this through our
                  <Link to="/integrations/GoogleTagManager"> Capture + GTM integration </Link>
                  or manually by adding the snippet found
                  <Link to="/setup"> here</Link>
                  .
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Enter your GTM container ID into each Typeform form
              you would like to track then hit approve.
            </List.Item>
            <List.Item>
              Future captures will be accessible through the
              <Link to="/dashboard"> dashboard </Link>
              and
              <Link to="/reporting"> reporting </Link>
              tab.
            </List.Item>
          </List>
        </Segment>

        <Segment attached="top">
          <Grid stretched columns="equal" verticalAlign="middle">
            <Grid.Column style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
              Typeform Forms
            </Grid.Column>
            <Grid.Column textAlign="right">
              <TypeformSearch />
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment attached>
          {
          (this.Typeform.configs.enabled && !_.isEmpty(this.Typeform.formData))
            ? <TypeformCards />
            : null
          }
          <Grid textAlign="center" style={{ margin: '30px 0 15px 0' }}>
            <TypeformPagination />
          </Grid>
        </Segment>

        <Header as="h3" attached="top">
          Manage Integration
        </Header>

        <Segment attached>
          <Form>
            <Form.Checkbox
              label="Enable"
              onChange={this.enableToggle}
              checked={this.Typeform.configs.enabled}
            />

            <Form.Button
              positive
              disabled={this.Typeform.loading.configs}
              loading={this.Typeform.loading.configs}
              onClick={() => this.Typeform.updateConfigs('Typeform')}
            >
              Update
            </Form.Button>
          </Form>
        </Segment>
      </>
    );
  }
}

export default withRouter(Setup);
