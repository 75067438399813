import _ from 'lodash';
import React, { Component } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class SettingsDropdown extends Component {
  settingsOption = {
    key: 'settings',
    text: 'Settings',
    icon: 'settings',
    onClick: () => this.props.history.push('/settings'),
  }

  paymentsOption = {
    key: 'payments',
    text: 'Payments',
    icon: 'payment',
    onClick: () => this.props.history.push('/payments'),
  }

  signoutOption = {
    key: 'sign-out',
    text: 'Sign Out',
    icon: 'sign out',
    onClick: this.props.store.auth.signOut,
  }

  setupOption = {
    key: 'setup',
    text: 'Setup',
    icon: 'code',
    onClick: () => this.props.history.push('/setup'),
  }

  get dropdownOptions() {
    return [
      this.settingsOption,
      this.setupOption,
      ['wix', 'shopify'].includes(this.props.store.initQueryParams.partner) ? null : this.paymentsOption,
      this.signoutOption,
    ].filter(option => !_.isEmpty(option));
  }

  render() {
    return (
      <Dropdown
        style={{ verticalAlign: 'text-bottom' }}
        options={this.dropdownOptions}
        trigger={<Button circular icon="user" />}
        icon={null}
        pointing="top right"
      />
    );
  }
}

export default withRouter(SettingsDropdown);
