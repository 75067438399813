import React, { Component } from 'react';
import {
  Sidebar, Segment, Menu, Responsive, Icon,
} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import ContentHeader from './ContentHeader';
import './DashboardLayout.css';

@inject('store')
@observer
class DashboardLayout extends Component {
  state = {
    visibility: true,
  };

  handleOnUpdate = (e, { width }) => {
    if (width < 768) {
      this.setState({ visibility: false });
    } else {
      this.setState({ visibility: true });
    }
  }

  handleVisibilityChange = () => {
    this.setState(prevState => ({
      visibility: !prevState.visibility,
    }));
  }

  render() {
    const routeConfigs = this.props.routeConfigs
      .filter(config => config.icon);
    const menuItems = routeConfigs.map(({ name, path, icon }) => {
      const route = path.replace(/\/:.*\/*/g, '');
      return (
        <Link
          to={route}
          key={name}
        >
          <Menu.Item
            as="div"
            className={`cursorPointer ${this.props.location.pathname.startsWith(route) ? 'activeItem' : ''}`}
          >
            <Icon name={icon} />
            {name}
          </Menu.Item>
        </Link>
      );
    });

    return (
      <Responsive fireOnMount onUpdate={this.handleOnUpdate}>
        <Sidebar.Pushable as={Segment} id="DashboardPage">
          <Sidebar
            id="Sidebar"
            as={Menu}
            animation={this.props.store.ui.mobile ? 'overlay' : 'push'}
            direction="left"
            icon="labeled"
            vertical
            visible={this.state.visibility}
            width="thin"
          >
            <Menu.Item id="SpecialMenuItem">
              <div className="logoContainer" />
            </Menu.Item>
            {menuItems}
          </Sidebar>
          <Sidebar.Pusher
            className={`posRelative ${!this.props.store.ui.mobile && this.state.visibility ? 'tabOpen' : 'tabClosed'}`}
          >
            <ContentHeader
              sideNavFnc={this.handleVisibilityChange}
              visibility={this.state.visibility}
            />
            <Segment basic className="backgroundAttributor">
              {this.props.children}
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    );
  }
}

export default withRouter(DashboardLayout);
