import _ from 'lodash';
import axios from 'axios';
import { observable, action, computed } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class GoogleAdwords {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    audienceIdx: undefined,
    enabled: false,
    scope: 'https://www.googleapis.com/auth/adwords',
  };

  @observable
  audiences = []

  /** Used to manage input state. */
  @observable
  inputConfigs = this.configs

  @observable
  loading = {
    getAudiences: false,
    getAuthUrl: false,
    getConfigs: false,
    updateConfigs: false,
    setup: false,
  };

  @computed
  get anyLoading() {
    return _.includes(this.loading, true);
  }

  @observable
  authUrl = '';

  @action
  reset = () => {
    this.configs = {
      userListId: '',
      userListType: '',
      enabled: false,
    };
    this.audiences = [];
    this.inputConfigs = this.configs;
    this.loading = _.mapValues(this.loading, () => false);
  }

  @action
  handleInput = (e, el) => {
    const value = el.type === 'checkbox' ? el.checked : el.value;
    this.inputConfigs = {
      ...this.inputConfigs,
      [el.name]: value,
    };
  }

  @action
  resetInputConfigs = () => {
    this.inputConfigs = this.configs;
  }

  @action
  getAuthUrl = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/GoogleAdwords/auth-url`);
      if (res.data.success) {
        const { authUrl } = res.data;
        this.authUrl = authUrl;
        return authUrl;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  @action
  openAuthPage = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/GoogleAdwords/auth-url`);
      if (res.data.success) {
        window.open(res.data.authUrl, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getConfigs = async () => {
    try {
      this.loading.getConfigs = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/GoogleAdwords/config`);

      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };

        this.inputConfigs = this.configs;
      }
    } catch (e) {
      // this.rootStore.failure('Could not find integration configs');
    } finally {
      this.loading.getConfigs = false;
    }
  }

  @action
  getAudiences = async () => {
    try {
      this.loading.getAudiences = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/GoogleAdwords/audiences`);

      if (data.success) {
        this.audiences = data.audiences;
        if (this.configs.userListId) {
          this.inputConfigs.audienceIdx = this.audiences
            .findIndex(audience => audience.id === this.configs.userListId);
        }
      }
    } catch (e) {
      this.rootStore.failure('Could not get audiences!');
    } finally {
      this.loading.getAudiences = false;
    }
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading.updateConfigs = true;

      const { data } = await axios.post(`${API_URL}/api/integrations/GoogleAdwords/config`, {
        ...this.inputConfigs,
        userListId: this.audiences[this.inputConfigs.audienceIdx].id,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated Adwords configurations', toastTypes.updateConfigs);
        this.rootStore.clearToast(toastTypes.updateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.updateConfigs);
    } finally {
      this.getConfigs();
      this.loading.updateConfigs = false;
    }
  }

  @action
  setup = async (code, scope) => {
    try {
      this.loading.setup = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/GoogleAdwords/setup`, { code, scope });

      if (data.success) {
        this.rootStore.success('GoogleAdwords integration successful.', toastTypes.setup);
        this.rootStore.clearToast(toastTypes.setup);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('GoogleAdwords integration unsuccessful... Try revoking Capture permissions at https://myaccount.google.com/permissions', toastTypes.setup);
    } finally {
      this.loading.setup = false;
      this.getConfigs();
    }
  }
}

export default GoogleAdwords;
