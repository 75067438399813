import axios from 'axios';
import { observable, action } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class Pipedrive {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    enabled: false,
  };

  @observable
  loading = false;

  @action
  reset = () => {
    this.configs = {};
    this.loading = false;
  }

  getAuthUrl = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/Pipedrive/auth-url`);
      if (res.data.success) {
        window.open(res.data.authUrl, '_blank');
        return res.data.authUrl;
      }
    } catch (e) {
      console.error(e);
    }
    return '';
  }

  @action
  getConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Pipedrive/config`);

      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (e) {
      // this.rootStore.failure('Could not find integration configs');
    } finally {
      this.loading = false;
    }
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/Pipedrive/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated the configurations.', toastTypes.pipedriveUpdateConfigs);
        this.rootStore.clearToast(toastTypes.pipedriveUpdateConfigs);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.pipedriveUpdateConfigs);
    } finally {
      this.getConfigs();
      this.loading = false;
    }
  }

  getTokens = async (code) => {
    try {
      this.loading = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/Pipedrive/get-tokens?code=${code}`);

      if (data.success) {
        this.getConfigs();
        this.rootStore.success('Pipedrive integration successful.', toastTypes.getTokens);
        this.rootStore.clearToast(toastTypes.getTokens);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Pipedrive integration unsuccessful.', toastTypes.getTokens);
    } finally {
      this.loading = false;
    }
  }
}

export default Pipedrive;
