import _ from 'lodash';
import axios from 'axios';
import { observable, action, computed } from 'mobx';
import { API_URL } from '../../configs';
import { toastTypes } from '../../enums';

class GoogleTagManager {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  configs = {
    enabled: false,
  };

  @observable
  accounts = [];

  @observable
  selected = {
    accountIdx: null,
    containerIdx: null,
    workspaceIdx: null,
  }

  @observable
  loading = {
    getAuthUrl: false,
    getConfigs: false,
    updateConfigs: false,
    setup: false,
    getAllResources: true,
    createWorkspace: false,
    installCaptureTag: false,
    publishWorkspace: false,
  };

  @computed
  get anyLoading() {
    return _.includes(this.loading, true);
  }

  @observable
  authUrl = '';

  @action
  reset = () => {
    this.configs = {
      enabled: false,
    };
    this.accounts = [];
    this.loading = _.mapValues(this.loading, () => false);
  }

  @action
  handleSelect = (e, el) => {
    const value = el.type === 'checkbox' ? el.checked : el.value;

    this.selected = {
      ...this.selected,
      [el.name]: value,
    };
  }

  @action
  resetInputConfigs = () => {
    this.inputConfigs = this.configs;
  }

  @action
  getAuthUrl = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/GoogleTagManager/auth-url`);
      if (res.data.success) {
        const { authUrl } = res.data;
        this.authUrl = authUrl;
        return authUrl;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  @action
  openAuthPage = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/integrations/GoogleTagManager/auth-url`);
      if (res.data.success) {
        window.open(res.data.authUrl, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getConfigs = async () => {
    try {
      this.loading.getConfigs = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/GoogleTagManager/config`);

      if (data.success) {
        this.configs = {
          ...this.configs,
          ...data.config,
        };
      }
    } catch (e) {
      // this.rootStore.failure('Could not find integration configs');
    } finally {
      this.loading.getConfigs = false;
    }
  }

  @action
  updateConfigs = async () => {
    try {
      this.loading.updateConfigs = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/GoogleTagManager/config`, {
        ...this.configs,
      });

      if (data.success) {
        this.rootStore.success('Successfully updated Google Tag Manager configurations', toastTypes.googleTagManagerSetUp);
        this.rootStore.clearToast(toastTypes.googleTagManagerSetUp);
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Something went wrong...', toastTypes.googleTagManagerSetUp);
    } finally {
      this.getConfigs();
      this.loading.updateConfigs = false;
    }
  }

  @action
  setup = async (code, scope) => {
    try {
      this.loading.setup = true;
      const { data } = await axios.post(`${API_URL}/api/integrations/GoogleTagManager/setup`, { code, scope });

      if (data.success) {
        this.getAllResources();
        this.rootStore.success('Google Tag Manager integration successful!');
      } else {
        throw new Error();
      }
    } catch (e) {
      this.rootStore.failure('Google Tag Manager integration unsuccessful.');
    } finally {
      this.loading.setup = false;
      this.getConfigs();
    }
  }

  @action
  getAllResources = async () => {
    try {
      this.loading.getAllResources = true;
      const { data } = await axios.get(`${API_URL}/api/integrations/GoogleTagManager/resources`);

      if (!data.success) {
        throw new Error('Failed to get GTM resources...');
      }
      this.accounts = data.accounts;
    } catch (err) {
      // console.error(err)
    }
    this.loading.getAllResources = false;
  }

  @action
  createWorkspace = async () => {
    try {
      this.loading.createWorkspace = true;
      const { accountIdx, containerIdx } = this.selected;
      const selectedContainer = _.get(this.accounts, [accountIdx, 'containers', containerIdx]);
      if (!selectedContainer) {
        throw new Error('You must specify a container!');
      }
      const { data } = await axios.post(`${API_URL}/api/integrations/GoogleTagManager/${selectedContainer.path}/workspaces`);
      if (data.success) {
        this.getAllResources();
        this.rootStore.success('Successfully created workspace!', toastTypes.createWorkspace);
        this.rootStore.clearToast(toastTypes.createWorkspace);
      } else {
        throw new Error('Could not create workspace...');
      }
    } catch (err) {
      this.rootStore.failure(err.message, toastTypes.createWorkspace);
      this.loading.createWorkspace = false;
    }
    this.loading.createWorkspace = false;
  }

  @action
  addCaptureTag = async () => {
    try {
      this.loading.installCaptureTag = true;
      const { accountIdx, containerIdx, workspaceIdx } = this.selected;
      const selectedWorkspace = _.get(this.accounts, [accountIdx, 'containers', containerIdx, 'workspaces', workspaceIdx]);
      if (!selectedWorkspace) {
        throw new Error('You must specify a workspace!');
      }
      const { data } = await axios.post(`${API_URL}/api/integrations/GoogleTagManager/${selectedWorkspace.path}/tags`);
      if (data.success) {
        this.getAllResources();
        this.rootStore.success('Successfully added Capture tag!', toastTypes.addCaptureTag);
        this.rootStore.clearToast(toastTypes.addCaptureTag);
      } else {
        throw new Error('Could not add capture tag...');
      }
    } catch (err) {
      this.rootStore.failure(err.message, toastTypes.addCaptureTag);
      this.loading.installCaptureTag = false;
    }
    this.loading.installCaptureTag = false;
  }

  @action
  publishWorkspace = async () => {
    try {
      this.loading.publishWorkspace = true;
      const { accountIdx, containerIdx, workspaceIdx } = this.selected;
      const selectedWorkspace = _.get(this.accounts, [accountIdx, 'containers', containerIdx, 'workspaces', workspaceIdx]);
      if (!selectedWorkspace) {
        throw new Error('You must specify a workspace!');
      }
      const { data } = await axios.post(`${API_URL}/api/integrations/GoogleTagManager/${selectedWorkspace.path}/publish`);
      if (data.success) {
        this.getAllResources();
        this.rootStore.success('Successfully published your workspace!', toastTypes.publishWorkspace);
        this.rootStore.clearToast(toastTypes.publishWorkspace);
      } else {
        throw new Error('Could not publish your workspace...');
      }
    } catch (err) {
      this.rootStore.failure(err.message, toastTypes.publishWorkspace);
      this.loading.publishWorkspace = false;
    }
    this.loading.publishWorkspace = false;
  }
}

export default GoogleTagManager;
