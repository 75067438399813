/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Button, Header, Table, Dropdown,
  Label, Pagination, Modal, Input,
  Segment, Icon,
} from 'semantic-ui-react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { TABLE_ITEMS_PER_PAGE, clientStatusType } from '../../../enums';
import { FIREBASE_CONFIGS } from '../../../configs';

const { projectId } = FIREBASE_CONFIGS;
const OPERATIONS = {
  STATUS_CHANGE: 'STATUS_CHANGE',
  MANAGE_USERS: 'MANAGE_USERS',
};

@inject('store')
@observer
class Admin extends Component {
  state = {
    modalOpen: false,
    operation: null,
    activePage: 1,
    statusChange: {
      clientId: null,
      statusTo: null,
    },
    userManagement: {
      clientId: null,
    },
    search: '',
    statusFilter: [...Object.keys(clientStatusType)],
  }

  componentDidMount() {
    this.props.store.admin.getClientData();
    this.delayedCallback = _.debounce(this.handleSearchInputChange, 500);
  }

  handleSearchInputChange = (e) => {
    this.setState({
      search: e.target.value,
      activePage: 1,
    });
  }

  handlePaginationChange = (e, { activePage }) => this.setState({ activePage })

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  handleStatusChange = (clientId, statusTo) => {
    this.setState({
      statusChange: {
        clientId,
        statusTo,
      },
      operation: OPERATIONS.STATUS_CHANGE,
      modalOpen: true,
    });
  }

  resetFilter = () => this.setState({ statusFilter: [...Object.keys(clientStatusType)] })

  removeFilter = status => this.setState({
    statusFilter: this.state.statusFilter.filter(value => value !== status),
  });

  @observable sortState = {
    column: 'Account Created',
    direction: 'descending',
  }

  @action handleSort = (clickedColumn) => {
    if (this.sortState.column !== clickedColumn) {
      this.sortState = {
        column: clickedColumn,
        direction: 'descending',
      };
    } else {
      this.sortState.direction = this.sortState.direction === 'ascending' ? 'descending' : 'ascending';
    }
  }

  sendResetEmail = (email) => {
    this.props.store.firebase.auth().sendPasswordResetEmail(email).then(() => {
      this.props.store.success(`Reset email successfully sent to ${email}`);
    }).catch(() => {
      this.props.store.failure('Reset email could not be sent.');
    });
  }

  modalContent = () => {
    const { operation, statusChange, userManagement } = this.state;
    const { clientData } = this.props.store.admin;

    switch (operation) {
      case OPERATIONS.STATUS_CHANGE:
        return (
          <Fragment>
            <Modal.Header>
              {`Change ${clientData[statusChange.clientId].company || statusChange.clientId}'s status to ${statusChange.statusTo}?`}
            </Modal.Header>
            <Modal.Actions>
              <Button onClick={this.handleClose}>
                Cancel
              </Button>
              <Button
                positive
                onClick={async () => {
                  await this.props.store.admin.setClientStatus(
                    statusChange.clientId,
                    statusChange.statusTo,
                  );
                  this.handleClose();
                }}
              >
                Confirm
              </Button>
            </Modal.Actions>
          </Fragment>
        );
      case OPERATIONS.MANAGE_USERS:
        return (
          <Fragment>
            <Modal.Header>
              {`Viewing ${clientData[userManagement.clientId].company || userManagement.clientId}'s Users`}
            </Modal.Header>
            <Modal.Content>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Email
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Phone
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Role
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    (() => {
                      const userRow = [];
                      const { users } = clientData[userManagement.clientId];
                      Object.entries(users).forEach((uid, user) => {
                        userRow.push(
                          <Table.Row key={uid}>
                            <Table.Cell>
                              {user.fullName}
                            </Table.Cell>
                            <Table.Cell>
                              {user.email}
                            </Table.Cell>
                            <Table.Cell>
                              {user.phone}
                            </Table.Cell>
                            <Table.Cell>
                              {user.claims.role}
                            </Table.Cell>
                            <Table.Cell collapsing>
                              <Dropdown
                                direction="left"
                                icon="ellipsis vertical"
                                className="icon"
                              >
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      this.sendResetEmail(user.email);
                                    }}
                                  >
                                  Reset Password
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Table.Cell>
                          </Table.Row>,
                        );
                      });
                      return userRow;
                    })()
                  }
                </Table.Body>
              </Table>
            </Modal.Content>
            <Modal.Actions>
              <Button positive onClick={this.handleClose}>
                Exit
              </Button>
            </Modal.Actions>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Modal.Header>
              Something went wrong...
            </Modal.Header>
          </Fragment>
        );
    }
  }

  generateClientStatusLabel = (status, deleteButton) => {
    let color;

    if (status === clientStatusType.enabled) {
      color = 'green';
    } else if (status === clientStatusType.disabled) {
      color = 'grey';
    } else if (status === clientStatusType.archived) {
      color = 'red';
    } else {
      // something is wrong since these are the 3 possible states
      color = 'red';
    }

    return (
      <Label
        key={status}
        color={color}
        className="capitalize"
      >
        {status}
        { deleteButton ? <Icon name="delete" onClick={() => this.removeFilter(status)} /> : null}
      </Label>
    );
  }

  buildRows = () => {
    const { clientData } = this.props.store.admin;
    const rows = Object.values(clientData)
      .filter((item) => {
        const search = this.state.search.toUpperCase();

        if (!item.id || item.id.includes('superadmin')) {
          return false;
        }

        if (item.accountStatus && !this.state.statusFilter.includes(item.accountStatus)) {
          return false;
        }

        if (item.company && item.company.toUpperCase().includes(search)) {
          return true;
        }

        for (const uid in item.users) {
          const user = item.users[uid];
          if (user.email && user.email.toUpperCase().includes(search)) {
            return true;
          }

          if (user.fullName && user.fullName.toUpperCase().includes(search)) {
            return true;
          }

          if (user.phone && user.phone.includes(search)) {
            return true;
          }
        }

        return false;
      })
      .sort((a, b) => {
        const { column, direction } = this.sortState;
        let comp = false;

        switch (column) {
          case 'Client':
            if (a.company.toUpperCase() > b.company.toUpperCase()) {
              comp = 1;
            } else if (a.company.toUpperCase() === b.company.toUpperCase()) {
              comp = 0;
            } else {
              comp = -1;
            }
            break;
          case 'Status':
            if (a.accountStatus > b.accountStatus) {
              comp = 1;
            } else if (a.accountStatus === b.accountStatus) {
              comp = 0;
            } else {
              comp = -1;
            }
            break;
          case 'Account Created':
            if (moment(a.accountCreated).isAfter(b.accountCreated)) {
              comp = 1;
            } else if (a.accountCreated === b.accountCreated) {
              comp = 0;
            } else {
              comp = -1;
            }
            break;
          case 'Premium':
            if (moment(a.premiumStartDate).isAfter(b.premiumStartDate)) {
              comp = 1;
            } else if (a.premiumStartDate === b.premiumStartDate) {
              comp = 0;
            } else {
              comp = -1;
            }
            break;
          case 'Forms Started':
            comp = Number(a.formsStarted || 0) - Number(b.formsStarted || 0);
            break;
          case 'Abandons':
            comp = Number(a.abandons || 0) - Number(b.abandons || 0);
            break;
          case 'Abandons w/ Email':
            comp = Number(a.abandons_with_emails || 0) - Number(b.abandons_with_emails || 0);
            break;
          case 'Abandons w/o Email':
            comp = (Number(a.abandons || 0) - Number(a.abandons_with_emails || 0)) - (Number(b.abandons || 0) - Number(b.abandons_with_emails || 0));
            break;
          default:
            comp = 0;
            break;
        }

        if (direction === 'ascending') {
          return comp;
        } else if (direction === 'descending') {
          return -comp;
        } else {
          return 0;
        }
      })
      .slice(
        0 + (this.state.activePage - 1) * TABLE_ITEMS_PER_PAGE,
        0 + (this.state.activePage * TABLE_ITEMS_PER_PAGE),
      )
      .map(client => (
        <Table.Row key={client.id}>
          <Table.Cell>{client.company ? client.company : client.id}</Table.Cell>
          <Table.Cell style={{ whiteSpace: 'pre-line' }}>{Object.values(clientData[client.id].users).map(user => user.email).join('\n')}</Table.Cell>
          <Table.Cell className="capitalize" textAlign="center">{this.generateClientStatusLabel(client.accountStatus)}</Table.Cell>
          <Table.Cell textAlign="center">{client.accountCreated ? moment(client.accountCreated, 'YYYY-MM-DD').format('MMM DD, YYYY') : ''}</Table.Cell>
          <Table.Cell textAlign="right">{client.formsStarted ? client.formsStarted.toLocaleString() : ''}</Table.Cell>
          <Table.Cell textAlign="right">{client.abandons ? client.abandons.toLocaleString() : ''}</Table.Cell>
          <Table.Cell textAlign="right">{client.abandons_with_emails ? client.abandons_with_emails.toLocaleString() : ''}</Table.Cell>
          <Table.Cell textAlign="right">{client.abandons && client.abandons_with_emails ? (client.abandons - client.abandons_with_emails).toLocaleString() : ''}</Table.Cell>
          <Table.Cell collapsing>
            <Dropdown
              direction="left"
              icon="ellipsis vertical"
              className="icon"
            >
              <Dropdown.Menu>
                <Dropdown.Header style={{ fontWeight: 700 }}>
                  {client.company}
                </Dropdown.Header>

                <Dropdown.Divider />

                <Dropdown.Header>
                  SET CLIENT STATUS
                </Dropdown.Header>

                <Dropdown.Item
                  onClick={() => this.handleStatusChange(client.id, 'enabled')}
                  disabled={client.status === clientStatusType.disabled}
                >
                  <Label color="green" content="Enabled" />
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => this.handleStatusChange(client.id, 'disabled')}
                  disabled={client.status === clientStatusType.disabled}
                >
                  <Label color="grey" content="Disabled" />
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => this.handleStatusChange(client.id, 'archived')}
                >
                  <Label color="red" content="Archived" />
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Header>
                  MANAGE CLIENT
                </Dropdown.Header>
                <Dropdown.Item
                  onClick={() => {
                    this.setState({
                      operation: OPERATIONS.MANAGE_USERS,
                      userManagement: {
                        clientId: client.id,
                      },
                      modalOpen: true,
                    });
                  }}
                >
                  Manage Users
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => this.props.store.admin.downloadCaptures(client.id, client.company)}
                >
                  Download Captures
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => this.props.store.admin.spoofClient(client.id)}
                >
                  Spoof Account
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Header>
                  DEV
                </Dropdown.Header>
                <Dropdown.Item
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://console.firebase.google.com/u/0/project/${projectId}/database/${projectId}/data/clients/${client.id}`}
                >
                  { client.id }
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Table.Cell>
        </Table.Row>
      ));

    return rows;
  }

  render() {
    const { clientData } = this.props.store.admin;
    const { column, direction } = this.sortState;

    return (
      <Fragment>
        <Header id="TabTitle" as="h2" style={{ display: 'inline-block' }}>
          Admin
        </Header>
        <Input
          style={{
            display: 'inline-block',
            position: 'absolute',
            right: '1rem',
            top: '1.5rem',
          }}
          icon="search"
          name="search"
          value={this.state.value}
          onChange={(e) => {
            e.persist();
            this.delayedCallback(e);
          }}
        />

        <Segment>
          <Button size="mini" primary onClick={this.resetFilter} style={{ marginRight: '1.5rem' }}>
            Reset Filter
          </Button>
          {
            this.state.statusFilter.map(status => (
              this.generateClientStatusLabel(status, true)
            ))
          }
        </Segment>
        <Table celled sortable striped>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell
                sorted={column === 'Client' ? direction : null}
                onClick={() => this.handleSort('Client')}
                content="Client"
              />
              <Table.HeaderCell
                content="Email(s)"
              />
              <Table.HeaderCell
                sorted={column === 'Status' ? direction : null}
                onClick={() => this.handleSort('Status')}
                content="Status"
              />
              <Table.HeaderCell
                sorted={column === 'Account Created' ? direction : null}
                onClick={() => this.handleSort('Account Created')}
                content="Account Created"
              />
              <Table.HeaderCell
                sorted={column === 'Forms Started' ? direction : null}
                onClick={() => this.handleSort('Forms Started')}
                content="Forms Started"
              />
              <Table.HeaderCell
                sorted={column === 'Abandons' ? direction : null}
                onClick={() => this.handleSort('Abandons')}
                content="Abandons"
              />
              <Table.HeaderCell
                sorted={column === 'Abandons w/ Email' ? direction : null}
                onClick={() => this.handleSort('Abandons w/ Email')}
                content="Abandons w/ Email"
              />
              <Table.HeaderCell
                sorted={column === 'Abandons w/o Email' ? direction : null}
                onClick={() => this.handleSort('Abandons w/o Email')}
                content="Abandons w/o Email"
              />
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.buildRows()}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="10" className="posRelative">
                <Pagination
                  activePage={this.state.activePage}
                  onPageChange={this.handlePaginationChange}
                  totalPages={Math.ceil(Object.keys(clientData).length / TABLE_ITEMS_PER_PAGE)}
                  style={{ position: 'relative', left: '50%', transform: 'translateX(-50%)' }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          {this.modalContent()}
        </Modal>

        <a style={{ display: 'none' }} ref={(emailRef) => { this.props.store.admin.emailRef = emailRef; }} />
      </Fragment>
    );
  }
}

export default Admin;
