import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  Segment, List, Header, Message, Button, Divider, Icon,
} from 'semantic-ui-react';
import ShareModal from './ShareModal';
import { API_URL } from '../../configs';

@inject('store')
@observer
class Setup extends Component {
  state = {
    modalOpen: false,
    sendToSelf: false,
  }

  @computed
  get script() {
    const { clientId } = this.props.store.auth.user;
    return (
      `<script>
  setTimeout(function() {
    (function (d, script) {
      script = d.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = '${API_URL}/api/mov/clientId/${clientId}';
      script.setAttribute('client-id', '${clientId}')
      d.getElementsByTagName('head')[0].appendChild(script);
    }(document));
  }, 1500);
</script>`);
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  shareMessageHandler = (sendToSelf = false) => {
    this.setState({ sendToSelf }, this.openModal);
  }

  copyScriptToClipboard = () => {
    navigator.clipboard.writeText(this.script);
    this.props.store.success('Script copied to clipboard!');
  }

  render() {
    return (
      <Fragment>
        <Header id="TabTitle" as="h2">
          Setup
        </Header>

        <Message
          info
          style={{ cursor: 'pointer' }}
          icon="inbox"
          header="You might need some technical knowledge for the setup. Would you like your dev team to handle this?"
          content="We'll share the instructions with your dev team through email!"
          onClick={() => this.shareMessageHandler(false)}
        />

        <Segment>
          <List ordered>
            <List.Item>
              Put this snippet on the bottom of your website's body element. You can also install the snippet using
              {' '}
              <a
                href="https://support.google.com/tagmanager/answer/6107167?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Tag Manager.
              </a>
              <br />
              NOTE: This snippet must be installed first before adding any forms.

              <Divider hidden />

              <Segment style={{ backgroundColor: 'lightgrey', maxWidth: '560px' }} attached>
                <code style={{ filter: 'opacity(50%)' }}>
                  { this.script }
                </code>
                <Button
                  primary
                  icon
                  labelPosition="left"
                  onClick={this.copyScriptToClipboard}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    filter: 'opacity(100%)',
                  }}
                >
                  <Icon name="clipboard outline" />
                  Copy to Clipboard
                </Button>
              </Segment>

              <Message
                attached="bottom"
                info
                style={{
                  maxWidth: '560px',
                  display: 'flex'
                }}
              >
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => this.shareMessageHandler(true)}
                >
                  <Icon name="envelope outline" />
                  Email this to yourself
                </Button>
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => this.shareMessageHandler(false)}
                >
                  <Icon name="envelope outline" />
                  Email this to your dev
                </Button>
              </Message>
            </List.Item>

            <Divider hidden />

            <List.Item>
              Then, go to
              {' '}
              <span className="linkStyling" onClick={() => this.props.history.push('/forms')}>Forms Tab</span>
              {' '}
              and use the green + button to add a form.
            </List.Item>

            <List.Item>
              Once you have your snippets installed and forms added, we'll turn the switch on to start building leads for your website!
            </List.Item>
          </List>
        </Segment>

        <ShareModal
          open={this.state.modalOpen}
          onClose={this.closeModal}
          sendToSelf={this.state.sendToSelf}
        />
      </Fragment>
    );
  }
}

export default withRouter(Setup);
