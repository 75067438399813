import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Button, Grid, Segment,
} from 'semantic-ui-react';

import CapturePlans from '../CapturePlans';

@inject('store')
@observer
class CapturePlansUpgrade extends Component {
  upgradePlan = () => {
    this.props.store.payments.upgradePlan();
  }

  render() {
    const { selectedPlan } = this.props.store.payments;
    const { planObj } = this.props.store.accountStatus;
    return (
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column align="left">
              <CapturePlans />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column align="left">
              <Button
                disabled={planObj.id === selectedPlan[0]}
                color="green"
                onClick={this.upgradePlan}
              >
                Update Plan
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default CapturePlansUpgrade;
